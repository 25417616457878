import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import communityService from "services/communities";
import { useAuth } from "context/Auth";
import { ICommunity } from "types/communities";

interface CommunityContextType {
  community: Partial<ICommunity>;
  setCommunity: React.Dispatch<React.SetStateAction<Partial<ICommunity>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  id: string | undefined;
  saveCommunity: (additionalProps?: Record<string, any>) => Promise<boolean>;
  reFetchCommunity: () => Promise<void>;
}

const CommunityContext = createContext<CommunityContextType | undefined>(undefined);

export const CommunityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [community, setCommunity] = useState<Partial<ICommunity>>({});
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const fetchCommunity = async () => {
    if (id && id !== "create") {
      try {
        setLoading(true);
        const community: ICommunity = await communityService.fetch(id);
        setCommunity(community);
      } catch (e) {
        console.error("Error fetching communities:", e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchCommunity();
  }, [id]);

  const saveCommunity = async (additionalProps = {}) => {
    try {
      setLoading(true);

      if (!community.name) {
        throw new Error("Please fill in all fields");
      }

      const updatedCommunity = {
        ...community,
        ...additionalProps,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      if (id === "create") {
        updatedCommunity.createdBy = user;
        updatedCommunity.createdDate = new Date().toISOString();
        delete updatedCommunity.id;
        delete updatedCommunity._id;
        await communityService.create(updatedCommunity);
      } else {
        updatedCommunity._id = updatedCommunity.id || updatedCommunity._id;
        updatedCommunity.updatedBy = user;
        updatedCommunity.updatedDate = new Date().toISOString();
        await communityService.update(updatedCommunity._id, updatedCommunity);
      }
      return true;
    } catch (error: any) {
      console.error("Error saving event:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommunityContext.Provider
      value={{
        community,
        setCommunity,
        loading,
        setLoading,
        error,
        setError,
        user,
        id,
        saveCommunity,
        reFetchCommunity: fetchCommunity,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export const useCommunity = () => {
  const context = useContext(CommunityContext);
  if (context === undefined) {
    throw new Error("useCommunity must be used within a CommunityProvider");
  }
  return context;
};