import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Paper,
  Divider,
  Link,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useAuth } from "context/Auth";
import stripeService from 'services/stripe';
import LoadingOverlay from "components/LoadingOverlay";
import { IOrganization } from 'types/organizations';
import organizationsService from 'services/organizations';

interface StripeAccountDetailsProps {
  accountId: string;
}

const StripeAccountDetails: React.FC<StripeAccountDetailsProps> = ({ accountId }) => {
  const [accountDetails, setAccountDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleUpdateAccount = async () => {
    try {
      await stripeService.updateAccount({
        country: 'US',
      });
    } catch (err) {
      console.error('Error updating account:', err);
      setError('Failed to update account.' + err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        setLoading(true);
        const response = await stripeService.getAccount(accountId);
        setAccountDetails(response);
      } catch (err) {
        console.error('Error fetching account details:', err);
        setError('Failed to load Stripe Connect account details');
      } finally {
        setLoading(false);
      }
    };

    if (accountId) {
      fetchAccountDetails();
    }
  }, [accountId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">{error}</Alert>
    );
  }

  if (!accountDetails) {
    return <Alert severity="info">No account details found</Alert>;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>Account Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">Account ID</Typography>
              <Typography fontWeight="medium">{accountDetails.id}</Typography>

            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">Status</Typography>
              <Chip
                label={accountDetails.charges_enabled ? 'Active' : 'Pending'}
                color={accountDetails.charges_enabled ? 'success' : 'warning'}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">Business Type</Typography>
              <Typography fontWeight="medium">
                {accountDetails.business_type || 'Not specified'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">Payouts Enabled</Typography>
              <Chip
                label={accountDetails.payouts_enabled ? 'Enabled' : 'Disabled'}
                color={accountDetails.payouts_enabled ? 'success' : 'error'}
                size="small"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {!accountDetails.charges_enabled && (
        <Box sx={{ mt: 3 }}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Your account setup is incomplete. Please complete the onboarding process to enable payments.
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                const response = await stripeService.createAccountOnboardingLink();
                window.location.href = response.url;
              } catch (err) {
                console.error('Error creating onboarding link:', err);
                setError('Failed to create onboarding link');
              }
            }}
          >
            Complete Account Setup
          </Button>
        </Box>
      )}

      {accountDetails.charges_enabled && (
        <Card sx={{ mt: 3, boxShadow: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>Account Capabilities</Typography>
            <Grid container spacing={2}>
              {accountDetails.capabilities && Object.entries(accountDetails.capabilities).map(([key, value]) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <Box sx={{ p: 1.5, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                      {key.replace(/_/g, ' ')}
                    </Typography>
                    <Chip
                      label={value as string}
                      color={value === 'active' ? 'success' : 'default'}
                      size="small"
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              ))}
              {/* <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateAccount}
                >
                  Update Account
                </Button>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      )}
      <Box>
        {accountDetails.loginLink?.url &&
          <a className="mt-8 text-blue-500 hover:text-blue-700 font-medium px-4 py-2 rounded transition-colors duration-200 bg-gray-100 border border-gray-200" href={accountDetails.loginLink.url} target="_blank" rel="noopener noreferrer">Login</a>}
      </Box>
    </Box>
  );
};

const StripeConnectOnboarding: React.FC<{
  onAccountCreated: (accountId: string) => void;
}> = ({ onAccountCreated }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleStartOnboarding = async () => {
    try {
      setLoading(true);
      // First create a connected account
      const accountResponse = await stripeService.createAccount({
        type: 'standard',
        country: 'US',
      });

      // Then get the onboarding link
      const onboardingResponse = await stripeService.createAccountOnboardingLink({
        account: accountResponse.id,
        refresh_url: `${window.location.origin}/organization/stripe-connect`,
        return_url: `${window.location.origin}/organization/stripe-connect`,
      });

      // Notify parent component of the new account ID
      onAccountCreated(accountResponse.id);

      // Redirect to Stripe onboarding
      window.location.href = onboardingResponse.url;
    } catch (err) {
      console.error('Error starting onboarding:', err);
      setError('Failed to start the onboarding process.' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: '800px', mx: 'auto', boxShadow: 2 }}>
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>Connect Your Stripe Account</Typography>
          <Typography variant="body1" color="text.secondary">
            Start accepting payments by connecting Stripe to your organization.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText', mb: 3 }}>
            <Typography variant="subtitle1" fontWeight="medium">Benefits of Stripe Connect:</Typography>
            <Box component="ul" sx={{ pl: 4, mt: 1 }}>
              <li>Accept payments directly to your bank account</li>
              <li>Manage payouts and balances from your Stripe dashboard</li>
              <li>Customize payment experiences for your customers</li>
              <li>Leverage Stripe's fraud prevention and security features</li>
            </Box>
          </Paper>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleStartOnboarding}
              disabled={loading}
              sx={{ px: 4, py: 1.5 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Connect with Stripe'}
            </Button>
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
            By connecting, you agree to Stripe's <Link href="https://stripe.com/terms" target="_blank" rel="noopener">Terms of Service</Link> and <Link href="https://stripe.com/privacy" target="_blank" rel="noopener">Privacy Policy</Link>.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const StripeConnectTab: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user, refreshUser } = useAuth();
  const [organization, setOrganization] = useState<IOrganization | null>(null);

  const fetchOrganization = useCallback(async () => {
    try {
      setLoading(true);
      const organizationId = user?.currentOrganization?.id;
      if (!organizationId) {
        throw new Error('No organization ID found');
      }
      const response = await organizationsService.fetch(organizationId);
      setOrganization(response);
    } catch (err) {
      console.error('Error fetching organization:', err);
      setError('Failed to load organization details');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const handleAccountCreated = async (accountId: string) => {
    try {
      setLoading(true);
      if (!organization) return;

      // Update the organization with the new Stripe account ID
      await organizationsService.update(organization.id, {
        ...organization,
        stripeAccountId: accountId
      });

      // Refresh the organization data
      await fetchOrganization();
      await refreshUser();
    } catch (err) {
      console.error('Error updating organization with Stripe account ID:', err);
      setError('Failed to save Stripe account information');
    } finally {
      setLoading(false);
    }
  };

  if (loading && !organization) {
    return <LoadingOverlay loading={true} />;
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>Stripe Connect</Typography>
        <Divider sx={{ mb: 3 }} />

        {organization?.stripeAccountId ? (
          <StripeAccountDetails accountId={organization.stripeAccountId} />
        ) : (
          <StripeConnectOnboarding onAccountCreated={handleAccountCreated} />
        )}
      </Grid>

      <LoadingOverlay loading={loading} />
    </Grid>
  );
};

export default StripeConnectTab; 