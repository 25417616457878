import { Select, MenuItem, Button, TextField, Grid, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import eventsService from '../../services/events';
import tagsService from '../../services/tags';

interface AudienceFilterProps {
  filter: {
    entity: "" | "members" | "events" | "rsvps" | "communities";
    condition: "" | "equals" | "contains" | "in" | "notIn" | "exists";
    field: string;
    value: any;
  };
  onFilterChange: (updatedFilter: any) => void;
  onDelete: () => void;
}

const AudienceFilter: React.FC<AudienceFilterProps> = ({ filter, onFilterChange, onDelete }) => {

  const [fields, setFields] = useState([]);
  const [events, setEvents] = useState([]);
  const [tags, setTags] = useState([]);
  const entityOptions = ['members', 'events', 'rsvps', 'communities'];
  const conditionOptions = ['equals', 'contains', 'in', 'notIn', 'exists'];

  useEffect(() => {
    switch (filter.entity) {
      case 'members':
        setFields(['tags']);
        break;
      case 'events':
        setFields(['None']);
        break;
      case 'rsvps':
        setFields(['status', 'event']);
        break;
      case 'communities':
        setFields(['None']);
        break;
      default:
        setFields([]);
    }
  }, [filter.entity]);

  useEffect(() => {
    eventsService.fetchAll().then(fetchedEvents => {
      setEvents(fetchedEvents.data);
    });
    tagsService.fetchAll().then(fetchedTags => {
      setTags(fetchedTags.data);
    });
  }, []);

  const valueContent = () => {
    if (filter.entity === 'rsvps' && filter.field === 'event') {
      return (
        <Autocomplete
          options={events}
          getOptionLabel={(option) => option.title}
          value={events.find(event => event.id === filter.value) || null}
          onChange={(event, newValue) => onFilterChange({ ...filter, value: newValue ? newValue.id : null })}
          renderInput={(params) => <TextField {...params} placeholder="Select Event" />}
          sx={{ width: '170px' }}
        />
      )
    } else if (filter.entity === 'rsvps' && filter.field === 'status') {
      return (
        <Select
          value={filter.value}
          onChange={(e) => onFilterChange({ ...filter, value: e.target.value })}
          displayEmpty
          placeholder='Select Status'
          sx={{ width: '170px' }}
        >
          <MenuItem value="">
            <em>Select Status</em>
          </MenuItem>
          <MenuItem value="attending">Attending</MenuItem>
          <MenuItem value="notAttending">Not Attending</MenuItem>
        </Select>
      )
    } else if (filter.entity === 'members' && filter.field === 'tags') {
      return (
        <Select
          value={filter.value}
          onChange={(e) => onFilterChange({ ...filter, value: e.target.value })}
          displayEmpty
          placeholder='Select Tag'
          sx={{ width: '170px' }}
        >
          <MenuItem value="">
            <em>Select Tag</em>
          </MenuItem>
          {tags.filter(tag => tag.type === 'member' || tag.type === 'tag').map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.title}
            </MenuItem>
          ))}
        </Select>
      )
    }
    return (
      <TextField
        value={filter.value}
        onChange={(e) => onFilterChange({ ...filter, value: e.target.value })}
        placeholder="Enter Value"
      />
    )
  };

  return (
    <Grid container spacing={1.5} direction={{ xs: "column", sm: "row" }} >
      <Grid item>
        <Select
          value={filter.entity}
          onChange={(e) => onFilterChange({ ...filter, entity: e.target.value })}
          displayEmpty
          sx={{ width: '130px' }}
        >
          <MenuItem value="">
            <em>Select Entity</em>
          </MenuItem>
          {entityOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          value={filter.condition}
          onChange={(e) => onFilterChange({ ...filter, condition: e.target.value })}
          displayEmpty
          sx={{ width: '150px' }}
        >
          <MenuItem value="">
            <em>Select Condition</em>
          </MenuItem>
          {conditionOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          value={filter.field}
          onChange={(e) => onFilterChange({ ...filter, field: e.target.value })}
          displayEmpty
          placeholder='Select Field'
          sx={{ width: '160px' }}
        >
          <MenuItem value="">
            <em>Select Field</em>
          </MenuItem>
          {fields.map((field, index) => (
            <MenuItem key={index} value={field}>
              {field.charAt(0).toUpperCase() + field.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        {valueContent()}
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          X
        </Button>
      </Grid>
    </Grid>
  );
};

export default AudienceFilter;