import React from "react";
import {
  Grid,
  TextField,
  Card,
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import pdfIcon from "../../assets/images/icons/file-pdf.png";
import docIcon from "../../assets/images/icons/file-doc.png";
import xlsIcon from "../../assets/images/icons/file-xls.png";
import pptIcon from "../../assets/images/icons/file-ppt.png";
import txtIcon from "../../assets/images/icons/file-txt.png";
import htmlIcon from "../../assets/images/icons/file-html.png";
import { IFile } from "types/files.d";
import { IFolder } from "types/folders.d";
import { useAuth } from "context/Auth";
import filesService from "services/files";

interface MediaProps {
  file: IFile;
  folders: IFolder[];
  getFiles: () => void;
}
const capitalizeFirstLetter = (val: string) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};
const filterOptions = createFilterOptions({
  ignoreCase: true,
});

const Media: React.FC<MediaProps> = ({ file, folders = [], getFiles }) => {
  const [newFile, setNewFile] = React.useState<IFile>({ ...file });
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useAuth();
  const renderFoldersOptionLabel = (option: any) => {
    const folder = folders.find((folder) => folder.id === option);
    if (folder) {
      return `${folder.name}`;
    }
    return option || "";
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      const tempNewFile: IFile = {
        ...newFile,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      await filesService.update(tempNewFile.id || tempNewFile._id, tempNewFile);
    } catch (error: any) {
      console.error("Error saving event:", error);
    } finally {
      setLoading(false);
      getFiles();
    }
  };

  return (
    <>
      <Grid item flexDirection={"row"} gap={"20px"} display={"flex"} width={"100%"}>
        <Card
          style={{
            width: "100%",
            height: "100%",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "500px",
            flex: 1,
          }}
        >
          {file.type === "image" && (
            <img
              src={file.url || file.image.url}
              alt="grid item"
              style={{ objectFit: "contain", width: "70%", maxHeight: "400px" }}
            />
          )}
          {file.type === "video" && (
            <video src={file.url} controls style={{ width: "70%", maxHeight: "400px" }} />
          )}
          {file.type === "application/pdf" && (
            <img style={{ width: "70%" }} src={pdfIcon} alt="pdf click" />
          )}
          {file.type === "application/pdf" && (
            <img style={{ width: "70%" }} src={pdfIcon} alt="pdf click" />
          )}
          {(file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword") && (
              <img style={{ width: "70%" }} src={docIcon} alt="doc click" />
            )}
          {(file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "application/vnd.ms-excel") && (
              <img style={{ width: "70%" }} src={xlsIcon} alt="xls click" />
            )}
          {(file.type ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            file.type === "application/vnd.ms-powerpoint") && (
              <img style={{ width: "70%" }} src={pptIcon} alt="ppt click" />
            )}
          {file.type === "text/plain" && (
            <img style={{ width: "70%" }} src={txtIcon} alt="ppt click" />
          )}
          {file.type === "text/html" && (
            <img style={{ width: "70%" }} src={htmlIcon} alt="ppt click" />
          )}
        </Card>{" "}
        <Card style={{ width: "300px", height: "100%", padding: "20px", gap: "20px" }}>
          {file.name && (
            <TextField
              label="File Name"
              value={newFile.name}
              onChange={(e) => setNewFile({ ...newFile, name: e.target.value })}
              style={{ marginBottom: "20px" }}
              fullWidth
            />
          )}
          {file.title && (
            <TextField
              label="File Title"
              value={newFile.title}
              onChange={(e) => setNewFile({ ...newFile, title: e.target.value })}
              style={{ marginBottom: "20px" }}
              fullWidth
            />
          )}
          {file.description && (
            <TextField
              label="File Description"
              value={newFile.description}
              onChange={(e) => setNewFile({ ...newFile, description: e.target.value })}
              style={{ marginBottom: "20px" }}
              fullWidth
            />
          )}
          <TextField
            label="Created On"
            value={new Date(file.createdDate || file.createdAt).toLocaleDateString()}
            style={{ marginBottom: "20px" }}
            fullWidth
          />
          <TextField
            label="Type"
            value={capitalizeFirstLetter(file.type)}
            style={{ marginBottom: "20px" }}
            fullWidth
          />
          <TextField label="URL" value={file.url} style={{ marginBottom: "20px" }} fullWidth />
          <FormControl fullWidth>
            <FormLabel htmlFor="folders">Folders</FormLabel>
            <Autocomplete
              id="folders"
              multiple
              value={newFile?.folders?.map((folder: any) => folder) || []}
              onChange={(_e, value) => {
                setNewFile({
                  ...newFile,
                  folders: value,
                });
              }}
              filterOptions={filterOptions}
              options={folders.map((folder: any) => folder.id)}
              getOptionLabel={renderFoldersOptionLabel}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            sx={{ mr: 1, marginTop: "auto" }}
          >
            Save Changes
          </Button>
        </Card>
      </Grid>
    </>
  );
};

export default Media;
