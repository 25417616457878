import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";

interface ISubuserResponse {
  id: string;
  username: string;
  email: string;
  status: string;
}

interface IDNSRecord {
  type: string;
  host: string;
  data: string;
  valid: boolean;
}

interface IDomainValidation {
  valid: boolean;
  records: IDNSRecord[];
}

async function createSubuser() {
  const response = await axios.post(`${API_HOST}/sendgrid/subusers`, {}, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getSubuserDomains(subuserId: string) {
  const response = await axios.get(`${API_HOST}/sendgrid/subusers/domains`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function authenticateDomain(subuserId: string, domain: string) {
  const response = await axios.post(`${API_HOST}/sendgrid/domains`, { domain }, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function validateDomain(domainId: string): Promise<IDomainValidation> {
  const response = await axios.post(`${API_HOST}/sendgrid/domains/${domainId}/validate`, {}, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}
async function assignIPs(subuserId: string, ips: string[]) {
  const response = await axios.put(`${API_HOST}/subusers/${subuserId}/ips`, { ips }, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function getDomainDNSRecords(domainId: string): Promise<IDNSRecord[]> {
  const response = await axios.get(`${API_HOST}/domains/${domainId}/dns-records`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}


async function createApiKey(subuserId: string, name: string) {
  const response = await axios.post(`${API_HOST}/subusers/${subuserId}/api-keys`, { name }, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function deleteDomain(domainId: string) {
  const response = await axios.delete(`${API_HOST}/sendgrid/domains/${domainId}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const sendgridService = {
  createSubuser,
  getSubuserDomains,
  // assignIPs,
  authenticateDomain,
  getDomainDNSRecords,
  validateDomain,
  createApiKey,
  deleteDomain
};

export default sendgridService;