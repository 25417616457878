import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import newsService from "services/news";
import { IGenerateNewsResponse } from "types/news";
import filesService from "services/files";
import { generateJSON } from "@tiptap/react";
import { INews } from "types/news";
import { IFile } from "types/files.d";
import StarterKit from "@tiptap/starter-kit";
import { useAuth } from "context/Auth";
import { IAuthor } from "types/authors";

interface NewsGenerateContextType {
  file: IFile | null;
  setFile: React.Dispatch<React.SetStateAction<IFile | null>>;
  shouldGenerateImage: boolean;
  setShouldGenerateImage: React.Dispatch<React.SetStateAction<boolean>>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  author: IAuthor | null;
  setAuthor: React.Dispatch<React.SetStateAction<IAuthor | null>>;
  metadata: Record<string, any>;
  setMetadata: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: React.Dispatch<React.SetStateAction<any>>;
  news: any[];
  setNews: React.Dispatch<React.SetStateAction<any[]>>;
  generateNews: () => Promise<boolean>;
  saveNews: (additionalProps?: Record<string, any>) => Promise<boolean>;
}

interface IGenerateNewsPayload {
  prompt: string;
  author: IAuthor;
  shouldGenerateImage?: boolean;
  defaultImage?: IFile;
}

const NewsGenerateContext = createContext<NewsGenerateContextType | undefined>(undefined);

export const NewsGenerateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const navigate = useNavigate();
  const [file, setFile] = useState<IFile | null>(null);
  const [shouldGenerateImage, setShouldGenerateImage] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [author, setAuthor] = useState<IAuthor | null>(null);
  const [metadata, setMetadata] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [news, setNews] = useState<any[]>([]);

  const { user } = useAuth();

  const generateNews = async () => {
    try {
      setLoading(true);
      if (!prompt || !author) {
        alert("Please fill in the prompt and author");
        throw new Error("Please fill in all fields");
      }

      const payload: IGenerateNewsPayload = {
        prompt: prompt,
        author: author,
        shouldGenerateImage: shouldGenerateImage,
      };

      if (shouldGenerateImage) {
        payload.defaultImage = file;
      }

      const response: IGenerateNewsResponse = await newsService.generate(payload);

      response.content = generateJSON(response.content as unknown as string, [StarterKit]) as JSON;

      let generatedImage: IFile | null;

      if (shouldGenerateImage) {
        generatedImage = await filesService.createFromUrl({
          url: response.image,
        });
      }

      setNews([{
        id: uuidv4(),
        status: "pending",
        ...response,
        defaultImage: generatedImage || file,
        files: [generatedImage || file],
        createdBy: user,
        createdDate: new Date().toISOString(),
      }]);
      return true;
    } catch (error: any) {
      console.error("Error generating news:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveNews = async (additionalProps = {}) => {
    try {
      setLoading(true);
      if (!news.length || !news[0].title || !news[0]?.defaultImage) {
        throw new Error("Please fill in all fields");
      }

      const updatedNews = {
        ...news[0],
        ...additionalProps,
        updatedBy: user,
        updatedAt: new Date().toISOString(),
      };

      const newsWithoutSeedImage = { ...updatedNews };
      delete newsWithoutSeedImage.seedImageURL;
      delete newsWithoutSeedImage.id;
      delete newsWithoutSeedImage._id;
      await newsService.create(newsWithoutSeedImage);
      navigate("/news");
      return true;
    } catch (error: any) {
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewsGenerateContext.Provider
      value={{
        file,
        setFile,
        shouldGenerateImage,
        setShouldGenerateImage,
        prompt,
        setPrompt,
        author,
        setAuthor,
        metadata,
        setMetadata,
        loading,
        setLoading,
        error,
        setError,
        news,
        setNews,
        generateNews,
        saveNews,
      }}
    >
      {children}
    </NewsGenerateContext.Provider>
  );
};

export const useNewsGenerate = () => {
  const context = useContext(NewsGenerateContext);
  if (context === undefined) {
    throw new Error("useNewsGenerate must be used within a NewsGenerateProvider");
  }
  return context;
}; 
