import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  createFilterOptions,
  TextField
} from "@mui/material";
import categoriesService from "../../services/categories";
import { ICategory } from "types/categories";

interface CategorySelectProps {
  value?: ICategory | string;
  onChange: (category: ICategory | null) => void;
}

const CategorySelect: React.FC<CategorySelectProps> = ({ onChange, value }) => {

  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await categoriesService.fetchAll({ pageSize: 1000 });
      setCategories(categories.data);
    };
    fetchCategories();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedCategory = categories.find((category) => category._id === value);
    onChange(selectedCategory || undefined);
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const getCurrentValue = () => {
    if (!value) return null;
    if (typeof value === 'object') return value._id;
    return value;
  };

  return (
    <Autocomplete
      value={getCurrentValue()}
      onChange={(e, value) => handleSelectChange(value)}
      options={categories?.map((category: any) => category._id)}
      filterOptions={filterOptions}
      getOptionLabel={(option: any) => {
        const category = categories.find((category) => category._id === option);
        return category ? category.title : option;
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default CategorySelect;
