import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import locationsService from "services/locations";
import BasicLayout from "components/Layouts/BasicLayout";
import tagsService from "services/tags";
const Locations: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value?.url} alt="Location" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "name", headerName: "Name", minWidth: 200, flex: 2 },
    { field: "instagram", headerName: "Instagram", minWidth: 200, flex: 2 },
    { field: "city", headerName: "City", minWidth: 200, flex: 2 },
    { field: "link", headerName: "Link", minWidth: 200, flex: 2 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Locations</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/locations/create">
            <Icon>add</Icon>
            Create Location
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="locations"
              defaultSortField="name"
              defaultSortDirection="asc"
              columns={columns}
              service={locationsService}
              filterOptions={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  label: "City",
                  name: "city",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  name: "tags",
                  label: "Tags",
                  type: "autocomplete",
                  tagType: "location",
                  service: tagsService,
                  fieldName: "title",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Locations;
