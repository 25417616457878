import React, { useState, useEffect } from "react";
import { useTemplateContext } from "./TemplateContext";
import LoadingOverlay from "components/LoadingOverlay";
import Handlebars from "handlebars";
import { ITemplateModelRequirement } from "types/templates";

// Register Handlebars helpers
Handlebars.registerHelper('formatDate', function (date) {
  if (!date) return '';
  // Create a date object from the UTC string
  const utcDate = new Date(date);
  // Format the date in the local timezone
  return utcDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC' // Ensure we use UTC
  });
});

Handlebars.registerHelper('formatTime', function (time) {
  if (!time) return '';

  // Parse the 24-hour time string (e.g., "14:30")
  const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));

  // Convert to 12-hour format
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format minutes with leading zero
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${hours12}:${formattedMinutes} ${period}`;
});

Handlebars.registerHelper('formatDateTime', function (date) {
  if (!date) return '';
  const utcDate = new Date(date);
  return utcDate.toLocaleString('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
});

Handlebars.registerHelper('truncate', function (str, length) {
  if (!str) return '';
  return str.length > length ? str.substring(0, length) + '...' : str;
});

Handlebars.registerHelper('capitalize', function (str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
});

Handlebars.registerHelper('uppercase', function (str) {
  if (!str) return '';
  return str.toUpperCase();
});

Handlebars.registerHelper('lowercase', function (str) {
  if (!str) return '';
  return str.toLowerCase();
});

// Sample data for different model types
const SAMPLE_DATA = {
  event: {
    title: "Summer Concert Series",
    startDate: "2024-07-01",
    startTime: "18:00",
    endTime: "21:00",
    defaultImage: {
      url: "https://picsum.photos/400/400"
    },
    description: "Join us for an evening of live music under the stars!",
    location: {
      name: "City Park Amphitheater",
      address1: "123 Park Avenue",
      city: "Springfield",
      state: "IL",
      zipCode: "62701"
    },
    category: {
      name: "Music"
    },
    allowRSVP: true,
    link: "https://example.com/rsvp/summer-concert"
  },
  organization: {
    name: "Community Events",
    defaultImage: {
      url: "https://picsum.photos/400/100"
    },
    description: "Bringing the community together through memorable events",
    phoneNumber: "(555) 123-4567",
    socialMedia: {
      facebook: "https://facebook.com/communityevents",
      twitter: "https://twitter.com/communityevents",
      instagram: "https://instagram.com/communityevents"
    }
  },
  news: {
    title: "City Announces New Park Development",
    publishDate: "2023-11-15",
    author: "Jane Smith",
    defaultImage: {
      url: "https://picsum.photos/400/300"
    },
    summary: "The city council has approved plans for a new community park.",
    content: "<p>The city council voted unanimously to approve the development of a new community park in the downtown area. Construction is expected to begin next spring.</p><p>The park will feature playgrounds, walking trails, and a small amphitheater for community events.</p>",
    tags: ["parks", "development", "community"]
  },
  member: {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "(555) 987-6543",
    address: {
      street: "456 Main St",
      city: "Springfield",
      state: "IL",
      zipCode: "62701"
    },
    memberSince: "2022-03-15",
    membershipLevel: "Gold",
    preferences: {
      newsletterOptIn: true,
      eventNotifications: true
    }
  },
  from: {
    email: "events@example.com",
    name: "Community Events"
  }
};

const PreviewTab = () => {
  const { template, setTemplate, loading } = useTemplateContext();
  const [viewType, setViewType] = useState<"html" | "text">("html");
  const [testData, setTestData] = useState(() => {
    // Initialize testData from template.testData if available
    return template?.testData || "{}";
  });
  const [renderedContent, setRenderedContent] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isTestDataValid, setIsTestDataValid] = useState(true);

  // Generate initial test data based on model requirements
  useEffect(() => {
    if (template?.requirements?.models && !template.testData) {
      const initialTestData: Record<string, any> = {};

      // Add sample data for each required model
      Object.entries(template.requirements.models).forEach(([modelKey, modelReq]) => {
        if (SAMPLE_DATA[modelKey]) {
          initialTestData[modelKey] = SAMPLE_DATA[modelKey];
        }
      });

      // Always include organization by default if not already defined
      if (!initialTestData.organization && SAMPLE_DATA.organization) {
        initialTestData.organization = SAMPLE_DATA.organization;
      }

      // Add from by default for email templates
      if (template.type === 'email' && SAMPLE_DATA.from) {
        initialTestData.from = SAMPLE_DATA.from;
      }

      const formattedData = JSON.stringify(initialTestData, null, 2);
      setTestData(formattedData);

      // Update template data
      setTemplate(prevTemplate => {
        if (!prevTemplate) return prevTemplate;
        return {
          ...prevTemplate,
          testData: formattedData
        };
      });
    }
  }, [template?.requirements?.models, template?.type, template?.testData, setTemplate]);

  // Load testData from template when it changes
  useEffect(() => {
    if (template?.testData) {
      setTestData(template.testData);
    }
  }, [template?.testData]);

  // Render the template whenever the template content or test data changes
  useEffect(() => {
    if (!template || !template[viewType]) {
      setRenderedContent("");
      return;
    }

    try {
      // Get the template content
      const templateContent = template[viewType] || "";

      // Parse the test data
      let data = {};
      try {
        data = JSON.parse(testData || "{}");
        setIsTestDataValid(true);
      } catch (jsonErr) {
        setError("Invalid JSON data: " + (jsonErr as Error).message);
        setIsTestDataValid(false);
        return;
      }

      // Compile and render the template in one step to avoid issues
      const rendered = Handlebars.compile(templateContent)(data);
      setRenderedContent(rendered);
      setError(null);
    } catch (err) {
      console.error("Template rendering error:", err);
      setError("Template rendering error: " + (err as Error).message);
      setRenderedContent("");
    }
  }, [template, viewType, testData]);

  const handleViewChange = (value: "html" | "text") => {
    setViewType(value);
  };

  const handleTestDataChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setTestData(newValue);

    // Update the template's testData property
    setTemplate(prevTemplate => {
      if (!prevTemplate) return prevTemplate;
      return {
        ...prevTemplate,
        testData: newValue
      };
    });
  };

  const formatJson = () => {
    try {
      const parsedData = JSON.parse(testData);
      const formattedData = JSON.stringify(parsedData, null, 2);
      setTestData(formattedData);

      // Update template data
      setTemplate(prevTemplate => {
        if (!prevTemplate) return prevTemplate;
        return {
          ...prevTemplate,
          testData: formattedData
        };
      });

      setIsTestDataValid(true);
      setError(null);
    } catch (err) {
      setError("Cannot format: Invalid JSON data");
      setIsTestDataValid(false);
    }
  };

  const renderModelRequirements = () => {
    if (!template?.requirements?.models || Object.keys(template.requirements.models).length === 0) {
      return null;
    }

    return (
      <div className="mb-3">
        <div className="text-sm font-medium text-gray-700 mb-2">Template Data Requirements:</div>
        <div className="flex flex-wrap gap-2">
          {Object.entries(template.requirements.models).map(([key, model]) => (
            <div key={key} className="inline-flex items-center rounded-full px-3 py-1 bg-gray-100 text-sm">
              {key}
              {model.required && <span className="ml-1 text-red-500">*</span>}
              {model.isRecipient && <span className="ml-1 text-purple-500">👤</span>}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPreview = () => {
    if (!template) return null;

    if (error) {
      return (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          {error}
        </div>
      );
    }

    if (viewType === "html") {
      return (
        <div
          className="bg-white p-4 rounded-lg shadow"
          dangerouslySetInnerHTML={{ __html: renderedContent }}
        />
      );
    }

    return (
      <div className="bg-white p-4 rounded-lg shadow whitespace-pre-wrap font-mono">
        {renderedContent}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {loading && <LoadingOverlay loading={loading} />}

      <div className="grid gap-6">
        {renderModelRequirements()}

        <div>
          <div className="flex justify-between items-center mb-1">
            <label htmlFor="testData" className="block text-sm font-medium text-gray-700">
              Test Data (JSON)
            </label>
            <button
              type="button"
              onClick={formatJson}
              className="text-xs bg-gray-200 hover:bg-gray-300 text-gray-700 py-1 px-2 rounded"
            >
              Format JSON
            </button>
          </div>
          <textarea
            id="testData"
            rows={10}
            value={testData}
            onChange={handleTestDataChange}
            className={`w-full p-2 border rounded-md bg-white font-mono text-sm ${isTestDataValid ? 'border-gray-300' : 'border-red-500'
              }`}
          />
        </div>

        {template?.type === "email" && (
          <div>
            <div className="border-b border-gray-300 mb-4">
              <div className="flex">
                <button
                  className={`px-4 py-2 ${viewType === "html" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-500"}`}
                  onClick={() => handleViewChange("html")}
                >
                  HTML
                </button>
                <button
                  className={`px-4 py-2 ${viewType === "text" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-500"}`}
                  onClick={() => handleViewChange("text")}
                >
                  Plain Text
                </button>
              </div>
            </div>
          </div>
        )}

        <div>
          {renderPreview()}
        </div>
      </div>
    </div>
  );
};

export default PreviewTab; 