import React from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "./LocationContext";
import DataSourceList from 'components/DataSourceList/DataSourceList';
import { IDataSource } from "types/dataSource.d";
import dataSourcesService from "services/dataSources";
import { DataSourceProvider } from "context/DataSource";

const LocationDataSources: React.FC = () => {
  const { location, refetchLocation } = useLocation();
  const [dataSources, setDataSources] = React.useState<IDataSource[]>([]);

  React.useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const response = await dataSourcesService.fetchAll({
          query: [{
            field: "parentId",
            value: location?.id,
            operator: "eq",
          }]
        });
        const formattedDataSources = response.data.map(source => ({
          ...source,
          id: source._id
        }));
        setDataSources(formattedDataSources);
      } catch (e) {
        console.error("Error fetching data sources:", e);
      }
    };
    if (location?.id) {
      fetchDataSources();
    }
  }, [location?.id]);

  const handleDataSourceChange = async () => {
    await refetchLocation();
    const response = await dataSourcesService.fetchAll({
      query: [{
        field: "parentId",
        value: location?.id,
        operator: "eq",
      }]
    });
    const formattedDataSources = response.data.map(source => ({
      ...source,
      id: source._id
    }));
    setDataSources(formattedDataSources);
  };

  if (!location?.id) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Data Sources</Typography>
        <DataSourceProvider
          onComplete={handleDataSourceChange}
          defaultValues={{
            parentType: "location",
            parentId: location?.id || '',
            type: "website",
            dataType: "events",
            url: "",
            scrapingInterval: "daily",
            active: true,
          }}
        >
          <DataSourceList
            dataSources={dataSources}
            parentId={location?.id || ''}
            parentType="location"
            onDataSourceChange={handleDataSourceChange}
          />
        </DataSourceProvider>
      </Grid>
    </Grid>
  );
};

export default LocationDataSources; 