import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import PaginatedDataGrid from "components/PaginatedDataGrid";
import newsService from "services/news";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import { capitalizeFirstLetter } from "utils/commonFunctions";
import tagsService from "services/tags";

const NewsList: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value?.url} alt="News URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return <>{params?.value?.name}</>;
      },
    },
    {
      field: "createdDate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{new Date(params?.value).toLocaleString()}</>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">News</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"} gap={2}>
          <Button variant="contained" color="secondary" component={Link} to="/news/generate">
            <Icon>add</Icon>
            Quick Add
          </Button>
          <Button variant="contained" color="primary" component={Link} to="/news/create">
            <Icon>add</Icon>
            Add News
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="news"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={newsService}
              filterOptions={[
                {
                  label: "Title",
                  name: "title",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
                },
                {
                  label: "Date",
                  name: "dateRange",
                  type: "date",
                  operator: "eq",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
                },
                {
                  name: "tags",
                  label: "Tags",
                  type: "autocomplete",
                  tagType: "news",
                  service: tagsService,
                  fieldName: "title",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
                },
                {
                  label: "Status",
                  name: "status",
                  type: "select",
                  options: ['draft', 'published', 'cancelled'].map((value) => ({
                    label: capitalizeFirstLetter(value),
                    value,
                  })),
                  operator: "eq",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default NewsList;
