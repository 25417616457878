import { Box } from "@mui/material";
import { useEvent } from "./EventContext";
import { IFile } from "types/files.d";
import FileGallery from 'components/FileGallery';
import { IEvent } from "types/events.d";
const MediaTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  const handleUpdateMedia = (files: IFile[]) => {
    const newEvent = {
      ...event,
      files: files
    };

    if (!event.defaultImage) {
      newEvent.defaultImage = files.find(f => f.type.startsWith('image')) || null;
    }
    if (!event.defaultVideo) {
      newEvent.defaultVideo = files.find(f => f.type.startsWith('video')) || null;
    }
    setEvent(newEvent);
  };

  const handleDeleteMedia = (file: IFile) => {
    setEvent({
      ...event,
      files: event.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setEvent({
      ...event,
      files: event.files?.filter(f => !files.includes(f)) || []
    });
  };

  const handleSetDefaultImage = (file: IFile) => {
    setEvent((prevEvent) => (
      { ...prevEvent, defaultImage: file }
    ));
  };

  const handleSetDefaultVideo = (file: IFile) => {
    setEvent((prevEvent) => (
      { ...prevEvent, defaultVideo: file }
    ));
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        data={event as IEvent}
        files={event?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
        onSetDefaultImage={handleSetDefaultImage}
        onSetDefaultVideo={handleSetDefaultVideo}
      />
    </Box>
  );
};

export default MediaTab;
