import React from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Grid, IconButton, Typography, Button, Box } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import LocationDetails from "./LocationDetails";
import LocationEvents from "./LocationEvents";
import LocationDataSources from "./LocationDataSources";
import LocationMedia from "./LocationMedia";
import LocationAdmin from "./LocationAdmin";
import LocationNav from "./LocationNav";
import { LocationProvider, useLocation } from "./LocationContext";

const LocationContent: React.FC = () => {
  const navigate = useNavigate();
  const { location, loading, handleSave } = useLocation();

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => navigate("/locations")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">
              {location?.name ? `Location: ${location.name}` : "Location"}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LocationNav />
        </Grid>
        <Grid item xs={12}>
          <Routes>
            <Route path="details" element={<LocationDetails />} />
            <Route path="events" element={<LocationEvents />} />
            <Route path="data-sources" element={<LocationDataSources />} />
            <Route path="media" element={<LocationMedia />} />
            <Route path="admin" element={<LocationAdmin />} />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

// Main router component that provides the context
const Location: React.FC = () => {
  return (
    <LocationProvider>
      <LocationContent />
    </LocationProvider>
  );
};

export default Location; 