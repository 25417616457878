import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "context/Auth";
import Logo from "assets/images/logos/logo_blue.png";
import CityViewLogoCity from "assets/images/logos/CityViewLogoCity.png";

interface Props {
  init?: any;
  children?: React.ReactNode;
}

const SimpleSideNav: React.FC<Props> = ({ children }) => {
  const { logout, user } = useAuth();
  const { pathname } = useLocation();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [menuSectionExpanded, setMenuSectionExpanded] = useState("");
  const [currentParent, setCurrentParent] = useState("");
  const [openUserMenu, setUserOpenMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      // Find the parent section that contains the current pathname
      const currentSection = user.routes.find((section: any) =>
        section.collapse?.some((route: any) => route.route === pathname)
      );
      setCurrentParent(currentSection?.key || "");
    }
  }, [pathname, user]);

  // Close drawer when clicking outside on mobile
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.getElementById("sidebar");
      if (drawerIsOpen && sidebar && !sidebar.contains(event.target as Node)) {
        setDrawerIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerIsOpen]);

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleClick = (key: string) => {
    setMenuSectionExpanded(key === menuSectionExpanded ? "" : key);
  };

  const renderNavLinks = () => {
    return user?.routes?.map((section: any) => {
      const isCurrentParentRoute = currentParent === section.key;
      const userExpanded = menuSectionExpanded === section.key;

      // Handle direct route items without collapse
      if (section.type === "item") {
        const isCurrentRoute = pathname === section.route;
        return (
          <Link
            className={`flex items-center px-2 py-1 mb-2 rounded-lg transition-colors ${isCurrentRoute ? "text-blue-600" : "text-gray-500 hover:bg-gray-100"
              }`}
            key={section.route}
            to={section.route}
          >
            <div className="flex items-center justify-start gap-3 w-full">
              {section.icon && (
                <span className="text-lg material-icons">{section.icon}</span>
              )}
              <span className="flex-1 text-sm font-medium">{section.name}</span>
            </div>
          </Link>
        );
      }

      // Handle routes with collapse/children
      const visibleRoutes = section.collapse?.filter((route: any) => route.type !== "hidden") || [];
      const hasChildren = visibleRoutes.length > 0;

      if (hasChildren) {
        return (
          <div key={section.key} className="mb-1 px-2 py-1 ">
            <button
              className="flex items-center justify-between w-full text-gray-500 hover:bg-gray-100 rounded-lg transition-colors"
              onClick={() => handleClick(section.key)}
            >
              <div className="flex items-center gap-3">
                {section.icon && (
                  <span className="text-lg material-icons">{section.icon}</span>
                )}
                <span className="text-sm font-medium">{section.name}</span>
              </div>
              <svg
                className={`w-5 h-5 transition-transform duration-200 ${userExpanded || isCurrentParentRoute ? "rotate-180" : ""
                  }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              className={`pl-10 mt-1 space-y-1 overflow-hidden transition-all duration-200 ${userExpanded || isCurrentParentRoute
                ? "max-h-96 opacity-100"
                : "max-h-0 opacity-0"
                }`}
            >
              {visibleRoutes.map((route: any) => {
                const isCurrentRoute = pathname === route.route;
                return (
                  <Link
                    className={`block py-1 px-1 text-sm rounded-lg transition-colors ${isCurrentRoute
                      ? "text-blue-600"
                      : "text-gray-500 hover:bg-gray-100"
                      }`}
                    key={route.route}
                    to={route.route}
                  >
                    {route.name}
                  </Link>
                );
              })}
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="flex h-screen bg-white">
      {/* Mobile menu overlay */}
      {drawerIsOpen && (
        <div
          className="fixed inset-0 z-20 bg-black bg-opacity-50 lg:hidden"
          onClick={() => setDrawerIsOpen(false)}
        ></div>
      )}

      {/* Mobile header with hamburger */}
      <div className="fixed top-0 left-0 right-0 z-10 flex items-center justify-between px-4 py-2 bg-white border-b lg:hidden">
        <img src={Logo} alt="CityView CMS Logo" className="h-8" />
        <button
          className="p-2 text-gray-600 rounded-md hover:bg-gray-100"
          onClick={() => setDrawerIsOpen(!drawerIsOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {/* Sidebar/Drawer */}
      <aside
        id="sidebar"
        className={`fixed flex flex-col justify-between inset-y-0 left-0 z-30 w-64 bg-white border-r transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static ${drawerIsOpen ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div>
          {/* Logo */}
          <div className="flex justify-between lg:justify-center p-2 ml-4 border-b">
            <Link to="/" className="flex items-center">
              <img src={Logo} alt="CityView CMS Logo" className="h-8 mt-2" />
            </Link>
            <button
              className="text-gray-500 hover:text-gray-800 lg:hidden"
              onClick={() => setDrawerIsOpen(false)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          {/* Close button - only on mobile */}


          {/* Navigation Links */}
          <nav className="flex flex-col flex-1 p-4 overflow-y-auto">
            {renderNavLinks()}
          </nav>
        </div>

        {/* User Profile */}
        <div className="p-4 border-t">
          <div className="relative">
            <button
              onClick={() => setUserOpenMenu(!openUserMenu)}
              className="flex items-center w-full p-1 rounded-lg hover:bg-gray-100"
            >
              <div className="flex items-center gap-3">
                {user?.imageUrl ? (
                  <img
                    src={user.imageUrl}
                    alt="avatar"
                    className="w-8 h-8 rounded-full object-cover"
                    onError={(e) => {
                      console.error("Image failed to load:", e);
                    }}
                  />
                ) : (
                  <span className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-600">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zm-4 7a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      ></path>
                    </svg>
                  </span>
                )}
                <span className="font-medium text-sm text-gray-700">{user?.name}</span>
              </div>
              <svg
                className={`w-5 h-5 ml-auto transition-transform ${openUserMenu ? "rotate-180" : ""
                  }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {/* User dropdown menu */}
            {openUserMenu && (
              <div className="z-10 mt-2 bg-white rounded-md shadow-lg border">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setUserOpenMenu(false)}
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full text-left text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </aside>

      {/* Main content area */}
      <main className="flex-1 overflow-auto pt-14 lg:pt-0">
        {children}
      </main>
    </div>
  );
};

export default SimpleSideNav;