import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useAuth } from "context/Auth";
import { ICategory } from "types/categories";
import categoriesService from "services/categories";
import LoadingOverlay from "components/LoadingOverlay";
import Image from "components/Images/Image";
import { IFile } from "types/files.d";
import AITextField from "components/AITextField";

interface CategoryFormProps {
  id: string;
  category: ICategory;
  setCategory: (category: ICategory) => void;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ id, category, setCategory }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });

  const { user } = useAuth();

  const handleSave = async () => {
    try {
      setLoading(true);
      const newCategory: ICategory = {
        ...category,
      };
      console.log(newCategory);
      if (!newCategory?.title || !newCategory?.description || !category?.defaultImage?.url) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        newCategory.createdBy = user;
        newCategory.createdDate = new Date().toISOString();
        await categoriesService.create(newCategory);
      } else {
        newCategory.updatedBy = user;
        newCategory.updatedAt = new Date().toISOString();
        await categoriesService.update(newCategory.id, newCategory);
      }
      navigate("/categories");
    } catch (error) {
      console.error("Error saving category:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await categoriesService.remove(id);
      navigate("/categories");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleImageAccept = (image: IFile) => {
    if (image && image.url) {
      setCategory({
        ...category,
        defaultImage: image,
      });
    } else {
      console.error("Invalid image data");
    }
  };

  return (
    <Grid container direction={"row"} spacing={2} marginLeft={2}>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px ' }}>
          <Image
            onAccept={handleImageAccept}
            src={category?.defaultImage?.url}
            alt={category?.title}
            bucketPath="cityview-cms/categories"
            folderPath="Categories Media"
          />
        </Grid>
        <Grid item xs={12} md={7} spacing={2} sx={{ width: '75%' }}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel required htmlFor="category-name">
                Name
              </FormLabel>
              <TextField
                id="category-name"
                value={category?.title || ""}
                required
                onChange={(e) => setCategory({ ...category, title: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <AITextField
                id="description"
                label="Description"
                prompt="From the following details, create a description for an category. The description should be descriptive and engaging and be at least 3 sentences long."
                promptMode="modal"
                value={category?.description || ""}
                required
                onChange={(val: any) => setCategory({ ...category, description: val })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} />
      {id && id !== "create" && (
        <Grid item xs={12}>
          <Typography variant="h4" color="alert">
            Danger Zone
          </Typography>
          <Button
            disabled={loading}
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Grid>
      )}
      <LoadingOverlay loading={loading} />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Grid>
  );
}

export default CategoryForm;