/**
 * Audio and haptic feedback utility functions for the application
 */

// Track if audio has been unlocked by user interaction
let audioContextUnlocked = false;
let audioContext: AudioContext | null = null;

/**
 * Check if the device supports vibration
 */
export const supportsVibration = (): boolean => {
  return 'vibrate' in navigator;
};

/**
 * Make the device vibrate with a specific pattern
 * @param pattern - Vibration pattern in milliseconds (alternating vibration and pause)
 */
export const vibrate = (pattern: number | number[]): void => {
  try {
    if (supportsVibration()) {
      navigator.vibrate(pattern);
    }
  } catch (error) {
    console.error('Error vibrating device:', error);
  }
};

/**
 * Vibrate with a success pattern (short pulse)
 */
export const vibrateSuccess = (): void => {
  vibrate(100); // Short 100ms vibration
};

/**
 * Vibrate with an error pattern (longer pattern)
 */
export const vibrateError = (): void => {
  vibrate([200, 50, 200, 50, 200, 50, 200]); // 100ms vibration, 50ms pause, 200ms vibration
};

/**
 * Initialize audio context - should be called after user interaction
 * This helps unlock audio on iOS and some browsers that require user interaction
 */
export const initAudio = (): void => {
  // Create and start a silent audio context to unlock audio
  try {
    const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
    if (AudioContextClass) {
      // Create audio context if it doesn't exist
      if (!audioContext) {
        audioContext = new AudioContextClass();
      }

      // Resume audio context if it's suspended
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }

      // Create a silent oscillator to unlock audio on iOS
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      gainNode.gain.value = 0; // Silent
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // Play and stop immediately to unlock audio context
      oscillator.start(0);
      oscillator.stop(0.001);

      audioContextUnlocked = true;
    }
  } catch (error) {
    console.error('Error initializing audio context:', error);
  }
};

/**
 * Generate a beep sound using Web Audio API
 * @param frequency - The frequency of the beep in Hz
 * @param duration - The duration of the beep in milliseconds
 * @param type - The type of oscillator wave
 * @param volume - The volume of the beep (0-1)
 */
const generateBeep = (
  frequency: number = 440,
  duration: number = 200,
  type: OscillatorType = 'sine',
  volume: number = 0.5
): void => {
  try {
    if (!audioContext) {
      const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
      if (AudioContextClass) {
        audioContext = new AudioContextClass();
      } else {
        throw new Error('Web Audio API not supported in this browser');
      }
    }

    // Resume context if suspended (needed for Safari)
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }

    // Create oscillator and gain nodes
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    // Configure oscillator
    oscillator.type = type;
    oscillator.frequency.value = frequency;

    // Configure gain (volume)
    gainNode.gain.value = volume;

    // Connect nodes
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    // Start and stop the oscillator
    const now = audioContext.currentTime;
    oscillator.start(now);
    oscillator.stop(now + duration / 1000);

    // Add a slight fade out to avoid clicks
    gainNode.gain.exponentialRampToValueAtTime(0.001, now + duration / 1000);
  } catch (error) {
    console.error('Error generating beep:', error);
  }
};

/**
 * Play a success sound (short high beep) and vibrate if supported
 */
export const playSuccessSound = (): void => {
  try {
    // If we haven't unlocked audio yet, try to initialize
    if (!audioContextUnlocked) {
      initAudio();
    }

    // Play a success sound (higher frequency, shorter duration)
    generateBeep(1200, 150, 'sine', 0.3);

    // Vibrate with success pattern
    vibrateSuccess();
  } catch (error) {
    console.error('Error playing success sound:', error);
  }
};

/**
 * Play an error sound (short low buzz) and vibrate if supported
 */
export const playErrorSound = (): void => {
  try {
    // If we haven't unlocked audio yet, try to initialize
    if (!audioContextUnlocked) {
      initAudio();
    }

    // Play an error sound (lower frequency, longer duration)
    generateBeep(300, 250, 'square', 0.3);

    // Vibrate with error pattern
    vibrateError();
  } catch (error) {
    console.error('Error playing error sound:', error);
  }
};

export default {
  playSuccessSound,
  playErrorSound,
  initAudio,
  vibrateSuccess,
  vibrateError,
  supportsVibration,
  vibrate
}; 