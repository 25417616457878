import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { ITag } from '../../types/tags';
import tagsService from '../../services/tags';

interface TagMultiSelectProps {
  selectedTags?: any;
  setSelectedTags?: (tags: ITag[]) => void;
  type?: string[];
}

const TagMultiSelect: React.FC<TagMultiSelectProps> = (props) => {

  const { selectedTags, setSelectedTags, type } = props;
  const [tags, setTags] = useState<ITag[]>([]);

  useEffect(() => {
    const fetchTags = async () => {
      const tags = await tagsService.fetchAll({
        limit: 999,
        pageSize: 999
      });
      setTags(tags.data);
    };
    fetchTags();
  }, []);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 999,
  });

  return (
    <Autocomplete
      multiple
      value={selectedTags || []}
      onChange={(_e, value) => {
        setSelectedTags?.(value);
      }}
      filterOptions={filterOptions}
      options={type ? tags.filter((tag: any) => type.includes(tag.type)) : tags}
      getOptionLabel={(option: ITag) => {
        return option.title;
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
    />
  );
};

export default TagMultiSelect;