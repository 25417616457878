import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import TicketForm from 'components/TicketForm/TicketForm';
import { ITicket } from 'types/tickets';
import { IUser } from '../../types/users.d';
import ticketsService from 'services/tickets';

interface TicketFormModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (ticket: ITicket) => Promise<void>;
  initialTicket?: Partial<ITicket>;
  user: IUser;
  rsvp?: any;
}

const TicketFormModal: React.FC<TicketFormModalProps> = ({
  open,
  onClose,
  onSave,
  initialTicket = {},
  user,
  rsvp,
}) => {
  const [ticket, setTicket] = React.useState<Partial<ITicket>>(initialTicket);
  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    await onSave(ticket as ITicket);
    onClose();
  };

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        setLoading(true);
        const _ticket = await ticketsService.fetch(initialTicket.id);
        setTicket(_ticket);
      } catch (e) {
        console.error("Error fetching ticket:", e);
      } finally {
        setLoading(false);
      }
    };
    if (initialTicket.id) {
      fetchTicket();
    }
  }, [initialTicket]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {initialTicket?.id ? 'Edit Ticket' : 'Create Ticket'}
      </DialogTitle>
      <DialogContent>
        {!loading && (
          <TicketForm
            ticket={ticket}
            setTicket={setTicket}
            user={user}
            rsvp={rsvp}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketFormModal; 