import React from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useCommunity } from "./CommunityContext";
import { formatDate, formatTime } from "utils/commonFunctions";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const CommunityEvents: React.FC = () => {
  const navigate = useNavigate();

  const { community } = useCommunity();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'defaultImage',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value?.url}
          alt={params.row.title}
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    { field: 'title', headerName: 'Title', flex: 2 },
    {
      field: 'startDate',
      headerName: 'Start',
      width: 200,
      valueFormatter: (params) => formatDate(new Date(params.value)),
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      width: 200,
      valueFormatter: (params) => formatTime(params.value),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleViewEvent(params.row.id)}
            size="medium"
          >
            <Visibility />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {community && community?.events && community.events.length > 0 ? (
          <DataGrid
            rows={community.events}
            columns={columns}
            paginationModel={{ page: 0, pageSize: 10 }}
            autoHeight
            disableRowSelectionOnClick
          />
        ) : (
          <Typography>No events</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CommunityEvents; 