import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  loading?: boolean;
}

function LoadingOverlay(props: Props) {
  const { loading } = props;
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 9999 }}
      open={!!loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingOverlay;