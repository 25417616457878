import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useContentType } from "./ContentTypeContext";
import contentTypeService from "services/contentTypes";

const ContentTypeAdmin: React.FC = () => {

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { loading, saveContentType } = useContentType();

  const handleDelete = async () => {
    try {
      await contentTypeService.remove(id);
      navigate("/content-types");
    } catch (error) {
      console.error("Error deleting contentType:", error);
    }
  };

  const handleSave = async () => {
    const result = await saveContentType();
    if (result) {
      navigate("/content-types");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {id && id !== "create" && (
          <>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Content Type
            </Button>
            <Typography variant="h6" color="error" sx={{ mt: 2 }}>
              Danger Zone
            </Typography>
            <Button
              disabled={loading}
              variant="contained"
              color="error"
              onClick={handleDelete}
              sx={{ mt: 1 }}
            >
              Delete Content Type
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ContentTypeAdmin; 