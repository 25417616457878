import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";

const LocationNav: React.FC = () => {

  const location = useLocation();
  const currentTab = location.pathname.split('/').pop() || 'details';

  const tabPaths = {
    details: 'details',
    events: 'events',
    'data-sources': 'data-sources',
    media: 'media',
    admin: 'admin'
  };

  return (
    <Tabs value={tabPaths[currentTab] || 'details'}>
      <Tab label="Details" value="details" component={Link} to={tabPaths.details} />
      <Tab label="Events" value="events" component={Link} to={tabPaths.events} />
      <Tab label="Data Sources" value="data-sources" component={Link} to={tabPaths['data-sources']} />
      <Tab label="Media" value="media" component={Link} to={tabPaths.media} />
      <Tab label="Admin" value="admin" component={Link} to={tabPaths.admin} />
    </Tabs>
  );
};

export default LocationNav; 