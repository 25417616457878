import { useCallback, useEffect, useState } from 'react';
import { TextField, InputAdornment, Icon } from '@mui/material';
import { debounce } from 'lodash';
import { FilterComponentProps } from './types';

export const TextFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  const debouncedOnChange = useCallback(
    debounce((newValue: string) => {
      onChange(newValue ? {
        field: filterOption.name,
        value: newValue,
        operator: filterOption.operator || 'search'
      } : null);
    }, 500),
    [filterOption, onChange]
  );

  const [inputValue, setInputValue] = useState(value || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    debouncedOnChange(newValue);
  };

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <TextField
      type={filterOption.type === 'number' || filterOption.type === 'int' ? 'number' : 'text'}
      name={`search-${filterOption.name}-${filterOption.operator}`}
      id={`grid-search-${filterOption.name}-${filterOption.operator}`}
      fullWidth
      value={inputValue}
      placeholder={filterOption?.placeholder ?? `Enter a ${filterOption.label}`}
      autoComplete="off"
      inputProps={{
        autoComplete: 'new-password',
        form: { autoComplete: 'off' },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      sx={{
        '& .MuiInputBase-root': {
          height: '53px', // Increase the height as needed
        }
      }}
    />
  );
}; 