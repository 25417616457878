import React, { createContext, useCallback, useState, useEffect, ReactNode, useMemo } from "react";
import authService from "../services/auth";
import TokenHandler from "../services/base";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { IUser } from "../types/users.d";
import { IOrganization } from "../types/organizations.d";
import userService from "../services/users";
export const AuthContext = createContext<any>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);
  const [currentOrganization, setCurrentOrganization] = useState<IOrganization | null>(null);
  const [error, setError] = useState<any>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const navigate = useNavigate();

  const isAuthenticated = useMemo(() => !!user, [user]);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const token = TokenHandler.getToken();
      if (token) {
        const { user } = await authService.me();
        setUser(user);
        setCurrentOrganization(user.currentOrganization);
        setHasSubscription(
          user?.currentOrganization?.subscription?.status === 'active' ||
          user?.currentOrganization?.subscription?.status === 'trialing' ||
          user?.currentOrganization?.subscription?.status === 'incomplete'
        );
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Failed to fetch user:', error);
      setError(error);
      setUser(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const register = useCallback(async (props: any) => {
    setLoading(true);
    try {
      const response = await authService.register(props);
      setUser(response.user);
      setCurrentOrganization(response.user.currentOrganization);
      TokenHandler.setCurrentOrganization(response.user.currentOrganization);
      TokenHandler.setToken(response.token);
      navigate('/plans'); // Redirect to plans page after registration
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [navigate]);

  const login = useCallback(async (username: string, password: string) => {
    try {
      setLoading(true);
      setError(null);
      const loginResponse = await authService.login(username, password);
      TokenHandler.setToken(loginResponse.token);
      TokenHandler.setCurrentOrganization(loginResponse.user.currentOrganization);
      await fetchUser();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [fetchUser]);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await authService.logout();
      setUser(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const getToken = useCallback(async () => {
    try {
      const token = TokenHandler.getToken();
      return token;
    } catch (error) {
      console.error(error);
    }
    return null;
  }, []);

  const refreshUser = async () => {
    try {
      const { user: userData } = await authService.me();
      setUser(userData);
      setCurrentOrganization(userData.currentOrganization);
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  const switchOrganization = async (organization: IOrganization) => {
    try {
      await userService.switchOrganization(organization.id);
      TokenHandler.setCurrentOrganization(organization);
      setCurrentOrganization(organization);
      await refreshUser();

      // Update subscription status for the new organization
      setHasSubscription(
        organization?.subscription?.status === 'active' ||
        organization?.subscription?.status === 'trialing' ||
        organization?.subscription?.status === 'incomplete'
      );

      // Reload the page to clear all stale data
      window.location.reload();
    } catch (error) {
      console.error('Failed to switch organization:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        error,
        loading,
        isAuthenticated,
        user,
        hasSubscription,
        login,
        logout,
        register,
        getToken,
        refreshUser,
        currentOrganization,
        setCurrentOrganization,
        switchOrganization
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
