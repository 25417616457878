import React, { useCallback, useState } from "react";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import contentsService from "services/contents";
import { useContentType } from "./ContentTypeContext";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/LoadingOverlay";

const ContentTypeList: React.FC = () => {
  const { contentType } = useContentType();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteItemsArray, setDeleteItemsArray] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleSelectIdToDelete = useCallback((idArray: string[]) => {
    if (!idArray || !idArray?.length) return;
    setDeleteItemsArray(idArray);
    setShowDeleteDialog(true);
  }, []);

  const handleDelete = useCallback(
    ({ idArray, onEachSuccess }: { idArray: string[]; onEachSuccess?: (id: string) => void }) => {
      const deleteActions = idArray.map(async (id) => {
        try {
          await contentsService.remove(id);
          onEachSuccess && onEachSuccess(id);
        } catch (error: any) {
          console.error("Error deleting:", error);
        }
      });
      Promise.allSettled(deleteActions).then(() => {
        setShowDeleteDialog(false);
      });
    },
    [contentsService, contentType._id]
  );


  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <PencilIcon
              className="w-6 h-6 mr-3 text-blue-600 cursor-pointer opacity-90 hover:opacity-100 hover:scale-110 transition-all"
              onClick={() => {
                navigate(`/content/${params?.id}`)
              }}
            />
            <TrashIcon
              className="w-6 h-6 mr-3 text-red-500 cursor-pointer opacity-90 hover:opacity-100 hover:scale-110 transition-all"
              onClick={() => handleSelectIdToDelete([params?.id])}
            />
          </>
        );
      },
    }
  ];

  React.useEffect(() => {
    if (contentType && contentType._id) {
      setIsLoading(false);
    }
  }, [contentType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
        <Typography variant="h4">Content</Typography>
      </Grid>
      <Grid item xs={2} sm={4}></Grid>
      <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
        {!isLoading && (
          <Button variant="contained" color="primary" component={Link} to={`/content/create/${contentType._id}`}>
            <Icon>add</Icon>
            Add Content
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box>
          {contentType && contentType._id ? (
            <PaginatedDataGrid
              collectionName="contents"
              defaultSortField="title"
              defaultSortDirection="asc"
              columns={columns}
              service={contentsService}
              showActions={false}
              defaultQuery={[{ field: "contentType._id", operator: "eq", value: contentType._id }]}
            />
          ) : (
            <Typography>Please save the content type to create content</Typography>
          )}
        </Box>
      </Grid>
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Warning!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowDeleteDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} direction={"row"} width={"100%"}>
            <Grid
              item
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              display={"flex"}
              height={"100%"}
              color={"000"}
            >
              <strong>
                Are you sure you want to delete
                {deleteItemsArray?.length > 1
                  ? ` these ${deleteItemsArray.length} items? `
                  : ` this item? `}
                This action cannot be undone.
              </strong>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent={"flex-end"} spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: "#7b809a" }}
                onClick={() => {
                  setShowDeleteDialog(false);
                  setDeleteItemsArray([]);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: "#F45D49" }}
                onClick={() =>
                  handleDelete({
                    idArray: deleteItemsArray,
                    onEachSuccess: (id: string) => {
                      setDeleteItemsArray((prev) => prev.filter((item) => item !== id));
                    },
                  })
                }
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default ContentTypeList;
