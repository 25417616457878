import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { IFilterOptionOption, FilterOption } from '../PaginationFilter';
import { FilterComponentProps } from './types';

interface FilterValue {
  field: string;
  value: string | number | string[];
  operator: string;
  backendField?: string;
  backendOperator?: string;
  backendValue?: string[];
}
interface query {
  field: string;
  operator: string;
  value: string | number | string[];
}


export const AutocompleteFilter: React.FC<FilterComponentProps> = ({ filterOption, value, onChange }) => {
  // We want a single selection even for tags
  const [options, setOptions] = useState<IFilterOptionOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IFilterOptionOption | null>(null);

  const fetchOptions = async (search: string = '') => {
    if (!filterOption.service) return;
    setLoading(true);
    try {
      const fieldName = filterOption.fieldName || 'title';
      const query: query[] = search
        ? [{ field: fieldName, operator: 'search', value: search }]
        : [];
      if (filterOption.tagType) {
        query.push({
          field: 'type',
          operator: 'in',
          value: [filterOption.tagType, 'tag']
        });
      }
      const { data } = await filterOption.service.fetchAll({
        query,
        page: 0,
        pageSize: 10
      });
      setOptions(
        data.map((item: any) => ({
          value: item.id || item._id,
          label: item[fieldName],
          originalItem: item
        }))
      );
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (value && options.length > 0) {
      const matchingOption = options.find(option => option.value === value.value);
      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    } else if (!value) {
      setSelectedOption(null);
    }
  }, [value, options]);

  return (
    <Autocomplete
      id={filterOption.name}
      fullWidth
      options={options}
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 2) {
          fetchOptions(newInputValue);
        } else if (newInputValue.length === 0) {
          fetchOptions();
        }
      }}
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
        if (newValue) {
          const filterValue: FilterValue = {
            field: filterOption.name,
            value: newValue.value,
            operator: filterOption.operator || 'eq'
          };

          // If the filter is for tags, adjust the filter object.
          // Even though objects can have multiple tags, the user is only choosing one.
          if (filterOption.name === 'tags' && newValue.originalItem?.slug) {
            filterValue.backendField = 'tags.slug';
            filterValue.backendOperator = 'in';
            filterValue.backendValue = [newValue.originalItem.slug];
          }
          onChange(filterValue);
        } else {
          onChange(null);
        }
      }}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={filterOption.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      style={{ minHeight: "53px" }}
    />
  );
};