import { FC, useState, Fragment } from 'react';
import { TicketType, TicketTypeOption } from 'types/events.d';

interface TicketOptionsDialogProps {
  open: boolean;
  onClose: () => void;
  ticketTypes: TicketType[];
  currentTicketTypeIndex: number | null;
  onAddOption: (option: TicketTypeOption) => void;
  onUpdateOption: (optionIndex: number, option: TicketTypeOption) => void;
  onDeleteOption: (optionIndex: number) => void;
}

const TicketOptionsDialog: FC<TicketOptionsDialogProps> = ({
  open,
  onClose,
  ticketTypes,
  currentTicketTypeIndex,
  onAddOption,
  onUpdateOption,
  onDeleteOption
}) => {
  const [newOption, setNewOption] = useState<Partial<TicketTypeOption>>({
    name: '',
    type: 'text',
    required: false,
    options: [],
    price: 0
  });
  const [optionDialogMode, setOptionDialogMode] = useState<'add' | 'edit'>('add');
  const [currentOptionIndex, setCurrentOptionIndex] = useState<number | null>(null);
  const [newOptionValue, setNewOptionValue] = useState('');

  const resetNewOption = () => {
    setNewOption({
      name: '',
      type: 'text',
      required: false,
      options: [],
      price: 0
    });
    setOptionDialogMode('add');
    setCurrentOptionIndex(null);
    setNewOptionValue('');
  };

  const handleDialogClose = () => {
    resetNewOption();
    onClose();
  };

  const handleAddOption = () => {
    if (currentTicketTypeIndex === null) return;

    if (!newOption.name) {
      alert('Option name is required');
      return;
    }

    if (newOption.type === 'select' && (!newOption.options || newOption.options.length === 0)) {
      alert('Select type requires at least one option');
      return;
    }

    if (optionDialogMode === 'add') {
      // Generate a temporary ID for new options
      const tempId = `temp_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
      const newOptionWithId = {
        ...newOption,
        _id: tempId,
        required: newOption.required || false
      } as TicketTypeOption;

      onAddOption(newOptionWithId);
    } else if (optionDialogMode === 'edit' && currentOptionIndex !== null) {
      const updatedOption = {
        ...ticketTypes[currentTicketTypeIndex].ticketTypeOptions?.[currentOptionIndex],
        ...newOption,
        required: newOption.required || false
      } as TicketTypeOption;

      onUpdateOption(currentOptionIndex, updatedOption);
    }

    resetNewOption();
  };

  const handleEditOption = (optionIndex: number) => {
    if (currentTicketTypeIndex === null) return;

    const option = ticketTypes[currentTicketTypeIndex].ticketTypeOptions?.[optionIndex];
    if (!option) return;

    setNewOption({
      name: option.name,
      description: option.description,
      type: option.type,
      required: option.required,
      options: option.options,
      price: option.price || 0
    });
    setOptionDialogMode('edit');
    setCurrentOptionIndex(optionIndex);
  };

  const handleAddOptionValue = () => {
    if (!newOption.options) {
      setNewOption({ ...newOption, options: [newOptionValue] });
    } else {
      setNewOption({ ...newOption, options: [...newOption.options, newOptionValue] });
    }
    setNewOptionValue('');
  };

  const handleRemoveOptionValue = (valueIndex: number) => {
    if (!newOption.options) return;

    const updatedValues = newOption.options.filter((_, i) => i !== valueIndex);
    setNewOption({ ...newOption, options: updatedValues });
  };

  const getTypeLabel = (type: string): string => {
    switch (type) {
      case 'text': return 'Text';
      case 'number': return 'Number';
      case 'boolean': return 'Yes/No';
      case 'select': return 'Select';
      default: return type;
    }
  };

  const currentTicket = currentTicketTypeIndex !== null && ticketTypes[currentTicketTypeIndex]
    ? ticketTypes[currentTicketTypeIndex]
    : null;

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={handleDialogClose}></div>

        {/* Modal panel */}
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
          {/* Header */}
          <div className="bg-gray-50 border-b px-6 py-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">
                {currentTicket
                  ? `Options for ${currentTicket.name}`
                  : 'Ticket Options'
                }
              </h2>
              <button
                onClick={handleDialogClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            {currentTicket && (
              <div className="flex flex-col space-y-8">
                {/* Option Form Section */}
                <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
                  <h3 className="text-base font-semibold text-gray-800 mb-5">
                    {optionDialogMode === 'add' ? 'Add New Option' : 'Edit Option'}
                  </h3>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                    {/* Option Name */}
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Option Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={newOption.name}
                        onChange={(e) => setNewOption({ ...newOption, name: e.target.value })}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                      />
                    </div>

                    {/* Description */}
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description
                      </label>
                      <input
                        type="text"
                        value={newOption.description || ''}
                        onChange={(e) => setNewOption({ ...newOption, description: e.target.value })}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
                    {/* Type Selector */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Type
                      </label>
                      <select
                        value={newOption.type}
                        onChange={(e) => setNewOption({ ...newOption, type: e.target.value as any })}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="boolean">Yes/No</option>
                        <option value="select">Select</option>
                      </select>
                    </div>

                    {/* Price Field */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Price
                      </label>
                      <input
                        type="number"
                        value={newOption.price || 0}
                        min="0"
                        step="0.01"
                        onChange={(e) => setNewOption({ ...newOption, price: parseFloat(e.target.value) })}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    {/* Required Switch */}
                    <div className="flex items-center mt-6">
                      <input
                        id="required-toggle"
                        type="checkbox"
                        checked={newOption.required || false}
                        onChange={(e) => setNewOption({ ...newOption, required: e.target.checked })}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label htmlFor="required-toggle" className="ml-2 block text-sm text-gray-700">
                        Required
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-3">
                    {/* Action Buttons */}
                    <div className="flex space-x-3">
                      <button
                        type="button"
                        onClick={handleAddOption}
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${optionDialogMode === 'add'
                          ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                          : 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                          }`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                          {optionDialogMode === 'add' ? (
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                          ) : (
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          )}
                        </svg>
                        {optionDialogMode === 'add' ? 'Add Option' : 'Update Option'}
                      </button>

                      {optionDialogMode === 'edit' && (
                        <button
                          type="button"
                          onClick={resetNewOption}
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          Cancel Edit
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Options for Select type */}
                  {newOption.type === 'select' && (
                    <div className="mt-5 p-5 border border-blue-100 rounded-lg bg-blue-50">
                      <h4 className="text-sm font-medium text-blue-800 mb-4">Select Options</h4>

                      <div className="flex items-center gap-3 mb-4">
                        <input
                          type="text"
                          value={newOptionValue}
                          onChange={(e) => setNewOptionValue(e.target.value)}
                          placeholder="Enter an option value"
                          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={handleAddOptionValue}
                          disabled={!newOptionValue}
                          className="inline-flex items-center px-3 py-2 border border-blue-500 text-sm font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                          </svg>
                          Add
                        </button>
                      </div>

                      {newOption.options && newOption.options.length > 0 && (
                        <div className="flex flex-wrap gap-2 mt-3">
                          {newOption.options.map((option, idx) => (
                            <div key={idx} className="flex items-center bg-white px-3 py-1.5 rounded-full border border-blue-200 text-sm">
                              <span className="text-blue-700">{option}</span>
                              <button
                                type="button"
                                onClick={() => handleRemoveOptionValue(idx)}
                                className="ml-1 text-blue-400 hover:text-blue-600 focus:outline-none"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Current Options Section */}
                <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
                  <h3 className="text-base font-semibold text-gray-800 mb-4">Current Options</h3>

                  {!currentTicket.ticketTypeOptions?.length ? (
                    <div className="p-8 text-center border border-dashed border-gray-300 rounded-lg bg-white">
                      <p className="text-gray-500">No options configured yet. Add an option above.</p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 gap-3 max-h-72 overflow-y-auto p-1 pr-2">
                      {currentTicket.ticketTypeOptions?.map((option, idx) => (
                        <div
                          key={option._id || idx}
                          className="flex justify-between items-start p-4 bg-white rounded-lg border border-gray-200 hover:shadow-md transition-shadow"
                        >
                          <div className="flex-grow pr-4">
                            <h4 className="text-sm font-medium text-gray-800">{option.name}</h4>
                            <div className="flex items-center flex-wrap gap-x-2 mt-1.5">
                              {option.description && (
                                <span className="text-xs text-gray-500 italic">{option.description}</span>
                              )}
                              <span className="text-xs px-2 py-0.5 rounded-full bg-blue-100 text-blue-800 font-medium">
                                {getTypeLabel(option.type)}
                              </span>
                              {option.required && (
                                <span className="text-xs px-2 py-0.5 rounded-full bg-red-100 text-red-800 font-medium">
                                  Required
                                </span>
                              )}
                              {option.price !== undefined && option.price > 0 && (
                                <span className="text-xs px-2 py-0.5 rounded-full bg-green-100 text-green-800 font-medium">
                                  ${option.price.toFixed(2)}
                                </span>
                              )}
                            </div>

                            {option.type === 'select' && option.options && option.options.length > 0 && (
                              <div className="mt-2.5 flex flex-wrap gap-1.5">
                                {option.options.map((value, valueIdx) => (
                                  <span
                                    key={valueIdx}
                                    className="text-xs px-2 py-0.5 rounded-full bg-gray-100 text-gray-700"
                                  >
                                    {value}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="flex gap-1 shrink-0">
                            <button
                              type="button"
                              onClick={() => handleEditOption(idx)}
                              className="p-1 rounded-full text-blue-600 hover:text-blue-800 hover:bg-blue-50 focus:outline-none"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              onClick={() => onDeleteOption(idx)}
                              className="p-1 rounded-full text-red-600 hover:text-red-800 hover:bg-red-50 focus:outline-none"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="bg-gray-50 border-t px-6 py-4">
            <button
              type="button"
              onClick={handleDialogClose}
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketOptionsDialog; 