import axios from "axios";
import { API_HOST } from "../config";
import TokenHandler from "./base";

const service = 'worker-activity';

export async function fetchAll(paginationModel: any = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/${service}`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const response = await axios.get(url.toString(), {
    params: {
      query: paginationModel.query,
      sort: paginationModel.sort,
    },
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/${service}/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export async function create(data: any) {
  const response = await axios.post(`${API_HOST}/${service}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export async function update(id: string, data: any) {
  const response = await axios.put(`${API_HOST}/${service}/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

export async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/${service}/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const workerActivitiesService = {
  fetchAll,
  fetch,
  create,
  update,
  remove,
};
export default workerActivitiesService;