import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import * as workerActivityService from 'services/workerActivity';
import { WorkerActivity } from 'types/workerActivity.d';

const WorkerActivityJSON: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [workerActivity, setWorkerActivity] = useState<WorkerActivity | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWorkerActivity = async () => {
      try {
        if (id) {
          const data = await workerActivityService.fetch(id);
          setWorkerActivity(data);
        }
      } catch (e) {
        console.error('Error fetching worker activity:', e);
        setError('Failed to fetch worker activity data');
      }
    };

    fetchWorkerActivity();
  }, [id]);

  if (error) {
    return (
      <Box mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!workerActivity) {
    return (
      <Box mt={4}>
        <Typography>Loading worker activity data...</Typography>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>Worker Activity JSON Data</Typography>
      <Paper
        sx={{
          p: 2,
          maxHeight: '600px',
          overflow: 'auto',
          backgroundColor: '#f5f5f5',
          '& pre': {
            margin: 0,
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word'
          }
        }}
      >
        <pre>
          {JSON.stringify(workerActivity, null, 2)}
        </pre>
      </Paper>
    </Box>
  );
};

export default WorkerActivityJSON;
