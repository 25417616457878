import React, { useState, useRef, useEffect } from 'react';
import { ITicket } from 'types/tickets';
import TicketFormModal from 'components/TicketFormModal/TicketFormModal';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import rsvpsService from 'services/rsvps';
import ticketsService from 'services/tickets';
import { Dialog, DialogContent, DialogTitle, CircularProgress, Alert } from '@mui/material';
import { IUser } from '../../types/users.d';
interface RSVPTicketsProps {
  tickets: ITicket[];

  onUpdate?: () => void;
  event: any;
  member: any;
  rsvp: any;
  loading?: boolean;
  pdfLoading?: boolean;
  user: IUser;
}

const RSVPTickets: React.FC<RSVPTicketsProps> = ({
  tickets = [],
  onUpdate,
  event,
  member,
  rsvp,
  loading: loadingProp = false,
  pdfLoading = false,
  user,
}) => {
  const [loading, setLoading] = useState(loadingProp);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Partial<ITicket> | undefined>();
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [qrTicket, setQRTicket] = useState<ITicket | null>(null);
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendResponse, setResendResponse] = useState<{ message: string; notificationId: string } | null>(null);
  const [resendError, setResendError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  const handleAddClick = () => {
    setSelectedTicket({ event, member, rsvp });
    setIsModalOpen(true);
  };

  const handleEditClick = (ticket: ITicket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const handleSave = async (ticket: ITicket) => {
    try {
      setLoading(true);
      setError(null);
      if (ticket.id) {
        await ticketsService.update(ticket.id, ticket);
      } else {
        await ticketsService.create(ticket);
      }
      setIsModalOpen(false);
      onUpdate?.();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to save ticket');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const blob = await fetch(dataUrl).then(res => res.blob());
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob })
        ]);
        // You could add a toast notification here to show success
      } catch (err) {
        console.error('Failed to copy QR code:', err);
        // You could add a toast notification here to show error
      }
    }
  };

  const downloadQRCode = async () => {
    if (qrCodeRef.current) {
      try {
        const dataUrl = await toPng(qrCodeRef.current);
        const link = document.createElement('a');
        link.download = `ticket_qr_${qrTicket?.ticketNumber || 'ticket'}.png`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Failed to download QR code:', err);
        // You could add a toast notification here to show error
      }
    }
  };

  const handleRegenerateTickets = async () => {
    try {
      setLoading(true);
      setError(null);
      await rsvpsService.regenerateTickets(rsvp.id);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to regenerate tickets');
    } finally {
      setLoading(false);
    }
  };

  const handleRegeneratePDF = async () => {
    try {
      setLoading(true);
      setError(null);
      await rsvpsService.regenerateTicketsPDF(rsvp.id);
      alert('PDF regenerated successfully');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to regenerate PDF');
    } finally {
      setLoading(false);
    }
  };

  const handleResendConfirmation = async () => {
    setResendLoading(true);
    setResendError(null);
    setResendResponse(null);

    try {
      const response = await rsvpsService.resendConfirmation(rsvp.id);
      setResendResponse(response);
    } catch (error) {
      setResendError(error instanceof Error ? error.message : 'Failed to resend confirmation');
    } finally {
      setResendLoading(false);
    }
  };

  const handleDeleteTicket = async (ticketId: string) => {
    await ticketsService.remove(ticketId);
    onUpdate();
  };

  return (
    <div>
      {error && <Alert severity="error">{error}</Alert>}
      <div className="flex justify-between mb-2 p-2 items-center">
        <h1 className="text-xl font-bold">Tickets</h1>
        <div className="flex gap-2">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors text-sm flex items-center gap-2"
            onClick={handleAddClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Add Ticket
          </button>

          {/* <button
            disabled={loading}
            className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors text-sm flex items-center gap-2 disabled:opacity-50"
            onClick={handleRegenerateTickets}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 100-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
            </svg>
            Regenerate Tickets
          </button> */}

          <button
            disabled={pdfLoading || loading}
            className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition-colors text-sm flex items-center gap-2 disabled:opacity-50"
            onClick={handleRegeneratePDF}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
            </svg>
            Regenerate PDF
          </button>

          {rsvp?.ticketsPdfUrl && (
            <a
              href={rsvp.ticketsPdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700 transition-colors text-sm flex items-center gap-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
              </svg>
              View PDF
            </a>
          )}

          <button
            disabled={loading}
            className="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-700 transition-colors text-sm flex items-center gap-2"
            onClick={handleResendConfirmation}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            Resend Confirmation
          </button>
        </div>
      </div>

      <div className="overflow-hidden rounded-sm shadow-sm">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-1/5 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ticket Number
                </th>
                <th className="w-1/5 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="w-1/5 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="w-1/5 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Price
                </th>
                <th className="w-1/5 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tickets.map((ticket) => (
                <tr key={ticket.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {ticket.ticketNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {ticket.ticketType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {ticket.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {`${ticket.currency} ${ticket.price}`}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                    <button
                      onClick={() => handleEditClick(ticket)}
                      className="text-blue-600 hover:text-blue-900 mx-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleDeleteTicket(ticket.id)}
                      className="text-red-600 hover:text-red-900 mx-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <TicketFormModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedTicket(undefined);
        }}
        onSave={handleSave}
        initialTicket={selectedTicket}
        user={user}
        rsvp={rsvp}
      />

      {qrTicket && (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${isQRModalOpen ? '' : 'hidden'}`}>
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Ticket QR Code</h3>
              <button
                onClick={() => {
                  setIsQRModalOpen(false);
                  setQRTicket(null);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex flex-col items-center">
              <div ref={qrCodeRef}>
                <QRCode
                  value={qrTicket.ticketNumber}
                  size={200}
                  level="H"
                  className="mb-4"
                />
              </div>
              <p className="text-sm text-gray-600 mb-4">Ticket Number: {qrTicket.ticketNumber}</p>
              <div className="flex gap-2">
                <button
                  onClick={copyToClipboard}
                  className="flex items-center gap-1 bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors text-sm"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                    <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                  </svg>
                  Copy
                </button>
                <button
                  onClick={downloadQRCode}
                  className="flex items-center gap-1 bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 transition-colors text-sm"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog open={resendLoading || !!resendResponse || !!resendError} onClose={() => {
        setResendResponse(null);
        setResendError(null);
      }}>
        <DialogTitle>
          {resendLoading ? 'Sending Confirmation...' :
            resendResponse ? 'Success' :
              resendError ? 'Error' : ''}
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center p-4">
            {resendLoading && (
              <CircularProgress />
            )}
            {resendResponse && (
              <div className="text-center">
                <p className="mb-4">{resendResponse.message}</p>
                <a
                  href={`/notifications/${resendResponse.notificationId}`}
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  View Notification
                </a>
              </div>
            )}
            {resendError && (
              <p className="text-red-600">{resendError}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RSVPTickets; 