import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import templatesService from 'services/templates';
import { ITemplate } from 'types/templates';

interface TemplateSelectProps {
  value: ITemplate | string | null;
  onChange: (template: ITemplate | null) => void;
  label?: string;
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({ value, onChange, label = 'Template' }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ITemplate[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const { data: templates } = await templatesService.fetchAll();
        if (active) {
          setOptions(templates);
        }
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [open]);

  // Convert string value to template object if needed
  const getCurrentValue = () => {
    if (!value) return null;
    if (typeof value === 'string') {
      return options.find(opt => opt._id === value) || null;
    }
    return value;
  };

  return (
    <Autocomplete
      id="template-select"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === 'string') return option._id === value;
        return option._id === value._id;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return '';  // This shouldn't happen but TypeScript needs it
        return option?.name || '';
      }}
      options={options}
      loading={loading}
      value={getCurrentValue()}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default TemplateSelect; 