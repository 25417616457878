import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { useEventGenerate } from "./EventGenerateContext";
import { DateTime } from "luxon";

const EventCard: React.FC<{ event: any }> = ({ event }) => {
  const formatDateTime = () => {
    if (!event.startDate || !event.startTime) return 'No date set';

    // Parse the date and time
    const date = DateTime.fromISO(event.startDate, { zone: 'utc' });
    const [hours, minutes] = event.startTime.split(':');

    // Combine date with time
    const dateTime = date.set({
      hour: parseInt(hours),
      minute: parseInt(minutes)
    });

    return dateTime.toFormat("cccc, LLLL d, yyyy 'at' h:mm a");
  };

  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      {/* Image Section */}
      <div className="w-full md:w-1/3 h-48 md:h-full relative">
        {event.defaultImage ? (
          <img
            src={event.defaultImage.url}
            alt={event.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Typography className="text-gray-400">No Image</Typography>
          </div>
        )}
        <div className="absolute top-2 right-2 px-2 py-1 rounded bg-white/90 text-sm">
          {event.status}
        </div>
      </div>

      {/* Content Section */}
      <div className="flex-1 p-4">
        <h3 className="text-xl font-semibold mb-2">{event.title}</h3>

        <div className="space-y-2">
          {/* Date/Time */}
          <div className="flex items-center text-gray-600">
            <span className="material-icons-outlined text-lg mr-2">event</span>
            <span>{formatDateTime()}</span>
          </div>

          {/* Location */}
          {event.location && (
            <div className="flex items-center text-gray-600">
              <span className="material-icons-outlined text-lg mr-2">place</span>
              <span>{event.location.name}</span>
            </div>
          )}

          {/* Price */}
          {event.price && (
            <div className="flex items-center text-gray-600">
              <span className="material-icons-outlined text-lg mr-2">sell</span>
              <span>{event.price}</span>
            </div>
          )}

          {/* Link */}
          {event.link && (
            <div className="flex items-center text-gray-600 overflow-hidden">
              <span className="material-icons-outlined text-lg mr-2">link</span>
              <a
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 truncate"
              >
                {event.link}
              </a>
            </div>
          )}

          {/* Tags */}
          {event.tags && event.tags.length > 0 && (
            <div className="flex flex-wrap gap-1 mt-3">
              {event.tags.map((tag: any) => (
                <span
                  key={tag.id}
                  className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                >
                  {tag.title}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FinalizeStep: React.FC = () => {
  const { events } = useEventGenerate();

  return (
    <div className="space-y-6">
      <Typography variant="h6" className="mb-4">Final Review</Typography>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {events.map((event) => (
          <EventCard key={event._id} event={event} />
        ))}
      </div>
    </div>
  );
};

export default FinalizeStep; 