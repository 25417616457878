import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IFile } from 'types/files.d';
import { IFolder } from 'types/folders.d';
import filesService from 'services/files';
import foldersService from 'services/folders';
import { IPaginationModal } from 'types/App';
import { IDataGridPageInfo } from 'components/PaginatedDataGrid/PaginatedDataGrid';

interface MediaGalleryContextType {
  // State
  loading: boolean;
  error: any;
  gridData: IFile[];
  rowCount: number;
  pageCount: number;
  selectedFiles: IFile[];
  folders: IFolder[];
  viewFolder: IFolder | null;
  file: File | null;
  paginationModel: IPaginationModal & IDataGridPageInfo;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  selectionMethod: 'single' | 'multiple';
  totalFiles: number;
  // Actions
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;
  setSelectedFiles: (files: IFile[]) => void;
  setViewFolder: (folder: IFolder | null) => void;
  setFile: (file: File | null) => void;
  setPaginationModel: (model: IPaginationModal & IDataGridPageInfo) => void;
  setSortField: (field: string) => void;
  setSortDirection: (direction: 'asc' | 'desc') => void;
  // Methods
  getFiles: () => Promise<void>;
  getFolders: () => Promise<void>;
  indexPage: (index: number) => void;
  getPaginationString: () => string;
  uploadFile: (file: File) => Promise<void>;
  setPageSize: (size: number) => void;
  handleDelete: () => Promise<void>;
}

const MediaGalleryContext = createContext<MediaGalleryContextType | undefined>(undefined);

export const MediaGalleryProvider: React.FC<{
  children: React.ReactNode;
  selectionMethod?: 'single' | 'multiple';
  onChangeFileSelection?: (files: IFile[]) => void;
}> = ({ children, selectionMethod = 'single', onChangeFileSelection }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [gridData, setGridData] = useState<IFile[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [folders, setFolders] = useState<IFolder[]>([]);
  const [viewFolder, setViewFolder] = useState<IFolder | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [sortField, setSortField] = useState('createdDate');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [totalFiles, setTotalFiles] = useState(0);

  const [paginationModel, setPaginationModel] = useState<IPaginationModal & IDataGridPageInfo>({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    if (onChangeFileSelection) {
      onChangeFileSelection(selectedFiles);
    }
  }, [selectedFiles]);


  useEffect(() => {
    getFiles();
  }, [paginationModel, sortDirection, sortField]);

  const getFolders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await foldersService.fetchAll();
      setFolders(response.data);
    } catch (error) {
      console.error(`Error fetching folders Data`, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getFiles = useCallback(async () => {
    try {
      setLoading(true);
      const params: any = {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort: {
          [sortField]: sortDirection,
        }
      }
      if (viewFolder) {
        params.query = {
          operator: "and",
          conditions: [
            {
              field: "folder._id",
              value: viewFolder.id,
              operator: "eq"
            }
          ]
        };
      }
      const response = await filesService.fetchAll(params);

      setGridData(response.data);
      if (!totalFiles) {
        setTotalFiles(response.total);
      }
      setRowCount(response.total);
      setPageCount(Math.ceil(response.total / paginationModel.pageSize));
    } catch (error) {
      console.error(`Error fetching files Data`, error);
      setError(error);
    } finally {
      getFolders();
      setLoading(false);
    }
  }, [paginationModel, sortDirection, sortField, viewFolder, getFolders]);

  useEffect(() => {
    getFiles();
  }, [viewFolder]);


  const indexPage = (index: number) => {
    const newPage = paginationModel.page + index;
    if (newPage >= 0 && newPage < pageCount) {
      setPaginationModel({ ...paginationModel, page: newPage });
      getFiles();
    }
  };

  const getPaginationString = () => {
    const minItem = paginationModel.page * paginationModel.pageSize + 1;
    const maxItem = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCount);
    return `Showing ${minItem}-${maxItem} of ${rowCount}`;
  };

  const uploadFile = async (file: File) => {
    try {
      const response = await filesService.upload(file, viewFolder?.id);
      if (response) {
        getFiles();
      }
    } catch (error) {
      console.error(`Error uploading file`, error);
      setError(error);
    }
  };

  const setPageSize = (size: number) => {
    setPaginationModel({ ...paginationModel, pageSize: size });
    getFiles();
  };

  const handleDelete = async () => {
    try {

      await Promise.all(selectedFiles.map(file => filesService.remove(file._id)));
      await getFiles();
      setTotalFiles(totalFiles - selectedFiles.length);
      setSelectedFiles([]);
    } catch (error) {
      console.error(`Error deleting files`, error);
      setError(error);
    }
  };

  const value = {
    // State
    loading,
    error,
    gridData,
    rowCount,
    pageCount,
    selectedFiles,
    folders,
    viewFolder,
    file,
    paginationModel,
    sortField,
    sortDirection,
    selectionMethod,
    totalFiles,
    // Actions
    setLoading,
    setError,
    setSelectedFiles,
    setViewFolder,
    setFile,
    setPaginationModel,
    setSortField,
    setSortDirection,

    // Methods
    getFiles,
    getFolders,
    indexPage,
    getPaginationString,
    uploadFile,
    setPageSize,
    handleDelete,
  };

  return <MediaGalleryContext.Provider value={value}>{children}</MediaGalleryContext.Provider>;
};

export const useMediaGallery = () => {
  const context = useContext(MediaGalleryContext);
  if (context === undefined) {
    throw new Error('useMediaGallery must be used within a MediaGalleryProvider');
  }
  return context;
};
