import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import rsvpsService from "services/rsvps";
import { IRSVP } from "types/rsvp.d";
import { IMember } from "types/members.d";
import { IEvent } from "types/events.d";
import RSVPTickets from 'components/RSVPTickets/RSVPTickets';
import EventSelect from 'components/EventSelect';
import MemberSelect from 'components/MemberSelect';

const RSVP: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rsvp, setRSVP] = useState<Partial<IRSVP> | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchRSVP = async () => {
      try {
        const rsvp = await rsvpsService.fetch(id);
        setRSVP(rsvp);
      } catch (e) {
        console.error("Error fetching RSVP:", e);
      }
    };

    if (id && id !== "create") {
      fetchRSVP();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newRSVP: Partial<IRSVP> = {
        ...rsvp,
        updatedBy: user?.uid,
        updatedDate: new Date(),
      };

      if (!newRSVP.member || !newRSVP.event || !newRSVP.status || newRSVP.guests === undefined) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newRSVP.createdBy = user?.uid;
        newRSVP.createdDate = new Date();
        newRSVP.eventId = newRSVP.event.slug || newRSVP.event._id;
        newRSVP.memberId = newRSVP.member.id;
        newRSVP.name = newRSVP.member.name;
        newRSVP.email = newRSVP.member.email;

        await rsvpsService.create(newRSVP);
      } else {
        await rsvpsService.update(newRSVP.id, newRSVP);
      }
      navigate("/rsvps");
    } catch (error: any) {
      console.error("Error saving RSVP:", error);
      setError(error?.response?.data ? error.response.data.message : "Error saving RSVP");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await rsvpsService.remove(id);
      navigate("/rsvps");
    } catch (error) {
      console.error("Error deleting RSVP:", error);
    }
  };

  const handleRSVPUpdate = async () => {
    // Refresh RSVP data when tickets are modified
    try {
      const updatedRSVP = await rsvpsService.fetch(id!);
      // Only update if the data has actually changed
      if (JSON.stringify(updatedRSVP) !== JSON.stringify(rsvp)) {
        setRSVP(updatedRSVP);
      }
    } catch (error) {
      console.error("Error refreshing RSVP:", error);
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">RSVP</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel htmlFor="member">Member</FormLabel>
              <MemberSelect
                value={rsvp?.member || null}
                onChange={(member) => setRSVP({ ...rsvp, member })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel htmlFor="event">Event</FormLabel>
              <EventSelect
                value={rsvp?.event || null}
                onChange={(event) => setRSVP({ ...rsvp, event })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="status">Status</FormLabel>
                <Select
                  id="status"
                  value={rsvp?.status || ""}
                  onChange={(e) => setRSVP({ ...rsvp, status: e.target.value as "attending" | "not_attending" | "maybe" })}
                >
                  <MenuItem value="attending">Attending</MenuItem>
                  <MenuItem value="not_attending">Not Attending</MenuItem>
                  <MenuItem value="maybe">Maybe</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="guests">Number of Guests</FormLabel>
                <TextField
                  id="guests"
                  value={rsvp?.guests ?? 0}
                  onChange={(e) => setRSVP({ ...rsvp, guests: parseInt(e.target.value) || 0 })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <TextField
                  id="notes"
                  multiline
                  rows={4}
                  value={rsvp?.notes || ""}
                  onChange={(e) => setRSVP({ ...rsvp, notes: e.target.value })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {id && id !== "create" && (
          <Grid item xs={12}>
            <RSVPTickets
              tickets={rsvp?.tickets || []}
              event={rsvp?.event}
              member={rsvp?.member}
              rsvp={rsvp}
              onUpdate={handleRSVPUpdate}
              user={user}
            />
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default RSVP;