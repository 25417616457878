import React from "react";
import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import Image from "components/Images/Image";
import { useCommunity } from "./CommunityContext";

import { IFile } from "types/files.d";
import AITextField from "components/AITextField";

export const CommunityDetails: React.FC = () => {

  const { community, setCommunity } = useCommunity();

  return (
    <div>
      <Grid item xs={12} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px ' }}>
            <Image
              onAccept={(image: IFile) => setCommunity({ ...community, defaultImage: image })}
              src={community?.defaultImage?.url}
              alt={community?.name}
              bucketPath="cityview-cms/communities"
              folderPath="Communities Media"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <AITextField
                    label="Name"
                    prompt="From the following details, create a name for an community. The name should be descriptive and engaging and be less than 6 words."
                    promptMode="modal"
                    value={community?.name || ""}
                    onChange={(val: any) => setCommunity({ ...community, name: val })}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <AITextField
                    label="Description"
                    prompt="From the following details, create a description for an community. The description should be descriptive and engaging and be at least 3 sentences long."
                    promptMode="modal"
                    value={community?.description || ""}
                    onChange={(val: any) => setCommunity({ ...community, description: val })}
                    required
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="link">Website</FormLabel>
                  <TextField
                    id="link"
                    value={community?.website || ""}
                    onChange={(e) =>
                      setCommunity({ ...community, website: e.target.value })
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="instagram">Instagram</FormLabel>
                  <TextField
                    id="instagram"
                    value={community?.instagram || ""}
                    onChange={(e) =>
                      setCommunity({ ...community, instagram: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="facebook">Facebook</FormLabel>
                  <TextField
                    id="facebook"
                    value={community?.facebook || ""}
                    onChange={(e) =>
                      setCommunity({ ...community, facebook: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="twitter">Twitter</FormLabel>
                  <TextField
                    id="twitter"
                    value={community?.twitter || ""}
                    onChange={(e) =>
                      setCommunity({ ...community, twitter: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunityDetails;