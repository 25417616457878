import { Box, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { useEvent } from "./EventContext";
import { TicketType } from "types/events.d";
import EventTicketTypes from "components/EventTicketTypes";

const TicketsTab: React.FC = () => {
  const { event, setEvent } = useEvent();

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>Ticket Settings</Typography>
          <FormGroup sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
            <FormControlLabel
              htmlFor="allowTicketSales"
              label={"Allow Ticket Sales"}
              control={
                <Checkbox
                  size="medium"
                  checked={event?.allowTicketSales || false}
                  onChange={(e) => setEvent({ ...event, allowTicketSales: e.target.checked })}
                />
              }
            />
            <FormControlLabel
              htmlFor="createTicketsForRSVP"
              label={"Create Tickets for RSVP"}
              control={
                <Checkbox
                  size="large"
                  checked={event?.createTicketsForRSVP || false}
                  onChange={(e) => setEvent({ ...event, createTicketsForRSVP: e.target.checked })}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      {event?.allowTicketSales && (
        <Grid item xs={12}>
          <EventTicketTypes
            ticketTypes={event?.ticketTypes || []}
            eventId={event?._id || event?.id}
            organizationId={event?.organization}
            onTicketTypesChange={(updatedTicketTypes) =>
              setEvent({ ...event, ticketTypes: updatedTicketTypes as TicketType[] })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TicketsTab; 