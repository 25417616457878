import { ChevronLeft } from "@mui/icons-material";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import EditFolderDialog from "./EditFolderDialog";
import { useMediaGallery } from "./MediaGalleryContext";
import { Button } from "@mui/material";

const GalleryHeader = () => {
  const { viewFolder, setViewFolder, setSelectedFiles, selectedFiles, handleDelete } = useMediaGallery();

  return (
    <div className="w-full bg-white p-5 flex items-center justify-between gap-5 shadow rounded">
      <div className="flex flex-row justify-center items-center h-8">
        <div className="w-8">
          {viewFolder && !selectedFiles.length && (
            <button
              onClick={() => setViewFolder(null)}
              className="hover:bg-gray-100 rounded-full p-2"
            >
              <ChevronLeft />
            </button>
          )}
          {!!selectedFiles.length && (
            <button
              onClick={() => setSelectedFiles([])}
              className="hover:bg-gray-100 rounded-full p-2"
            >
              <ChevronLeft />
            </button>
          )}
        </div>
        <span className="text-lg">
          {viewFolder ? viewFolder?.name : "All Media"}
        </span>
      </div>
      <div className="flex items-center justify-end">
        <div>
          {viewFolder && !selectedFiles.length && (
            <EditFolderDialog />
          )}
        </div>
        {selectedFiles.length > 0 && (
          <div>
            <Button variant="contained" color="error" onClick={() => handleDelete()}>
              {selectedFiles.length > 1 ? "Delete All" : "Delete"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default GalleryHeader;