import { Grid } from "@mui/material";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import newslettersService from "services/newsletters";
import { capitalizeFirstLetter } from "utils/commonFunctions";


const NewsLetterList = () => {

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  return (
    <Grid item xs={12}>
      <PaginatedDataGrid
        collectionName="newsletters"
        defaultSortField="name"
        defaultSortDirection="asc"
        columns={columns}
        service={newslettersService}
        filterOptions={[
          {
            label: "Title",
            name: "title",
            type: "text",
            operator: "search",
            columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
          },
          {
            label: "Subject",
            name: "subject",
            type: "text",
            operator: "search",
            columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
          },
          {
            label: "Status",
            name: "status",
            type: "select",
            options: ['draft', 'published', 'cancelled'].map((value) => ({
              label: capitalizeFirstLetter(value),
              value,
            })),
            operator: "eq",
            columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
          },
        ]}
      />
    </Grid>
  )
};

export default NewsLetterList;