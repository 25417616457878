import { Box, Button, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { useEvent } from "./EventContext";
import membersService from "services/members";
import rsvpsService from "services/rsvps";
import { GridColDef } from "@mui/x-data-grid";
import EventInviteButton from "components/EventInviteButton";

const RSVPsTab: React.FC = () => {

  const { event, setEvent } = useEvent();

  console.log("event", event);

  const columns: GridColDef[] = [
    {
      field: "member.name",
      headerName: "Member",
      flex: 1,
      valueGetter: (params) => params.row.member?.name,
    },
    {
      field: "member.email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => params.row.member?.email,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => {
        const status = params.row.status;
        if (!status) return "Pending";

        // Format the status text
        switch (status) {
          case "attending":
            return "Attending";
          case "not_attending":
            return "Not Attending";
          case "maybe":
            return "Maybe";
          default:
            return status.charAt(0).toUpperCase() + status.slice(1); // Capitalize any other statuses
        }
      },
    },
    {
      field: "guests",
      headerName: "Attendees",
      flex: 1,
      valueGetter: (params) => params.row.guests,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography><strong>Stats</strong></Typography>
            <Typography>Total RSVPs: {event?.totalRSVPs}</Typography>
            <Typography>Total Attending: {event?.totalAttending}</Typography>
            <Typography>Total Not Attending: {event?.totalNotAttending}</Typography>
            <Typography>Total Maybe: {event?.totalMaybe}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormGroup sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}>
            <FormControlLabel
              htmlFor="allowRSVP"
              label={"Allow RSVP"}
              control={
                <Checkbox
                  size="medium"
                  checked={event?.allowRSVP || false}
                  onChange={(e) => setEvent({ ...event, allowRSVP: e.target.checked })}
                />
              }
            />
            <FormControlLabel
              htmlFor="allowGuests"
              label={"Allow Guests"}
              sx={{ marginBottom: 1 }}
              control={
                <Checkbox
                  size="large"
                  checked={event?.allowGuests === undefined ? true : event?.allowGuests}
                  onChange={(e) => setEvent({ ...event, allowGuests: e.target.checked })}
                />
              }
            />
            <FormControlLabel
              htmlFor="guestsLimit"
              label={"Guest Limit (per RSVP)"}
              control={
                <input
                  type="number"
                  value={event?.guestsLimit === undefined ? 4 : event?.guestsLimit}
                  onChange={(e) => setEvent({ ...event, guestsLimit: parseInt(e.target.value, 10) })}
                  className="ml-2 w-[60px] border border-gray-300 rounded p-1 min-w-0"
                  min={0}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid >
      <Grid item xs={12}>
        <EventInviteButton event={event} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">RSVPs</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <PaginatedDataGrid
            collectionName="rsvps"
            defaultSortField="createdDate"
            defaultSortDirection="desc"
            columns={columns}
            service={rsvpsService}
            getRowId={(row) => row._id || row.id}
            defaultQuery={[
              {
                field: "event._id",
                operator: "eq",
                value: event._id,
              },
            ]}
            filterOptions={[
              {
                label: "Member",
                name: "member._id",
                type: "autocomplete",
                fieldName: "name",
                service: membersService,
                columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
              },
              {
                label: "Status",
                name: "status",
                type: "select",
                options: [
                  { label: "Attending", value: "attending" },
                  { label: "Not Attending", value: "not_attending" },
                  { label: "Maybe", value: "maybe" },
                ],
                columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 6 },
              },
            ]}
          />
        </Box>
      </Grid>
    </Grid >
  );
};

export default RSVPsTab;