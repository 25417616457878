import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import * as queuesService from "services/queues";

const QueueList: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/queue/${params.id}`}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Queues</Typography>
      </Grid>
      <Grid item xs={12}>
        <PaginatedDataGrid
          collectionName="queues"
          defaultSortField="createdAt"
          defaultSortDirection="desc"
          columns={columns}
          service={queuesService}
        />
      </Grid>
    </Grid>
  );
};

export default QueueList; 