import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContentType } from "./ContentTypeContext";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import contentTypesService from "services/contentTypes";
import { IContentType } from "types/contentTypes";

const ContentTypeDetails = () => {
  const { contentType, setContentType, metadata, setMetadata, saveContentType } = useContentType();
  const [newFieldLabel, setNewFieldLabel] = useState("");
  const [newFieldType, setNewFieldType] = useState("text");
  const [contentTypes, setContentTypes] = useState<IContentType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContentTypes = async () => {
      const response = await contentTypesService.fetchAll();
      setContentTypes(response.data);
    };
    fetchContentTypes();
  }, []);

  const handleSave = async () => {
    const result = await saveContentType();
    if (result) {
      navigate("/content-types");
    }
  };
  const handleAddMetadata = () => {
    if (newFieldLabel.trim() === "") return;
    const newMetadata = {
      label: newFieldLabel,
      slug: slugify(newFieldLabel, { lower: true }),
      type: newFieldType,
      ...(newFieldType === "children" && { childContentID: "" }),
    };
    setMetadata([...metadata, newMetadata]);
    setNewFieldLabel("");
    setNewFieldType("text");
  };

  const handleRemoveMetadata = (slug) => {
    setMetadata(metadata.filter((field) => field.slug !== slug));
  };

  const handleChildSelectChange = (e, fieldSlug) => {
    const selectedChildId = e.target.value;

    const updatedMetadata = metadata.map((field) => {
      if (field.slug === fieldSlug) {
        return {
          ...field,
          childContentID: selectedChildId,
        };
      }
      return field;
    });
    setMetadata(updatedMetadata);
  };

  // Render a field based on its type.
  const renderFieldByType = (field) => {
    switch (field.type) {
      case "text":
        return (
          <TextField
            label={field.label}
            fullWidth
            variant="outlined"
          />
        );
      case "textarea":
        return (
          <TextField
            label={field.label}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
          />
        );
      case "children":
        return (
          <>
            <FormControl fullWidth>
              <InputLabel id={`child-select-label-${field.slug}`}>{field.label}</InputLabel>
              <Select
                labelId={`child-select-label-${field.slug}`}
                value={field.childContentID || ""}
                placeholder={"Select a child content type"}
                label={field.label}
                onChange={(e) => handleChildSelectChange(e, field.slug)}
              >
                {contentTypes.map((ct) => (
                  <MenuItem key={ct._id} value={ct._id}>{ct.title}</MenuItem>
                ))}
                <MenuItem value="none">None</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      default:
        return (
          <TextField
            label={field.label}
            fullWidth
            variant="outlined"
          />
        );
    }
  };

  const renderFormPreview = () => {
    if (metadata.length === 0) {
      return <Typography>No fields added yet.</Typography>;
    }
    return (
      <>
        {metadata.map((field) => (
          <Box
            key={field._id}
            mb={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            {renderFieldByType(field)}
            <IconButton
              onClick={() => handleRemoveMetadata(field.slug)}
              aria-label="delete field"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Content Type Name</Typography>
        <TextField
          value={contentType?.title}
          placeholder="Content Type Name"
          onChange={(e) => setContentType({
            ...contentType,
            title: e.target.value,
          })}
          fullWidth
          margin="normal"
          sx={{ marginBottom: 2 }}
        />
        <Typography variant="h6">Add New Field</Typography>
        <TextField
          label="Field Label"
          value={newFieldLabel}
          onChange={(e) => setNewFieldLabel(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ marginBottom: 2 }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="field-type-label">Field Type</InputLabel>
          <Select
            labelId="field-type-label"
            value={newFieldType}
            label="Metadata Type"
            onChange={(e) => setNewFieldType(e.target.value)}
          >
            <MenuItem value="text">Text Input</MenuItem>
            <MenuItem value="textarea">Text Area</MenuItem>
            <MenuItem value="children">Child Content</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleAddMetadata} sx={{ marginTop: 2 }}>
          Add Field
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Preview</Typography>
        <Box component="form" noValidate autoComplete="off">
          {renderFormPreview()}
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 6 }}>
              Save
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContentTypeDetails;