import React from "react";
import { useLocation, Navigate, Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import LoadingOverlay from "components/LoadingOverlay";
import { EventGenerateProvider, useEventGenerate } from "./EventGenerateContext";
import ConfigureStep from "./ConfigureStep";
import ReviewStep from "./ReviewStep";
import FinalizeStep from "./FinalizeStep";

const EventGenerateContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error, generateEvents, saveEvents, file, prompt, details, events } = useEventGenerate();
  const currentTab = location.pathname.split('/').pop() || 'configure';

  const tabPaths = {
    configure: 'configure',
    review: 'review',
    finalize: 'finalize',
  };

  const handleNext = async () => {
    if (currentTab === 'configure') {
      const success = await generateEvents();
      if (success) {
        navigate('/events/generate/review');
      }
    } else if (currentTab === 'review') {
      navigate('/events/generate/finalize');
    } else if (currentTab === 'finalize') {
      const success = await saveEvents();
      if (success) {
        navigate('/events');
      }
    }
  };

  const isNextDisabled = () => {
    if (currentTab === 'configure') {
      return !details;
    }
    if (currentTab === 'review' || currentTab === 'finalize') {
      return events.length === 0;
    }
    return false;
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/events")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">Generate Events</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[currentTab] || 'configure'}>
              <Tab
                label="Configure"
                value="configure"
                component={Link}
                to={tabPaths.configure}
                disabled={currentTab !== 'configure' && events.length === 0}
              />
              <Tab
                label="Review"
                value="review"
                component={Link}
                to={tabPaths.review}
                disabled={currentTab === 'configure' || events.length === 0}
              />
              <Tab
                label="Finalize"
                value="finalize"
                component={Link}
                to={tabPaths.finalize}
                disabled={currentTab !== 'finalize' || events.length === 0}
              />
            </Tabs>
          </Box>

          <Box className="mb-8">
            <Routes>
              <Route path="configure" element={<ConfigureStep />} />
              <Route path="review" element={<ReviewStep />} />
              <Route path="finalize" element={<FinalizeStep />} />
              <Route path="/" element={<Navigate to="configure" replace />} />
            </Routes>
          </Box>

          <Box className="flex justify-end">
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={isNextDisabled()}
            >
              {currentTab === 'finalize' ? 'Create Events' : 'Next'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const EventGenerate: React.FC = () => {
  return (
    <EventGenerateProvider>
      <EventGenerateContent />
    </EventGenerateProvider>
  );
};

export default EventGenerate;