import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import contentTypesService from "services/contentTypes";
import contentsService from "services/contents";
import { IContent } from "types/contents";
import { useParams, useNavigate } from "react-router-dom";
import { IContentType } from "types/contentTypes";
import slugify from "slugify";

const defaultFormData: Partial<IContent> = {
  name: "",
  metadata: {},
};

const Content = () => {
  const { contentTypeId, id } = useParams();
  const navigate = useNavigate();
  const [contentType, setContentType] = useState<IContentType | null>(null);
  const [content, setContent] = useState<IContent | null>(null);
  const [formData, setFormData] = useState<Partial<IContent>>(defaultFormData);
  const [childContents, setChildContents] = useState<Record<string, any[]>>({});
  const [currentChildrenField, setCurrentChildrenField] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tempChildrenSelection, setTempChildrenSelection] = useState<string[]>([]);

  useEffect(() => {
    const fetchContent = async () => {
      if (id) {
        const contentResponse = await contentsService.fetch(id);
        setContent(contentResponse);
        setFormData(contentResponse);
        const contentTypeResponse = await contentTypesService.fetch(
          contentResponse.contentType._id
        );
        setContentType(contentTypeResponse);
      } else {
        const contentTypeResponse = await contentTypesService.fetch(contentTypeId);
        setContentType(contentTypeResponse);
      }
    };
    fetchContent();
  }, [contentTypeId, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const contentPayload = {
        contentType: contentTypeId,
        slug: slugify(formData.name, { lower: true }),
        ...formData,
      };
      if (id) {
        await contentsService.update(id, contentPayload);
      } else {
        await contentsService.create(contentPayload);
      }
      navigate(`/content-types/${contentType?._id}/content`);
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const fetchChildren = async (field: any) => {
    try {
      const response = await contentsService.fetchAll({
        query: field.childContentID !== "none" ? [
          {
            field: "contentType._id",
            value: field.childContentID,
            operator: "eq",
          },
        ] : [],
      });
      const filteredData = response.data.filter((content: IContent) => content._id !== id);
      setChildContents((prev) => ({ ...prev, [field.slug]: filteredData }));
      // setChildContents((prev) => ({ ...prev, [field.slug]: response.data }));
    } catch (error) {
      console.error("Error fetching children:", error);
    }
  };

  const openModalForChildren = (field: any) => {
    setCurrentChildrenField(field);
    setModalOpen(true);
    const currentSelection = formData.metadata && formData.metadata[field.slug];
    if (Array.isArray(currentSelection)) {
      setTempChildrenSelection(currentSelection.map((item: any) => item.childContentId));
    } else {
      setTempChildrenSelection([]);
    }
    if (!childContents[field.slug]) {
      fetchChildren(field);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentChildrenField(null);
    setTempChildrenSelection([]);
  };

  const toggleChildSelection = (childId: string) => {
    setTempChildrenSelection((prev) =>
      prev.includes(childId)
        ? prev.filter((id) => id !== childId)
        : [...prev, childId]
    );
  };

  const confirmChildrenSelection = () => {
    if (currentChildrenField) {
      setFormData((prev) => ({
        ...prev,
        metadata: {
          ...prev.metadata,
          [currentChildrenField.slug]: tempChildrenSelection.map((id) => ({ childContentId: id })),
        },
      }));
    }
    closeModal();
  };

  const renderFields = () => {
    if (!contentType || !contentType.metadata) return null;
    return contentType.metadata.map((field: any) => {
      if (field.type === "text") {
        return (
          <TextField
            key={field.slug}
            label={field.label}
            name={field.slug}
            value={(formData.metadata && formData.metadata[field.slug]) || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                metadata: {
                  ...prev.metadata,
                  [field.slug]: e.target.value,
                },
              }))
            }
            fullWidth
            margin="normal"
          />
        );
      } else if (field.type === "textarea") {
        return (
          <TextField
            key={field.slug}
            label={field.label}
            name={field.slug}
            value={(formData.metadata && formData.metadata[field.slug]) || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                metadata: {
                  ...prev.metadata,
                  [field.slug]: e.target.value,
                },
              }))
            }
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        );
      } else if (field.type === "children") {
        const selectedChildren =
          (formData.metadata && formData.metadata[field.slug]) || [];
        fetchChildren(field);
        return (
          <Box key={field.slug} sx={{ my: 2 }}>
            <Typography variant="subtitle1">{field.label}</Typography>
            {Array.isArray(selectedChildren) && selectedChildren.length > 0 && (
              <Box sx={{ mb: 1 }}>
                {selectedChildren.map((childObj: any, index: number) => {
                  const childId = childObj.childContentId;
                  const child = childContents[field.slug]?.find(
                    (c) => c._id === childId
                  );
                  return (
                    <Typography key={index} variant="body2">
                      {child ? child.name : childId}
                    </Typography>
                  );
                })}
              </Box>
            )}
            <Button variant="contained" color="primary" onClick={() => openModalForChildren(field)}>
              Add Children
            </Button>
          </Box>
        );
      }
      return null;
    });
  };

  return (
    <BasicLayout>
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => navigate(`/content-types/${contentType?._id}/content`)}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h4">
          {id
            ? `Edit Content for: ${content?.name}`
            : `Create Content for ${contentType?.title}`}
        </Typography>
      </Box>
      <Typography variant="h6">Content Name</Typography>
      <TextField
        value={formData?.name}
        placeholder="Content Name"
        onChange={(e) =>
          setFormData((prev) => ({ ...prev, name: e.target.value }))
        }
        fullWidth
        margin="normal"
      />
      <Typography variant="h6">Content Metadata</Typography>
      <form onSubmit={handleSubmit}>
        {renderFields()}
        <Button type="submit" variant="contained" color="primary">
          {id ? "Update Content" : "Save Content"}
        </Button>
      </form>

      {/* Modal for selecting children */}
      {modalOpen && currentChildrenField && (
        <Dialog open onClose={closeModal}>
          <DialogTitle>Select Children for {currentChildrenField.label}</DialogTitle>
          <DialogContent>
            <List>
              {childContents[currentChildrenField.slug] &&
                childContents[currentChildrenField.slug].map((child) => (
                  <ListItem
                    key={child._id}
                    button
                    onClick={() => toggleChildSelection(child._id)}
                  >
                    <Checkbox checked={tempChildrenSelection.includes(child._id)} />
                    <ListItemText primary={child.name} />
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button onClick={confirmChildrenSelection}>Confirm</Button>
          </DialogActions>
        </Dialog>
      )}
    </BasicLayout>
  );
};

export default Content;