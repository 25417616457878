import { Box } from "@mui/material";
import { useCommunity } from "./CommunityContext";
import { IFile } from "types/files.d";
import FileGallery from 'components/FileGallery';
import { ICommunity } from "types/communities.d";

const CommunityMedia: React.FC = () => {
  const { community, setCommunity } = useCommunity();

  const handleUpdateMedia = (files: IFile[]) => {
    const newCommunity = {
      ...community,
      files: files
    };
    if (!community.defaultImage) {
      newCommunity.defaultImage = files.find(f => f.type.startsWith('image')) || null;
    }
    if (!community.defaultVideo) {
      newCommunity.defaultVideo = files.find(f => f.type.startsWith('video')) || null;
    }
    setCommunity(newCommunity);
  };

  const handleDeleteMedia = (file: IFile) => {
    setCommunity({
      ...community,
      files: community.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setCommunity({
      ...community,
      files: community.files?.filter(f => !files.includes(f)) || []
    });
  };
  const handleSetDefaultImage = (file: IFile) => {
    setCommunity({
      ...community,
      defaultImage: file
    });
  };

  const handleSetDefaultVideo = (file: IFile) => {
    setCommunity({
      ...community,
      defaultVideo: file
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        data={community as ICommunity}
        files={community?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
        onSetDefaultImage={handleSetDefaultImage}
        onSetDefaultVideo={handleSetDefaultVideo}
      />
    </Box>
  );
};

export default CommunityMedia; 