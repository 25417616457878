import React, { useState } from 'react';
import { Box } from '@mui/material';
import FileGallery from 'components/FileGallery';
import { useNews } from './NewsContext';
import { IFile } from 'types/files.d';
import { INews } from 'types/news.d';

const MediaTab: React.FC = () => {
  const { news, setNews } = useNews();

  const handleUpdateMedia = (files: IFile[]) => {
    const newNews = {
      ...news,
      files: files
    };

    if (!news.defaultImage) {
      newNews.defaultImage = files.find(f => f.type.startsWith('image')) || null;
    }
    if (!news.defaultVideo) {
      newNews.defaultVideo = files.find(f => f.type.startsWith('video')) || null;
    }
    setNews(newNews);
  };


  const handleDeleteMedia = (file: IFile) => {
    // Assuming article.media is an array of IFile
    setNews({
      ...news,
      files: news.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setNews({
      ...news,
      files: news.files?.filter(f => !files.includes(f)) || []
    });
  };

  const handleSetDefaultImage = (file: IFile) => {
    setNews({
      ...news,
      defaultImage: file
    });
  };

  const handleSetDefaultVideo = (file: IFile) => {
    setNews({
      ...news,
      defaultVideo: file
    });
  };


  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        data={news as INews}
        files={news?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
        onSetDefaultImage={handleSetDefaultImage}
        onSetDefaultVideo={handleSetDefaultVideo}
      />
    </Box>
  );
};

export default MediaTab;
