import React, { useState } from 'react';
import eventsService from '../services/events';
import TagMultiSelect from './TagMultiSelect';
import { ITag } from '../types/tags';

interface EventInviteButtonProps {
  event: any;
}

const EventInviteButton: React.FC<EventInviteButtonProps> = ({ event }) => {
  const [open, setOpen] = useState(false);
  const [inviteType, setInviteType] = useState('all');
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [templateId, setTemplateId] = useState('event-invitation');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await eventsService.invite(event.id, {
        tagIds: inviteType === 'byTag' ? selectedTags.map((tag: ITag) => tag._id) : null,
        subject,
        message,
        templateId
      });
      setOpen(false);
      // Reset form
      setInviteType('all');
      setSelectedTags([]);
      setSubject('');
      setMessage('');
    } catch (err: any) {
      setError(err.message || 'Failed to send invitations');
    } finally {
      setLoading(false);
    }
  };

  const handleInviteTypeChange = (value: string) => {
    setInviteType(value);
    if (value !== 'byTag') {
      setSelectedTags([]); // Reset tags when switching away from byTag
    }
  };

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition duration-150 ease-in-out"
      >
        Send Invitations
      </button>

      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[1201] flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-lg">
            <form onSubmit={handleSubmit}>
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4">Send Event Invitations</h2>

                {error && (
                  <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded">
                    {error}
                  </div>
                )}

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Invitation Type
                    </label>
                    <div className="space-y-2">
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          value="all"
                          checked={inviteType === 'all'}
                          onChange={(e) => handleInviteTypeChange(e.target.value)}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <span className="text-gray-900">All Members</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          value="byTag"
                          checked={inviteType === 'byTag'}
                          onChange={(e) => handleInviteTypeChange(e.target.value)}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <span className="text-gray-900">By Tag</span>
                      </label>
                    </div>
                  </div>

                  {inviteType === 'byTag' && (
                    <div className="mt-4">
                      <TagMultiSelect
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        type={["member"]}
                      />
                    </div>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Subject
                    </label>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Message
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      rows={4}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Template
                    </label>
                    <select
                      value={templateId}
                      onChange={(e) => setTemplateId(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="event-invitation">Event Invitation</option>
                      <option value="event-announcement">Event Announcement</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  disabled={loading}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={`min-w-[100px] px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${loading ? 'opacity-75 cursor-not-allowed' : ''
                    }`}
                >
                  {loading ? (
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto" />
                  ) : (
                    'Send'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EventInviteButton; 