import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { useNewsGenerate } from "./NewsGenerateContext";
import { DateTime } from "luxon";

const NewsCard: React.FC<{ news: any }> = ({ news }) => {

  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      {/* Image Section */}
      <div className="w-full md:w-1/3 h-48 md:h-full relative">
        {news.defaultImage ? (
          <img
            src={news.defaultImage.url}
            alt={news.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <Typography className="text-gray-400">No Image</Typography>
          </div>
        )}
        <div className="absolute top-2 right-2 px-2 py-1 rounded bg-white/90 text-sm">
          {news.status}
        </div>
      </div>

      {/* Content Section */}
      <div className="flex-1 p-4">
        <h3 className="text-xl font-semibold mb-2">{news.title}</h3>

        <div className="space-y-2">
          {/* Date/Time */}
          <div className="flex items-center text-gray-600">
            <span className="material-icons-outlined text-lg mr-2">News</span>
          </div>

          {/* Tags */}
          {news.tags && news.tags.length > 0 && (
            <div className="flex flex-wrap gap-1 mt-3">
              {news.tags.map((tag: any) => (
                <span
                  key={tag.id}
                  className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                >
                  {tag.title}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FinalizeStep: React.FC = () => {
  const { news } = useNewsGenerate();

  return (
    <div className="space-y-6">
      <Typography variant="h6" className="mb-4">Final Review</Typography>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        {news.map((news) => (
          <NewsCard key={news._id} news={news} />
        ))}
      </div>
    </div>
  );
};

export default FinalizeStep; 