import React from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Grid, IconButton } from '@mui/material';
import BasicLayout from "components/Layouts/BasicLayout";
import GeneralTab from './GeneralTab';
import PhoneNumbersTab from './PhoneNumbersTab';
import SubscriptionTab from './SubscriptionTab';
import ApiKeysTab from './ApiKeysTab';
import Invoices from './Invoices';
import StripeConnectTab from './StripeConnectTab';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Organization: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.split('/').pop();
  const tabPaths = {
    general: '/organization/general',
    phones: '/organization/phones',
    subscription: '/organization/subscription',
    apiKeys: '/organization/api-keys',
    invoices: '/organization/invoices',
    "stripe-connect": '/organization/stripe-connect'
  };

  if (!tabPaths[pathName]) {
    return <Navigate to="/organization/general" replace />;
  }

  const currentTab = tabPaths[pathName] ? pathName : 'general';

  return (
    <BasicLayout>
      <Grid item xs={12} display="flex" alignItems="center">
        <IconButton onClick={() => navigate('/dashboard')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h4">Organization</Typography>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={currentTab || 'general'}>
          <Tab
            label="General"
            value="general"
            component={Link}
            to={tabPaths.general}
          />
          <Tab
            label="Phone Numbers"
            value="phones"
            component={Link}
            to={tabPaths.phones}
          />
          <Tab
            label="Subscription"
            value="subscription"
            component={Link}
            to={tabPaths.subscription}
          />
          <Tab
            label="Invoices"
            value="invoices"
            component={Link}
            to={tabPaths.invoices}
          />
          <Tab
            label="API Keys"
            value="api-keys"
            component={Link}
            to={tabPaths.apiKeys}
          />
          <Tab
            label="Stripe Connect"
            value="stripe-connect"
            component={Link}
            to={tabPaths["stripe-connect"]}
          />
        </Tabs>
      </Box>

      <Routes>
        <Route path="general" element={<GeneralTab />} />
        <Route path="phones" element={<PhoneNumbersTab />} />
        <Route path="subscription" element={<SubscriptionTab />} />
        <Route path="api-keys" element={<ApiKeysTab />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="stripe-connect" element={<StripeConnectTab />} />
        <Route path="/" element={<Navigate to="general" replace />} />
      </Routes>
    </BasicLayout>
  );
};

export default Organization;