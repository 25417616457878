import React from "react";
import { Grid } from "@mui/material";
import { useDataSource } from "../../context/DataSource";
import PaginatedDataGrid from "components/PaginatedDataGrid/PaginatedDataGrid";
import workerActivitiesService from "services/workerActivity";

const DataSourceActivity: React.FC = () => {
  const { id } = useDataSource();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaginatedDataGrid
          collectionName="workerActivities"
          service={workerActivitiesService}
          showActions={false}
          columns={[
            { field: 'type', headerName: 'Type', flex: 1 },
            { field: 'status', headerName: 'Status', flex: 1 },
            { field: 'stage', headerName: 'Stage', flex: 1 },
            { field: 'createdAt', headerName: 'Created At', flex: 1 }
          ]}
          defaultQuery={[
            {
              field: 'dataSource._id',
              operator: 'eq',
              value: id,
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DataSourceActivity; 