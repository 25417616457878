import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Alert, Typography, IconButton, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import sendgridService from '../../services/sendgrid';
import { useAuth } from "context/Auth";
import BasicLayout from 'components/Layouts/BasicLayout';
import { ChevronLeft } from '@mui/icons-material';

const EmailSettings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [domains, setDomains] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [domain, setDomain] = useState('');
  const [dialogError, setDialogError] = useState<string | null>(null);
  const [isDialogSubmitting, setIsDialogSubmitting] = useState(false);
  const [validatingDomains, setValidatingDomains] = useState<{ [key: string]: boolean }>({});
  const [deletingDomains, setDeletingDomains] = useState<{ [key: string]: boolean }>({});

  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();

  const handleCreateSubuser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    try {
      await sendgridService.createSubuser();
      await refreshUser();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to create subuser');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddDomain = async () => {
    if (!domain.trim() || !user?.currentOrganization?.sendgridConfig?.user_id) return;

    setIsDialogSubmitting(true);
    setDialogError(null);

    try {
      await sendgridService.authenticateDomain(user?.currentOrganization?.sendgridConfig?.user_id, domain);
      await refreshUser(); // Refresh organization data
      setOpenDialog(false);
      setDomain('');
    } catch (err: any) {
      setDialogError(err.response?.data?.message || 'Failed to add domain');
    } finally {
      setIsDialogSubmitting(false);
    }
  };

  const handleValidateDomain = async (domainId: string) => {
    setValidatingDomains(prev => ({ ...prev, [domainId]: true }));
    try {
      const response = await sendgridService.validateDomain(domainId);
      setDomains((prev: any) => prev.map(domain => String(domain.id) === String(domainId) ? { ...domain, valid: response.valid } : domain));
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to validate domain');
    } finally {
      setValidatingDomains(prev => ({ ...prev, [domainId]: false }));
    }
  };

  const handleDeleteDomain = async (domainId: string) => {
    setDeletingDomains(prev => ({ ...prev, [domainId]: true }));
    try {
      await sendgridService.deleteDomain(domainId);
      await refreshUser();
      await fetchDomains();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to delete domain');
    } finally {
      setDeletingDomains(prev => ({ ...prev, [domainId]: false }));
    }
  };

  const fetchDomains = async () => {
    try {
      if (!user?.currentOrganization?.sendgridConfig?.user_id) return;
      const domains = await sendgridService.getSubuserDomains(user?.currentOrganization?.sendgridConfig?.user_id);
      setDomains(domains);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to fetch domains');
    }
  };

  useEffect(() => {
    if (user?.currentOrganization?.sendgridConfig?.user_id) {
      fetchDomains();
    }
  }, [user?.currentOrganization?.sendgridConfig?.user_id]);

  console.log('domains', domains);

  if (loading || isSubmitting) return <BasicLayout><CircularProgress /></BasicLayout>;

  if (!user?.currentOrganization?.sendgridConfig?.user_id) {
    return (
      <BasicLayout>
        <Box sx={{ maxWidth: 600, p: 3 }}>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h5" sx={{ mb: 3 }}>Email Settings</Typography>
          <form onSubmit={handleCreateSubuser}>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Enable Email'}
            </Button>
          </form>
        </Box>
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Email Settings</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ maxWidth: 600 }}>
            <Typography variant="h5">Email Settings</Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>SendGrid Subuser Details</Typography>
            <Typography>User ID: {user?.currentOrganization?.sendgridConfig?.user_id}</Typography>
            <Typography>Email: {user?.currentOrganization?.sendgridConfig?.email}</Typography>
            <Typography>Username: {user?.currentOrganization?.sendgridConfig?.username}</Typography>
            {/* Add additional subuser details as needed */}
          </Box>
        </Grid>
        {domains?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Domains</Typography>
            {domains.map((domain) => (
              <Box key={domain.id} sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {domain.domain}
                  </Typography>
                  <Typography>
                    {domain.valid ? 'Validated' : 'Not Validated'}
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleValidateDomain(domain.id)}
                    disabled={validatingDomains[domain.id]}
                  >
                    {validatingDomains[domain.id] ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Validate DNS'
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDeleteDomain(domain.id)}
                    disabled={deletingDomains[domain.id]}
                  >
                    {deletingDomains[domain.id] ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Delete'
                    )}
                  </Button>
                </Box>
                {domain.dns && (
                  <Box sx={{ pl: 2 }}>
                    {Object.entries(domain.dns).map(([key, record]: [string, any]) => (
                      <Box key={key} sx={{ mb: 1 }}>
                        <Typography>
                          {key.toUpperCase()} - {record?.type?.toUpperCase()} Record:
                        </Typography>
                        <Box sx={{ pl: 2 }}>
                          <Typography>Host: {record.host}</Typography>
                          <Typography>Points to: {record.data}</Typography>
                          <Typography>Status: {record.valid ? '✓ Valid' : '⚠️ Invalid'}</Typography>
                          <Typography>Reason: {record.reason}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Add Domain
          </Button>

          <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Add Domain</DialogTitle>
            <DialogContent>
              {dialogError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {dialogError}
                </Alert>
              )}
              <TextField
                autoFocus
                margin="dense"
                label="Domain"
                fullWidth
                variant="outlined"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="example.com"
                disabled={isDialogSubmitting}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} disabled={isDialogSubmitting}>
                Cancel
              </Button>
              <Button
                onClick={handleAddDomain}
                variant="contained"
                disabled={isDialogSubmitting || !domain.trim()}
              >
                {isDialogSubmitting ? <CircularProgress size={24} /> : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default EmailSettings;
