import { ILocationData } from "types/locations.d";
import { ITagGroup } from "types/tagGroups";

export const isStringAValidDate = (date?: string | number) => {
  //verify date is a string (not a number or undefined)
  if (!date || !isNaN(Number(date))) {
    return false;
  }
  //check if string is valid date
  const dateTest: Date | string = new Date(date) || "Invalid Date";
  return dateTest !== "Invalid Date";
};

export const getLocationById = ({
  locationId,
  locations,
}: {
  locationId: string;
  locations: ILocationData[];
}) => {
  if (!locationId || !locations) return null;
  return locations.find((location) => location.id === locationId);
};

export const getTagGroupById = ({
  selectedTagGroupId,
  tagGroups,
}: {
  selectedTagGroupId: string;
  tagGroups: ITagGroup[];
}) => {
  return tagGroups.find((tagGroup) => tagGroup.id === selectedTagGroupId);
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (date: string | Date) => {
  if (!date) return "";

  if (typeof date === "string") {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
};

// Takes a 24 hour time string and formats it to 12 hour time
export const formatTime = (time: string) => {
  console.log(time);
  if (!time) return "";
  const [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
};

interface AddressComponent {
  longText: string;
  shortText: string;
  types: string[];
}

export const getAddressComponents = (addressComponents: AddressComponent[]) => {
  const findComponent = (type: string) =>
    addressComponents?.find((component) => component.types?.includes(type))?.longText || "";

  const address = {
    address1: findComponent("street_number") + " " + findComponent("route"),
    address2: findComponent("subpremise"),
    city: findComponent("locality"),
    state: findComponent("administrative_area_level_1"),
    zip: findComponent("postal_code"),
  };
  console.log(address);
  return address;
};

export const calculateStringWidth = (str: string) => str.length * 10 + 80;
