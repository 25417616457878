import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import templatesService from "services/templates";
import { ITemplate } from "types/templates";

interface ITemplateContextProps {
  template: ITemplate | null;
  setTemplate: React.Dispatch<React.SetStateAction<ITemplate | null>>;
  loading: boolean;
  error: string | null;
  saveTemplate: (data?: Record<string, any>) => Promise<void>;
}

const TemplateContext = createContext<ITemplateContextProps>({
  template: null,
  setTemplate: () => { },
  loading: false,
  error: null,
  saveTemplate: async () => { },
});

export const useTemplateContext = () => useContext(TemplateContext);

export const TemplateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [template, setTemplate] = useState<ITemplate | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const isNew = !id || id === 'new';

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!id || id === 'new') {
        setTemplate({
          name: '',
          type: 'email',
          description: '',
          html: '',
          text: '',
        } as ITemplate);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const data = await templatesService.fetch(id);
        setTemplate(data);
      } catch (error) {
        console.error("Error fetching template:", error);
        setError("Failed to load template. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [id]);

  const saveTemplate = async (additionalData?: Record<string, any>) => {
    try {
      setLoading(true);
      setError(null);

      const dataToSave = {
        ...template,
        ...additionalData,
      };

      if (isNew) {
        const created = await templatesService.create(dataToSave);
        setTemplate(created);
        navigate(`/template/${created.id}/details`);
      } else if (id) {
        const updated = await templatesService.update(id, dataToSave);
        setTemplate(updated);
      }
    } catch (error) {
      console.error("Error saving template:", error);
      setError("Failed to save template. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <TemplateContext.Provider value={{ template, setTemplate, loading, error, saveTemplate }}>
      {children}
    </TemplateContext.Provider>
  );
};

export default TemplateProvider; 