import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IService, IPaginationModal } from "types/App";
import { IRSVP } from "types/rsvp.d";

interface IRegenerateTickets extends IService {
  regenerateTickets: (id: string) => Promise<any>;
  regenerateTicketsPDF: (id: string) => Promise<any>;
  downloadTicketsPDF: (id: string) => Promise<any>;
  resendConfirmation: (id: string) => Promise<any>;
}

const rsvpsService: IRegenerateTickets = {
  fetchAll: async (paginationModel?: IPaginationModal) => {
    const filteredPagination = { ...paginationModel };
    delete filteredPagination.query;
    delete filteredPagination.sort;

    const url = new URL(`${API_HOST}/rsvp`);
    url.search = new URLSearchParams(filteredPagination).toString();

    const params = {
      ...(paginationModel?.query && { query: paginationModel.query }),
      ...(paginationModel?.sort && { sort: paginationModel.sort }),
    };
    const response = await axios.get(url.toString(), {
      params,
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  fetch: async (id: string) => {
    const response = await axios.get(`${API_HOST}/rsvp/${id}`, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  create: async (data: IRSVP) => {
    const response = await axios.post(`${API_HOST}/rsvp`, data, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  update: async (id: string, data: Partial<IRSVP>) => {
    const response = await axios.put(`${API_HOST}/rsvp/${id}`, data, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  remove: async (id: string) => {
    const response = await axios.delete(`${API_HOST}/rsvp/${id}`, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },
  export: async (paginationModel?: IPaginationModal) => {
    const filteredPagination = { ...paginationModel };
    delete filteredPagination.query;
    delete filteredPagination.sort;

    const url = new URL(`${API_HOST}/rsvp/export`);
    url.search = new URLSearchParams(filteredPagination).toString();

    const params = {
      ...(paginationModel?.query && { query: paginationModel.query }),
      ...(paginationModel?.sort && { sort: paginationModel.sort }),
    };

    const response = await axios.get(url.toString(), {
      params,
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  regenerateTickets: async (id: string) => {
    const response = await axios.post(`${API_HOST}/rsvp/${id}/regenerate-tickets`, {}, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  regenerateTicketsPDF: async (id: string) => {
    const response = await axios.post(`${API_HOST}/rsvp/${id}/regenerate-pdf`, {}, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },

  downloadTicketsPDF: async (id: string) => {
    const response = await axios.get(`${API_HOST}/rsvp/${id}/download-pdf`, {
      headers: TokenHandler.getHeaders(),
      responseType: 'blob', // Important for handling PDF downloads
    });
    return response.data;
  },

  resendConfirmation: async (id: string) => {
    const response = await axios.post(`${API_HOST}/rsvp/${id}/resend-confirmation`, {}, {
      headers: TokenHandler.getHeaders(),
    });
    return response.data;
  },
};

export default rsvpsService;