import React from "react";
import { useNavigate, useLocation, Link, Outlet } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { useDataSource } from "../../context/DataSource";

const DataSource: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error, deleteDataSource, dataSource } = useDataSource();

  const currentTab = location.pathname.split('/').pop() || 'details';

  const tabPaths = {
    details: 'details',
    activity: 'activity',
    run: 'run',
  };

  const handleDelete = async () => {
    const result = await deleteDataSource();
    if (result) {
      navigate("/data-sources");
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => navigate("/data-sources")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">
              {dataSource?.name ? "Data Source: " + dataSource.name : "Data Source"}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              disabled={loading}
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={loading}
            >
              Delete
            </Button>
          </Box>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[currentTab] || 'details'}>
              <Tab label="Details" value="details" component={Link} to="details" />
              <Tab label="Activity" value="activity" component={Link} to="activity" />
              <Tab label="Run" value="run" component={Link} to="run" />
            </Tabs>
          </Box>
          <Outlet />
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

export default DataSource;
