import React, { useMemo } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Grid,
  Paper,
  Button,
} from '@mui/material';
import AITextField from 'components/AITextField';
import { Controller } from 'react-hook-form';
import LocationSelect from 'components/LocationSelect';
import ArtistSelect from 'components/ArtistSelect';
import CommunitySelect from 'components/CommunitySelect';
import AuthorSelect from 'components/AuthorSelect';
import { ILocationData } from 'types/locations.d';
import { IAuthor } from 'types/authors';
import { useDataSource } from 'context/DataSource';

interface DataSourceFormProps {
  dataSourceId?: string;
  parentId?: string;
  parentType?: 'location' | 'community' | 'artist' | 'author' | 'none' | 'group' | undefined | null;
  onComplete?: () => void;
  onCancel?: () => void;
  showSaveButton?: boolean;
  useContext?: boolean;
}

export interface IDataSourceResults {
  success: boolean;
  itemsFound: number;
  formattedItems: any[];
  totalFormattedItems: number;
  savedItems: any[];
  totalSavedItems: number;
  duplicateItems: number;
  message: string;
}

const DataSourceForm: React.FC<DataSourceFormProps> = (props) => {
  const {
    loading,
    error,
    handleSubmit: handleSubmitContext,
    formMethods: {
      control,
      register,
      handleSubmit,
      formState: {
        errors
      },
      watch
    },
  } = useDataSource();
  const { showSaveButton = false, onComplete } = props;

  const watchedType = watch('type');
  const watchedParentType = watch('parentType');
  const urlLabel = useMemo(() => {
    switch (watchedType) {
      case 'website': return 'URL';
      case 'instagram': return 'Instagram Handle';
      case 'facebook': return 'Facebook Handle';
      case 'twitter': return 'Twitter Username';
      case 'rss': return 'RSS URL';
      case 'json': return 'JSON URL';
    }
  }, [watchedType]);

  return (
    <Grid container spacing={3}>
      {loading && (
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Typography>Loading...</Typography>
          </Paper>
        </Grid>
      )}

      {error && (
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2, bgcolor: 'error.light' }}>
            <Typography color="error">Error: {error}</Typography>
          </Paper>
        </Grid>
      )}

      {/* Left Column - Form Fields */}
      {!loading && (
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <FormControl fullWidth margin="dense" error={!!errors.type}>
              <FormLabel>Type</FormLabel>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Type is required' }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="website">Website</MenuItem>
                    <MenuItem value="instagram">Instagram</MenuItem>
                    <MenuItem value="facebook">Facebook</MenuItem>
                    <MenuItem value="twitter">Twitter</MenuItem>
                    <MenuItem value="rss">RSS</MenuItem>
                    <MenuItem value="json">JSON</MenuItem>
                  </Select>
                )}
              />
              {errors.type?.message && (
                <FormHelperText>{errors.type.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.dataType}>
              <FormLabel>Data Type</FormLabel>
              <Controller
                name="dataType"
                control={control}
                rules={{ required: 'Data type is required' }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="events">Events</MenuItem>
                    <MenuItem value="news">News</MenuItem>
                  </Select>
                )}
              />
              {errors.dataType?.message && (
                <FormHelperText>{errors.dataType.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.url}>
              <FormLabel>{urlLabel}</FormLabel>
              <TextField
                {...register('url', {
                  required: 'URL is required',
                })}
                margin="dense"
                type="url"
                fullWidth
              />
              {errors.url?.message && (
                <FormHelperText>{errors.url.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.prompt}>
              <Controller
                name="prompt"
                control={control}
                render={({ field }) => (
                  <AITextField
                    {...field}
                    label="Prompt"
                    prompt="Generate a prompt for extracting content from this source"
                    promptMode="modal"
                    multiline
                    rows={4}
                  />
                )}
              />
              {errors.prompt?.message && (
                <FormHelperText>{errors.prompt.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.scrapingInterval}>
              <FormLabel>Scraping Interval</FormLabel>
              <Controller
                name="scrapingInterval"
                control={control}
                rules={{ required: 'Scraping interval is required' }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="hourly">Hourly</MenuItem>
                    <MenuItem value="every-240-minutes">Every 4 Hours</MenuItem>
                    <MenuItem value="twice-daily">Twice Daily</MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </Select>
                )}
              />
              {errors.scrapingInterval?.message && (
                <FormHelperText>{errors.scrapingInterval.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.author}>
              <FormLabel>Author</FormLabel>
              <Controller
                name="author"
                control={control}
                render={({ field }) => (
                  <AuthorSelect
                    value={field.value}
                    onChange={(author: IAuthor) => {
                      field.onChange(author._id);
                    }}
                  />
                )}
              />
              {errors.author?.message && (
                <FormHelperText>{errors.author.message as string}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth margin="dense" error={!!errors.parentType}>
              <FormLabel>Parent Type</FormLabel>
              <Controller
                name="parentType"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="location">Location</MenuItem>
                    <MenuItem value="community">Community</MenuItem>
                    <MenuItem value="artist">Artist</MenuItem>
                    <MenuItem value="author">Author</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            {watchedParentType && watchedParentType !== 'none' && (
              <FormControl fullWidth margin="dense" error={!!errors.parentId}>
                <FormLabel>Parent</FormLabel>
                <Controller
                  name="parentId"
                  control={control}
                  render={({ field }) => {
                    switch (watchedParentType) {
                      case 'location':
                        return <LocationSelect
                          value={field.value}
                          onChange={(location: ILocationData) => {
                            return field.onChange(location._id)
                          }}
                        />;
                      case 'artist':
                        return <ArtistSelect {...field} />;
                      case 'community':
                        return <CommunitySelect {...field} />;
                      case 'author':
                        return <AuthorSelect
                          value={field.value}
                          onChange={(author: IAuthor) => field.onChange(author._id)}
                        />;
                      default:
                        return null;
                    }
                  }}
                />
              </FormControl>
            )}
            {showSaveButton && (
              <Button variant="contained" color="primary" onClick={() => {
                const response = handleSubmit(handleSubmitContext)();
                if (response) {
                  onComplete?.();
                }
              }}>
                Save
              </Button>
            )}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default DataSourceForm;
