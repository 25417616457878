import React from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import * as workerActivityService from "services/workerActivity";

interface WorkerActivityListProps {
  defaultQuery?: Array<{
    field: string;
    operator: string;
    value: any;
  }>;
}

const WorkerActivityList: React.FC<WorkerActivityListProps> = ({ defaultQuery = [] }) => {
  const columns: GridColDef[] = [
    { field: "type", headerName: "Type", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "startedAt", headerName: "Started At", flex: 1, renderCell: (params: GridValueGetterParams) => (
        <p className="text-sm">{params.row.startedAt ? new Date(params.row.startedAt).toLocaleString() : ''}</p>
      )
    },
    {
      field: "completedAt", headerName: "Completed At", flex: 1, renderCell: (params: GridValueGetterParams) => (
        <p className="text-sm">{params.row.completedAt ? new Date(params.row.completedAt).toLocaleString() : ''}</p>
      )
    },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PaginatedDataGrid
          collectionName="workerActivities"
          defaultSortField="startedAt"
          defaultSortDirection="desc"
          columns={columns}
          service={workerActivityService}
          defaultQuery={defaultQuery}
        />
      </Grid>
    </Grid>
  );
};

export default WorkerActivityList; 