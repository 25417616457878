import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { IMember } from '../../types/members';
import membersService from 'services/members';

interface MemberSelectProps {
  selectedMembers?: IMember[];
  setSelectedMembers?: (members: IMember[]) => void;
}

const MemberMultiSelect: React.FC<MemberSelectProps> = (props) => {
  const { selectedMembers, setSelectedMembers } = props;
  const [members, setMembers] = useState<IMember[]>([]);


  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const _members = await membersService.fetchAll({ page: 0, pageSize: 2000 }); // TODO: I'm not sure if this is the correct way to fetch members, since there could be a lot of them
        setMembers(
          _members.data.map((member: any) => {
            return {
              ...member,
            };
          })
        );
      } catch (e) {
        console.error("Error fetching members:", e);
      }
    };

    fetchMembers();
  }, []);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderMemberOptionLabel = (member: IMember) => {
    if (member) {
      return `${member.name}`;
    }
    return "";
  };

  return (
    <Autocomplete
      multiple
      value={selectedMembers || []}
      onChange={(_e, value: IMember[]) => {
        setSelectedMembers?.(value);
      }}
      filterOptions={filterOptions}
      options={members}
      getOptionLabel={renderMemberOptionLabel}
      isOptionEqualToValue={(option: IMember, value: IMember) => option.id === value.id}
      noOptionsText="No members found"
      renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
    />
  );
};

export default MemberMultiSelect;
