import { FormControl, Select, MenuItem, Chip, Box } from '@mui/material';
import { FilterComponentProps } from './types';

export const MultiSelectFilter = ({ filterOption, value, onChange }: FilterComponentProps) => {
  return (
    <FormControl fullWidth>
      <Select
        multiple
        value={value || []}
        onChange={(e) => {
          const selectedValues = e.target.value;
          onChange(selectedValues.length > 0 ? {
            field: filterOption.name,
            value: selectedValues,
            operator: filterOption.operator || 'in'
          } : null);
        }}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip
                key={value}
                label={filterOption.options?.find(opt => opt.value === value)?.label || value}
              />
            ))}
          </Box>
        )}
        style={{ minHeight: "53px" }}
      >
        {filterOption.options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}; 