import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Tabs, Tab, Icon } from '@mui/material';
import { IDataSourceResults } from '../DataSourceForm/DataSourceForm';
import eventsService from 'services/events';
import newsService from 'services/news';
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { capitalizeFirstLetter, formatTime } from 'utils/commonFunctions';

const format = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
}

export interface DataSourceResultsProps {
  results: IDataSourceResults;
  dataType: 'events' | 'news' | 'both';
}

interface SavedItem {
  _id: string;
  title: string;
  status: string;
  startDate?: string;
  date?: string;
  description: string;
}

const DataSourceResults: React.FC<DataSourceResultsProps> = ({ results, dataType }) => {
  const [items, setItems] = useState<SavedItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'events' | 'news'>(dataType === 'both' ? 'events' : dataType);

  const eventsColumns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value?.url ? (
          <img src={params?.value?.url} alt="Event" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 2 },
    {
      field: "startDate",
      headerName: "Start",
      flex: 1,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        const localDate = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
        return <span>{localDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>;
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      renderCell: (params: any) => <>{formatTime(params?.value)}</>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
  ];

  const newsColumns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="News URL" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => {
        return <>{params?.value?.name}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
  ];

  if (!Object.keys(results).length) return null;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Run Results
      </Typography>
      <Box mb={2}>
        <Typography>Items Found: {results?.itemsFound || 0}</Typography>
        <Typography>Formatted Items: {results.totalFormattedItems || 0}</Typography>
        <Typography>Saved Items: {results?.savedItems?.length || 0}</Typography>
        <Typography>Duplicate Items: {results?.duplicateItems || 0}</Typography>
      </Box>

      {dataType === 'both' && (
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{ mb: 2 }}
        >
          <Tab label="Events" value="events" />
          <Tab label="News" value="news" />
        </Tabs>
      )}

      <DataGrid
        rows={results?.savedItems?.length ? results?.savedItems : []}
        columns={dataType === 'events' ? eventsColumns : newsColumns}
        getRowId={(row) => row._id}
      />
    </Box>
  );
};

export default DataSourceResults; 