import { Grid, Box, IconButton, Typography, Button, Alert, Tabs, Tab } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useLocation, Navigate, Routes, Route, Link, useNavigate } from "react-router-dom";
import BasicLayout from "components/Layouts/BasicLayout";
import LoadingOverlay from "components/LoadingOverlay";
import { useContentType, ContentTypeProvider } from "./ContentTypeContext";
import ContentTypeDetails from "./ContentTypeDetails";
import ContentTypeList from "./ContentTypeList";
import ContentTypeAdmin from "./ContentTypeAdmin";

const ContentTypeContent: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { id, loading, error, saveContentType, contentType } = useContentType();
  const currentTab = location.pathname.split('/').pop() || 'contentType';

  const tabPaths = {
    contentType: 'contentType',
    content: 'content',
    admin: 'admin',
  };

  const validCurrentTab = tabPaths[currentTab] ? currentTab : 'contentType';

  const handleSave = async () => {
    const result = await saveContentType();
    if (result) {
      navigate("/content-types");
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/content-types")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">{contentType?.title ? "Content Type: " + contentType.title : "Content Type"}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
                Save
              </Button>
            </Box>
          </Box>
        </Grid>

        {error && <Alert severity="error">{error}</Alert>}

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[validCurrentTab]}>
              <Tab label="Content Type" value="contentType" component={Link} to={tabPaths.contentType} />
              <Tab label="Content" value="content" component={Link} to={tabPaths.content} />
              <Tab label="Admin" value="admin" component={Link} to={tabPaths.admin} />
            </Tabs>
          </Box>
          <Routes>
            <Route path="contentType" element={<ContentTypeDetails />} />
            <Route path="content" element={<ContentTypeList />} />
            <Route path="admin" element={<ContentTypeAdmin />} />
            <Route path="/" element={<Navigate to="contentType" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const ContentType: React.FC = () => {
  return (
    <ContentTypeProvider>
      <ContentTypeContent />
    </ContentTypeProvider>
  );
};

export default ContentType;
