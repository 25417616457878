import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { IService, IPaginationModal } from "types/App";
import LoadingOverlay from "components/LoadingOverlay";
import PaginationFilter, { FilterOption } from "./PaginationFilter";
import { useGlobalFilterContext } from "./GlobalFilterContext";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import theme from "assets/theme";
import { calculateStringWidth } from "utils/commonFunctions";

interface IPaginatedDataGridProps {
  collectionName: string;
  columns?: GridColDef[];
  defaultSortField?: string;
  defaultSortDirection?: "asc" | "desc";
  service?: IService;
  filterOptions?: FilterOption[];
  allowReSorting?: boolean;
  getRowId?: (row: any) => string | number;
  defaultQuery?: {
    field: string;
    operator: string;
    value: string | string[];
  }[];
  showActions?: boolean;
}

export interface IDataGridPageInfo {
  page: number;
  pageSize: number;
}

const PaginatedDataGrid = (props: IPaginatedDataGridProps) => {
  const {
    collectionName,
    columns = [],
    defaultSortField = "title",
    defaultSortDirection = "asc",
    service,
    filterOptions = [],
    allowReSorting = false,
    defaultQuery = [],
    showActions = true,
  } = props;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [originalGridData, setOriginalGridData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [reSorted, setReSorted] = useState(false);
  const [copiedData, setCopiedData] = useState(null);
  const [copyDialog, setCopyDialog] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState<
    IPaginationModal & IDataGridPageInfo
  >({
    pageSize: 10,
    page: 0,
  });
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(defaultSortDirection);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });

  const [deleteItemsArray, setDeleteItemsArray] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [updatedIds, setUpdatedIds] = useState<string[]>([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { filterValues: contextFilterValues } = useGlobalFilterContext();

  const filterValues = useMemo(() => {
    return contextFilterValues[collectionName as keyof typeof contextFilterValues] || [];
  }, [collectionName, contextFilterValues]);

  const location = useLocation();

  //TODO investigate missing dependency array for useCallback
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await service.fetchAll({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort: {
          [sortField]: sortDirection,
        },
        query: [
          ...(Array.isArray(defaultQuery) ? defaultQuery : []),
          ...(Array.isArray(filterValues) ? filterValues : []),
        ],
      });
      setGridData(response.data);
      setOriginalGridData(response.data);
      setRowCount(response.total);
      console.log(response.data);
    } catch (error) {
      console.error(`Error fetching ${collectionName}`, error);
    } finally {
      setLoading(false);
    }
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    sortField,
    sortDirection,
    service,
    collectionName,
    filterValues,
  ]);

  useEffect(() => {
    fetchData();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    sortField,
    sortDirection,
    service,
    collectionName,
    filterValues,
  ]);

  const handleSelectIdToDelete = useCallback((idArray: string[]) => {
    if (!idArray || !idArray?.length) return;
    setDeleteItemsArray(idArray);
    setShowDeleteDialog(true);
  }, []);

  // Function to delete an event or array of events
  const handleDelete = useCallback(
    ({ idArray, onEachSuccess }: { idArray: string[]; onEachSuccess?: (id: string) => void }) => {
      setLoading(true);
      const deleteActions = idArray.map(async (id) => {
        try {
          await service.remove(id);
          onEachSuccess && onEachSuccess(id);
        } catch (error: any) {
          if (error.response?.data) {
            setSnackbar({ open: true, message: error.response.data, severity: "error" });
          }
          console.error("Error deleting:", error);
        }
      });
      Promise.allSettled(deleteActions).then(() => {
        setLoading(false);
        setShowDeleteDialog(false);
        fetchData();
      });
    },
    [service, fetchData]
  );

  // Function to bulk update of items
  const handleBulkUpdate = useCallback(
    ({ idArray, onEachSuccess }: { idArray: string[]; onEachSuccess?: (id: string) => void }) => {
      setLoading(true);
      const editActions = idArray.map(async (id) => {
        try {
          const updatedRow = gridData.find((row) => row.id === id);
          updatedRow && (await service.update(id, updatedRow));
          setGridData((prev) =>
            prev.map((d: any) => {
              if (d.id !== updatedRow.id) return d;
              return updatedRow;
            })
          );
          onEachSuccess && onEachSuccess(id);
        } catch (error: any) {
          if (error.response?.data) {
            setSnackbar({ open: true, message: error.response.data, severity: "error" });
          }
          console.error("Error updating:", error);
        }
      });
      Promise.allSettled(editActions).then(() => {
        setLoading(false);
        setUpdatedIds([]);
        setReSorted(false);
        setOriginalGridData(gridData);
      });
    },
    [gridData, service]
  );

  const handleSortOrderUp = useCallback(
    (rowId?: string) => {
      if (!rowId) return;
      const selectedRow = gridData.find((item: any) => item.id === rowId);
      const sortOrder = Number(selectedRow?.sortOrder);

      if (!selectedRow || isNaN(sortOrder)) return;

      let tempUpdatedIds = [...updatedIds];
      const currentRowIndex = gridData.findIndex((item: any) => item.id === rowId);
      let newSortOrder = sortOrder - 1;
      const tempGridData = gridData.reduce((acc: any, item, index) => {
        //first row can't be indexed up
        if (currentRowIndex === 0) return [...acc, item];

        //update current row's sort order value
        if (index === currentRowIndex) {
          if (!updatedIds.some((itemId) => item.id === itemId)) {
            tempUpdatedIds.push(item.id);
          }
          return [...acc, { ...item, sortOrder: sortOrder - 1 }];
        }

        //if row has duplicate sort order value, increment it up
        if (item.sortOrder === newSortOrder) {
          if (!updatedIds.some((itemId) => item.id === itemId)) {
            tempUpdatedIds.push(item.id);
          }
          newSortOrder++;
          return [...acc, { ...item, sortOrder: newSortOrder }];
        }

        //default
        return [...acc, item];
      }, []);
      tempGridData.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
      setUpdatedIds(tempUpdatedIds);
      setGridData(tempGridData);
      setReSorted(true);
    },
    [gridData, updatedIds]
  );

  const handleSortOrderDown = useCallback(
    (rowId?: string) => {
      if (!rowId) return;
      const selectedRow = gridData.find((item: any) => item.id === rowId);
      const sortOrder = Number(selectedRow?.sortOrder);

      if (!selectedRow || isNaN(sortOrder)) return;

      let tempUpdatedIds = [...updatedIds];
      const currentRowIndex = gridData.findIndex((item: any) => item.id === rowId);
      let newSortOrder = sortOrder + 1;
      const tempGridData = gridData.reduce((acc: any, item, index) => {
        //last row can't be indexed down
        if (currentRowIndex === gridData.length - 1) return [...acc, item];

        //update current row's sort order value
        if (index === currentRowIndex) {
          if (!updatedIds.some((itemId) => item.id === itemId)) {
            tempUpdatedIds.push(item.id);
          }
          return [...acc, { ...item, sortOrder: sortOrder + 1 }];
        }

        //if row has duplicate sort order value, increment it down
        if (item.sortOrder === newSortOrder) {
          if (!updatedIds.some((itemId) => item.id === itemId)) {
            tempUpdatedIds.push(item.id);
          }
          newSortOrder--;
          return [...acc, { ...item, sortOrder: newSortOrder }];
        }

        //default
        return [...acc, item];
      }, []);
      tempGridData.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
      setUpdatedIds(tempUpdatedIds);
      setGridData(tempGridData);
      setReSorted(true);
    },
    [gridData, updatedIds]
  );

  const handleCopyData = useCallback(async (id: string) => {
    try {
      const response = await service.fetch(id);
      const { _id, id: responseId, ...dataToCopy } = response;

      if (dataToCopy.status === "published") {
        dataToCopy.status = "pending";
      }

      if (dataToCopy.name) {
        dataToCopy.name = `(Copy of) ${dataToCopy.name}`;
      } else if (dataToCopy.title) {
        dataToCopy.title = `(Copy of) ${dataToCopy.title}`;
      }

      setCopiedData(dataToCopy);
      setCopyDialog(true);
    } catch (error) {
      console.error("Error copying data:", error);
    }
  }, []);

  const createCopyData = useCallback(async () => {
    try {
      const response = await service.create(copiedData);
      setCopyDialog(false);
      setCopiedData(null);
      fetchData();
      navigate(`${location.pathname}/${response.id}`);
    } catch (error) {
      console.error("Error creating a copy of the data:", error);
    }
  }, [copiedData, service]);

  const _columns = useMemo(() => {
    let tempColumns = [...columns];
    tempColumns = tempColumns.map((col) => ({
      ...col,
      minWidth: col.minWidth || calculateStringWidth(col.headerName || ""),
    }));
    if (showActions) {
      tempColumns.push({
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params: any) => {
          return (
            <>
              <PencilIcon
                className="w-6 h-6 mr-3 text-blue-600 cursor-pointer opacity-90 hover:opacity-100 hover:scale-110 transition-all"
                onClick={() => {
                  navigate(`${location.pathname}/${params?.id}`);
                }}
              />
              <DocumentDuplicateIcon
                className="w-6 h-6 mr-3 text-gray-500 cursor-pointer opacity-90 hover:opacity-100 hover:scale-110 transition-all"
                onClick={() => handleCopyData(params.id)}
              />
              <TrashIcon
                className="w-6 h-6 mr-3 text-red-500 cursor-pointer opacity-90 hover:opacity-100 hover:scale-110 transition-all"
                onClick={() => handleSelectIdToDelete([params?.id])}
              />
            </>
          );
        },
      });
    }
    allowReSorting &&
      tempColumns.unshift({
        field: "dragAndDrop",
        headerName: "",
        width: 100,
        cellClassName: () => "no-border",
        renderCell: (params: any) => {
          const itemId = params?.row?.id;
          const rowIndex = gridData.findIndex((item: any) => item.id === itemId);
          return (
            <Grid container display={"flex"} justifyContent={"space-between"}>
              {rowIndex !== 0 ? (
                <IconButton onClick={() => handleSortOrderUp(itemId)}>
                  <ArrowDropUp />
                </IconButton>
              ) : (
                <div />
              )}
              {rowIndex !== gridData.length - 1 ? (
                <IconButton onClick={() => handleSortOrderDown(itemId)}>
                  <ArrowDropDown />
                </IconButton>
              ) : (
                <div />
              )}
            </Grid>
          );
        },
      });
    return tempColumns;
  }, [
    allowReSorting,
    columns,
    gridData,
    handleSelectIdToDelete,
    handleSortOrderDown,
    handleSortOrderUp,
    location.pathname,
    navigate,
  ]);

  const handlePageIndexing = (data: IDataGridPageInfo) => {
    setPaginationModel({ ...paginationModel, page: data.page, pageSize: data.pageSize });
  };

  const handleDiscardChanges = () => {
    setGridData(originalGridData);
    setUpdatedIds([]);
    setReSorted(false);
  };

  const handleExport = async () => {
    try {
      const { url } = await service.export({
        page: 0,
        pageSize: 9999,
        sort: {
          [sortField]: sortDirection,
        },
        query: filterValues,
      });
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error exporting:", error);
      setSnackbar({ open: true, message: "Error exporting data", severity: "error" });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size={isSmallScreen ? "small" : "medium"}
              color="error"
              disabled={selectedIds.length === 0}
              onClick={() => {
                handleSelectIdToDelete(selectedIds);
              }}
            >
              Delete {selectedIds.length} Selected
            </Button>
            {allowReSorting && (
              <>
                <Button
                  variant="contained"
                  color="warning"
                  disabled={!updatedIds.length}
                  onClick={handleDiscardChanges}
                >
                  Discard Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!reSorted}
                  onClick={() => handleBulkUpdate({ idArray: updatedIds })}
                >
                  Save Changes
                </Button>
              </>
            )}
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            {typeof service?.export === "function" && (
              <Button variant="contained" color="secondary" onClick={handleExport} size="small">
                Export
              </Button>
            )}
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <Grid container spacing={2}>
      {filterOptions && filterOptions.length > 0 && (
        <Grid item xs={12}>
          <PaginationFilter filterOptions={filterOptions} collectionName={collectionName} />
        </Grid>
      )}
      <Grid item xs={12} display={"flex"} flexDirection={"column"}>
        <DataGrid
          loading={loading}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            minWidth: isSmallScreen ? "0" : "400px",
            ".no-border": {
              outline: "none !important",
            },
          }}
          autoHeight
          rows={Array.isArray(gridData) ? gridData : []}
          rowCount={rowCount}
          getRowId={(row) => row.id || row._id}
          columns={_columns}
          pageSizeOptions={[2, 10, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          paginationModel={paginationModel}
          onRowSelectionModelChange={(data: any) => setSelectedIds(data)}
          rowSelectionModel={selectedIds}
          onPaginationModelChange={(data: IDataGridPageInfo) => {
            handlePageIndexing(data);
          }}
          onSortModelChange={(data: any) => {
            setSortField(data[0]?.field);
            setSortDirection(data[0]?.sort);
          }}
          paginationMode="server"
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Grid>

      <Dialog open={copyDialog} onClose={() => setCopyDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Copy Data
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setCopyDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} direction={"row"} width={"100%"}>
            <Grid
              item
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              display={"flex"}
              height={"100%"}
              color={"000"}
            >
              <strong>
                This action will create a copy of "
                {(copiedData?.name || copiedData?.title)?.replace("(Copy of) ", "")}" with the same
                data.
              </strong>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent={"flex-end"} spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: "#7b809a" }}
                onClick={() => {
                  setCopyDialog(false);
                  setCopiedData(null);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={createCopyData}>
                Copy and Create
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={loading} />
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Warning!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowDeleteDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} direction={"row"} width={"100%"}>
            <Grid
              item
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              display={"flex"}
              height={"100%"}
              color={"000"}
            >
              <strong>
                Are you sure you want to delete
                {deleteItemsArray?.length > 1
                  ? ` these ${deleteItemsArray.length} items? `
                  : ` this item? `}
                This action cannot be undone.
              </strong>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent={"flex-end"} spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: "#7b809a" }}
                onClick={() => {
                  setShowDeleteDialog(false);
                  setDeleteItemsArray([]);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: "#F45D49" }}
                onClick={() =>
                  handleDelete({
                    idArray: deleteItemsArray,
                    onEachSuccess: (id: string) => {
                      setDeleteItemsArray((prev) => prev.filter((item) => item !== id));
                      setSelectedIds((prev) => prev.filter((item) => item !== id));
                    },
                  })
                }
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <LoadingOverlay loading={loading} />
      </Dialog>
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false, message: "" })}
      >
        <Alert
          severity={snackbar.severity}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setSnackbar({ ...snackbar, open: false, message: "" })}
            >
              Dismiss
            </Button>
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default PaginatedDataGrid;
