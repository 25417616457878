import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DataSource from './DataSource';
import DataSourceDetails from './DataSourceDetails';
import DataSourceActivity from './DataSourceActivity';
import DataSourceRun from './DataSourceRun';
import { DataSourceProvider } from '../../context/DataSource';

const DataSourceRoutes = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return (
    <DataSourceProvider id={id} onComplete={() => {
      navigate('/data-sources');
    }}>
      <Routes>
        <Route element={<DataSource />}>
          <Route path="details" element={<DataSourceDetails />} />
          <Route path="activity" element={<DataSourceActivity />} />
          <Route path="run" element={<DataSourceRun />} />
          <Route path="/" element={<Navigate to="details" replace />} />
        </Route>
      </Routes>
    </DataSourceProvider>
  );
};

export default DataSourceRoutes;