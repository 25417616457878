import { Types } from 'mongoose';
import { IUser } from './users';
import { IOrganization } from './organizations';
import { IDataSource } from './dataSources';
import { IFile } from './files';

export enum StatusEnum {
  Published = "published",
  Pending = "pending",
}

export type StatusTypes = StatusEnum.Published | StatusEnum.Pending;

export interface ICommunity {
  _id?: string;
  id?: string;
  name?: string;
  description?: string;
  website?: string;
  instagram?: string;
  twitter?: string;
  facebook?: string;
  createdBy?: Types.ObjectId | IUser;
  createdDate?: string;
  updatedBy?: Types.ObjectId | IUser;
  updatedDate?: string;
  organization?: Types.ObjectId | IOrganization;
  dataSources?: (Types.ObjectId | IDataSource)[];
  defaultImage?: IFile;
  defaultVideo?: IFile;
  files?: IFile[];
  events?: IEvent[];
} 