import React from "react";
import { Grid } from "@mui/material";
import { useDataSource } from "../../context/DataSource";
import DataSourceForm from "components/DataSourceForm/DataSourceForm";

const DataSourceDetails: React.FC = () => {
  const { id } = useDataSource();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DataSourceForm
          dataSourceId={id}
          useContext={true}
          onComplete={() => { }}
          onCancel={() => { }}
        />
      </Grid>
    </Grid>
  );
};

export default DataSourceDetails; 