import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormControl, FormLabel, Grid, TextField, Typography, Select, MenuItem } from "@mui/material";
import * as queuesService from "services/queues";

const QueueForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [queue, setQueue] = useState<any>({
    type: '',
    status: 'pending',
    dataSourceId: '',
    organization: '',
    results: '',
    error: '',
    createdAt: '',
    startedAt: '',
    completedAt: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchQueue = async () => {
      try {
        setLoading(true);
        const data = await queuesService.fetch(id);
        setQueue(data);
      } catch (e) {
        console.error("Error fetching queue:", e);
        setError("Failed to load queue.");
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchQueue();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (id) {
        await queuesService.update(id, queue);
      } else {
        await queuesService.create(queue);
      }
      navigate("/queues");
    } catch (e) {
      console.error("Error saving queue:", e);
      setError("Failed to save queue.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Queue</Typography>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="type">Type</FormLabel>
          <TextField
            id="type"
            value={queue?.type || ""}
            onChange={(e) => setQueue({ ...queue, type: e.target.value })}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="status">Status</FormLabel>
          <Select
            id="status"
            value={queue?.status || "pending"}
            onChange={(e) => setQueue({ ...queue, status: e.target.value })}
            required
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="processing">Processing</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="dataSourceId">Data Source ID</FormLabel>
          <TextField
            id="dataSourceId"
            value={queue?.dataSourceId || ""}
            onChange={(e) => setQueue({ ...queue, dataSourceId: e.target.value })}
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="organization">Organization</FormLabel>
          <TextField
            id="organization"
            value={queue?.organization || ""}
            onChange={(e) => setQueue({ ...queue, organization: e.target.value })}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="results">Results</FormLabel>
          <TextField
            id="results"
            value={queue?.results || ""}
            onChange={(e) => setQueue({ ...queue, results: e.target.value })}
            multiline
            rows={4}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="error">Error</FormLabel>
          <TextField
            id="error"
            value={queue?.error || ""}
            onChange={(e) => setQueue({ ...queue, error: e.target.value })}
            multiline
            rows={2}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="createdAt">Created At</FormLabel>
          <TextField
            id="createdAt"
            type="datetime-local"
            value={queue?.createdAt || ""}
            onChange={(e) => setQueue({ ...queue, createdAt: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="startedAt">Started At</FormLabel>
          <TextField
            id="startedAt"
            type="datetime-local"
            value={queue?.startedAt || ""}
            onChange={(e) => setQueue({ ...queue, startedAt: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="completedAt">Completed At</FormLabel>
          <TextField
            id="completedAt"
            type="datetime-local"
            value={queue?.completedAt || ""}
            onChange={(e) => setQueue({ ...queue, completedAt: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default QueueForm; 