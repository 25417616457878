import { Box } from "@mui/material";
import { useLocation } from "./LocationContext";
import { IFile } from "types/files.d";
import FileGallery from 'components/FileGallery';
import { ILocationData } from "types/locations.d";

const LocationMedia: React.FC = () => {

  const { location, setLocation } = useLocation();

  const handleUpdateMedia = (files: IFile[]) => {
    const newLocation = {
      ...location,
      files: files
    };
    if (!location.defaultImage) {
      newLocation.defaultImage = files.find(f => f.type.startsWith('image')) || null;
    }
    if (!location.defaultVideo) {
      newLocation.defaultVideo = files.find(f => f.type.startsWith('video')) || null;
    }
    setLocation(newLocation);
  };

  const handleDeleteMedia = (file: IFile) => {
    setLocation({
      ...location,
      files: location.files?.filter(f => f.id !== file.id) || []
    });
  };

  const handleBulkDeleteMedia = (files: IFile[]) => {
    setLocation({
      ...location,
      files: location.files?.filter(f => !files.includes(f)) || []
    });
  };
  const handleSetDefaultImage = (file: IFile) => {
    setLocation({
      ...location,
      defaultImage: file
    });
  };

  const handleSetDefaultVideo = (file: IFile) => {
    setLocation({
      ...location,
      defaultVideo: file
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <FileGallery
        data={location as ILocationData}
        files={location?.files || []}
        updateFiles={handleUpdateMedia}
        onDelete={handleDeleteMedia}
        onBulkDelete={handleBulkDeleteMedia}
        onSetDefaultImage={handleSetDefaultImage}
        onSetDefaultVideo={handleSetDefaultVideo}
      />
    </Box>
  );
};

export default LocationMedia; 