import { IUser as User } from "./users";
import { ITag } from "./tags";
import { ILocationData } from "./locations.d";
import { IPromotion } from "./promotions.d";
import { IFile } from "./files.d";
import { ICategory } from "./categories";
import { ICommunity } from "./communities";
import { IRSVP } from "./rsvp.d";
import { IOrganization } from "./organizations.d";

export interface TicketTypeOption {
  _id?: string;
  id?: string;
  name: string;
  description?: string;
  type: 'text' | 'number' | 'boolean' | 'select';
  options?: string[];
  required: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  organization: IOrganization;
  price?: number;
}

export interface TicketType {
  _id?: string;
  id?: string;
  name: string;
  description?: string;
  price: number;
  available: number;
  event: string;
  sortOrder?: number;
  organization: string;
  ticketTypeOptions?: TicketTypeOption[];
  createdAt?: Date;
  updatedAt?: Date;
  organization: IOrganization;
}

export interface IEventResponse {
  page: number;
  limit: number;
  search: any;
  total: number;
  sortField: string;
  sortDirection: string;
  data: IEvent[];
}

export interface IGenerateEventImageResponse {
  image: string;
}
export enum StatusEnum {
  Published = "published",
  Pending = "pending",
}

export type StatusTypes = StatusEnum.Published | StatusEnum.Pending;

export interface IEvent {
  id?: string;
  _id?: string;
  createdBy?: User | string;
  createdDate?: string;
  updatedDate?: string;
  updatedBy?: User;
  organization: string; // Changed to required
  content?: JSON;
  title: string;
  slug: string;
  defaultImage?: IFile;
  defaultVideo?: IFile;
  files?: IFile[];
  description?: string;
  price?: string;
  tagIds: string[];
  link: string;
  status: StatusTypes;
  featured?: boolean;
  boosted?: boolean;
  location: ILocationData;
  locationName?: string;
  community?: ICommunity;
  promotion?: IPromotion;
  tags: ITag[];
  rsvpDeadline?: Date;
  maxCapacity?: number;
  membershipTypes?: Array<'basic' | 'premium' | 'vip'>;
  allowGuests: boolean;
  guestsLimit: number;
  rsvpOpenDate?: Date;
  waitlistEnabled: boolean;
  waitlistLimit?: number;
  eventType: 'single' | 'multi' | 'recurring';
  startDate: Date;
  endDate?: Date;
  startTime: string;
  endTime?: string;
  recurrenceType?: 'weekly' | 'monthly' | 'bi-weekly' | 'none';
  recurrenceRule?: {
    daysOfWeek?: string[];
    dayOfMonth?: string;
    weekOfMonth?: string;
  };
  category?: ICategory;
  createTicketsForRSVP?: boolean;
  allowRSVP?: boolean;
  rsvps?: IRSVP[];
  totalRSVPs?: number;
  totalAttending?: number;
  totalNotAttending?: number;
  totalMaybe?: number;
  allowTicketSales?: boolean;
  ticketTypes?: TicketType[];
}

export interface TicketType {
  _id?: string;
  id?: string;
  name: string;
  description?: string;
  price: number;
  available: number;
  event: string;
  sortOrder?: number;
  organization: string;
  includedGuests?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Photo {
  width: number;
  height: number;
  html_attributions: string[];
  url: string;
  photo_reference: string;
  _id: string;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum WeekOfMonth {
  First = 1,
  Second,
  Third,
  Fourth,
  Last,
}
