import { FileRejection, useDropzone } from "react-dropzone";
import { IFile } from "types/files.d";
import { useMediaGallery } from "./MediaGalleryContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DialogContent, Dialog, Grid, Box, Button, Typography } from "@mui/material";

import pdfIcon from "../../assets/images/icons/file-pdf.png";
import docIcon from "../../assets/images/icons/file-doc.png";
import xlsIcon from "../../assets/images/icons/file-xls.png";
import pptIcon from "../../assets/images/icons/file-ppt.png";
import txtIcon from "../../assets/images/icons/file-txt.png";
import htmlIcon from "../../assets/images/icons/file-html.png";

import filesService from "services/files";
import LoadingOverlay from "components/LoadingOverlay";
import { useState } from "react";
const Gallery = () => {

  const {
    loading,
    getFiles,
    gridData,
    selectedFiles,
    setSelectedFiles,
    setError,
    setLoading,
    selectionMethod,
    viewFolder,
    handleDelete,
  } = useMediaGallery();

  const [viewingImage, setViewingImage] = useState<IFile | null>(null);

  // const renderFileIcon = (file: IFile) => {
  //   switch (file.type) { 
  //     case "image/jpeg":
  //     case "image/png":
  //     case "image/gif":
  //     case "image/bmp":
  //     case "image/svg+xml":
  //       return <img src={file.url} alt="grid item" style={{ objectFit: "cover", width: "100%", height: "100%", maxWidth: "100%", maxHeight: "200px" }} />;
  //     case "video/mp4":
  //     case "video/avi":
  //     case "video/mov":
  //     case "video/wmv":
  //     case "video/flv":
  //       return <video src={file.url} style={{ objectFit: "cover", width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }} />;
  //     case "application/pdf":
  //       return <img style={{ width: "70%" }} src={pdfIcon} alt="pdf click" />;
  //     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
  //     case "application/msword":
  //       return <img style={{ width: "70%" }} src={docIcon} alt="doc click" />;
  //     case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
  //     case "application/vnd.ms-excel":
  //       return <img style={{ width: "70%" }} src={xlsIcon} alt="xls click" />;
  //     case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
  //     case "application/vnd.ms-powerpoint":
  //       return <img style={{ width: "70%" }} src={pptIcon} alt="ppt click" />;
  //     case "text/plain":
  //       return <img style={{ width: "70%" }} src={txtIcon} alt="txt click" />;
  //     case "text/html":
  //       return <img style={{ width: "70%" }} src={htmlIcon} alt="html click" />;
  //     default:
  //       return null;
  //   }
  // };

  const onDrop = async (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    try {
      setLoading(true);
      if (acceptedFiles.length === 0) return;
      for (const file of acceptedFiles) {
        await filesService.upload(file, viewFolder?.id);
      }
      getFiles();
    } catch (error) {
      setError(error);
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const maxFileSize = 500000000; //500MB
  const fileSizeValidator = (file: File) => {
    if (file.size > maxFileSize) {
      setError(`File size is larger than ${maxFileSize / 1000000} MB`);
      return {
        code: "filesize-too-large",
        message: `File size is larger than ${maxFileSize / 1000000} MB`,
      };
    }

    return null;
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.map((rejection) =>
      rejection.errors.map((error) => console.error(error.message))
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/msword": [".doc"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "text/html": [".html"],
      "image/*": [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".svg"],
      "video/*": [".mp4", ".avi", ".mov", ".wmv", ".flv", ".mkv", ".webm"],
    },
    validator: fileSizeValidator,
  });

  const handleFileClick = (file: any) => {
    if (selectedFiles.some(f => f._id === file._id)) {
      setSelectedFiles(selectedFiles.filter(f => f._id !== file._id));
    } else if (selectionMethod === 'single') {
      setSelectedFiles([file]);
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const handleEyeClick = (file: any) => {
    setViewingImage(file);
    setSelectedFiles([file]);
  };

  const handleClose = () => {
    setViewingImage(null);
  };

  const handleCopy = () => {
    if (viewingImage?.url) {
      navigator.clipboard.writeText(viewingImage.url);
    }
  };

  const handleDeleteClick = async () => {
    await handleDelete();
    setViewingImage(null);
  };

  return (
    <div className="p-5 bg-white h-full w-full">
      <div
        className="flex flex-row flex-wrap gap-5 overflow-auto h-full"
        {...getRootProps({
          onClick: (event) => event.stopPropagation(),
        })}
      >
        <div
          className={`absolute inset-0 flex items-center justify-center 
            ${isDragActive ? 'bg-white/90 z-10' : 'transparent -z-10'}
            transition-all duration-200 ease-in-out`}
        >
          <input {...getInputProps()} />
          <p className="text-center">
            {isDragActive
              ? "Drop the files here..."
              : ""}
          </p>
          {loading && <LoadingOverlay loading={loading} />}
        </div>

        <Grid container spacing={2} padding={2}>
          {gridData?.map((file) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={file._id}>
              <div className={`bg-white rounded-lg shadow overflow-hidden ${selectedFiles.some(f => f._id === file._id) ? 'border-2 border-blue-500' : 'border border-gray-200'}`} onClick={() => handleFileClick(file)}>
                <div className="relative aspect-square">
                  <VisibilityIcon className="absolute top-2 right-2 text-white bg-blue-500 rounded-full p-1 w-8 h-8 z-10 cursor-pointer shadow-md shadow-black/50" onClick={(e) => {
                    e.stopPropagation();
                    handleEyeClick(file);
                  }} />
                  {file.type?.startsWith('video/') ? (
                    <video
                      src={file.url}
                      className="absolute inset-0 w-full h-full object-cover cursor-pointer"
                      preload="metadata"
                      muted
                      playsInline
                      onLoadedMetadata={(e) => {
                        // Seek to first frame to show thumbnail
                        const video = e.target as HTMLVideoElement;
                        video.currentTime = 0.1;
                      }}
                      onMouseEnter={(e) => {
                        const video = e.target as HTMLVideoElement;
                        video.play();
                      }}
                      onMouseLeave={(e) => {
                        const video = e.target as HTMLVideoElement;
                        video.pause();
                      }}
                      onTouchStart={(e) => {
                        const video = e.target as HTMLVideoElement;
                        video.play();
                      }}
                      onTouchEnd={(e) => {
                        const video = e.target as HTMLVideoElement;
                        video.pause();
                      }}
                    />
                  ) : (
                    <img
                      src={file.url}
                      alt={`${file.id}`}
                      className="absolute inset-0 w-full h-full object-cover cursor-pointer"
                    />
                  )}
                </div>
                <div className="flex justify-between items-center px-2 py-1">
                  <p className="text-center overflow-hidden whitespace-nowrap text-ellipsis">{file.name || "Filename not found.."}</p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <Dialog
        open={viewingImage !== null}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: { height: "100vh", display: "flex", flexDirection: "row", overflow: "hidden" },
        }}
      >
        <DialogContent style={{ display: "flex", width: "100%", padding: 0, overflow: "hidden" }}>
          {/* Left Side - Full Image View */}
          <Box
            sx={{
              flex: 1,
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {viewingImage && (
              viewingImage.type.startsWith('image') ? (
                <img
                  src={viewingImage.url}
                  alt={`Viewing ${viewingImage.id}`}
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <video
                  src={viewingImage.url}
                  controls
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                  }}
                />
              )
            )}
          </Box>

          {/* Right Side - URL and Delete Button */}
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              padding: 3,
              backgroundColor: "#fff",
            }}
          >
            {viewingImage && (
              <Box>
                <Typography variant="h5">File Name</Typography>
                <Typography variant="body2" style={{ maxWidth: "100%", wordWrap: "break-word" }}>{viewingImage.name}</Typography>
              </Box>
            )}

            {viewingImage && <Box sx={{ mt: 4 }}>
              <Typography variant="h5">File URL</Typography>
              <Box
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  paddingBottom: 1,
                  borderBottom: "1px solid #ddd",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Typography variant="body2" sx={{ display: "inline-block" }}>
                  {viewingImage.url}
                </Typography>
              </Box>
            </Box>}

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopy}
                sx={{ width: "50%" }}
              >
                Copy URL
              </Button>
            </Box>
            {/* {error && <Typography variant="body2" style={{ color: 'red', marginTop: '8px' }}>{error}</Typography>} */}

            {/* Push Delete Button to Bottom */}
            <Box sx={{ flexGrow: 1 }} />

            {/* Delete Button at Bottom Right */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginRight: 2 }}
                onClick={() => handleClose()}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteClick()}
              >
                DELETE
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Gallery;
