import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IEvent } from "types/events.d";
import eventService from "services/events";
import { useAuth } from "context/Auth";
import { StatusEnum } from "types/events.d";

import eventsService from "services/events";

const DEFAULT_CREATE_EVENT: IEvent = {
  title: "",
  createdDate: new Date().toISOString(),
  link: "",
  location: undefined,
  slug: "",
  tagIds: [],
  defaultImage: undefined,
  tags: [],
  id: "",
  updatedBy: undefined,
  updatedDate: new Date().toISOString(),
  status: StatusEnum.Published,
  eventType: "single",
  organization: null,
  allowRSVP: false,
  allowGuests: false,
  guestsLimit: 0,
  waitlistEnabled: false,
  allowTicketSales: false,
  createTicketsForRSVP: false,
  ticketTypes: [],
  description: "",
  startDate: new Date(),
  startTime: "18:00",
}

interface EventContextType {
  event: Partial<IEvent>;
  setEvent: React.Dispatch<React.SetStateAction<Partial<IEvent>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  id: string | undefined;
  saveEvent: (additionalProps?: Record<string, any>) => Promise<boolean>;
  deleteEvent: () => Promise<boolean>;
}

interface IEventAPIRequest extends Omit<IEvent, "location"> {
  location: string;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

export const EventProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [event, setEvent] = useState<Partial<IEvent>>({
    ...DEFAULT_CREATE_EVENT,
  });
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  // useEffect(() => {
  //   const fetchEvent = async () => {
  //     try {
  //       setLoading(true);
  //       const _event: IEvent = await eventService.fetch(id);
  //       if (!_event.status) {
  //         _event.status = StatusEnum.Published;
  //       }
  //       setEvent(_event);
  //     } catch (e) {
  //       console.error("Error fetching event:", e);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   if (id && id !== "create") {
  //     fetchEvent();
  //   }
  // }, [id]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const event: IEvent = await eventsService.fetch(id);
        //not all events have status, so we set it to published by default
        if (!event.status) {
          event.status = StatusEnum.Pending;
        }
        setEvent(event);
      } catch (e) {
        console.error("Error fetching events:", e);
      }
    };

    if (id && id !== "create") {
      fetchEvent();
    }
  }, [id]);

  const saveEvent = async (additionalProps = {}) => {
    try {
      setLoading(true);

      if (!event.title || !event?.defaultImage) {
        throw new Error("Please fill in all fields");
      }

      const updatedEvent = {
        ...event,
        ...additionalProps,
        category: event.category || null,
        community: event.community || null,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      if (id === "create") {
        updatedEvent.createdBy = user;
        updatedEvent.createdDate = new Date().toISOString();
        delete updatedEvent.id;
        delete updatedEvent._id;
        await eventsService.create(updatedEvent);
      } else {
        updatedEvent.updatedBy = user;
        updatedEvent.updatedDate = new Date().toISOString();
        await eventsService.update(updatedEvent.id || updatedEvent._id, updatedEvent);
      }
      return true;
    } catch (error: any) {
      console.error("Error saving event:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteEvent = async () => {
    try {
      await eventsService.remove(id);
      return true;
    } catch (error: any) {
      setError(error.message);
      return false;
    }
  };

  return (
    <EventContext.Provider
      value={{
        event,
        setEvent,
        loading,
        setLoading,
        error,
        setError,
        user,
        id,
        saveEvent,
        deleteEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error("useEvent must be used within a EventProvider");
  }
  return context;
};