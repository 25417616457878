import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { INewsletter } from "types/newsletters";
import LoadingOverlay from "components/LoadingOverlay";
import { useState } from "react";
import TemplateSelect from 'components/TemplateSelect';
import { ITemplate } from 'types/templates';
import EventSelect from "components/EventSelect";
import { IEvent } from "types/events.d";
import TagMultiSelect from "components/TagMultiSelect";
import { ITag } from "types/tags";
import MemberMultiSelect from "components/MemberMultiSelect";
import { IMember } from "types/members";

interface NewsLetterFormProps {
  id: string;
  newsletter?: Partial<INewsletter>;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  setError: (error: string) => void;
  loading: boolean;
  handleSave: () => void;
  handleDelete: () => void;
}

const NewsLetterForm: React.FC<NewsLetterFormProps> = ({ id, newsletter, setNewsletter, setError, loading, handleSave, handleDelete }) => {
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });

  const handleTemplateChange = (template: ITemplate | null) => {
    setNewsletter({
      ...newsletter,
      templateId: template?.id || undefined,
      template: template || undefined,
      // Reset template data when template changes
      templateData: {}
    });
  };

  const handleTemplateDataChange = (key: string, value: any) => {
    setNewsletter({
      ...newsletter,
      templateData: {
        ...newsletter?.templateData,
        [key]: value
      }
    });
  };

  // Helper function to render the appropriate input based on requirement type
  const renderRequirementField = (key: string, requirement: any) => {
    const value = newsletter?.templateData?.[key];

    switch (key) {
      case 'event':
        return (
          <EventSelect
            value={value || null}
            onChange={(event: IEvent | null) => handleTemplateDataChange(key, event)}
          />
        );
      case 'tags':
        return (
          <TagMultiSelect
            selectedTags={value || []}
            setSelectedTags={(tags: ITag[]) => handleTemplateDataChange(key, tags)}
            type={requirement.type ? [requirement.type] : undefined}
          />
        );
      case 'members':
        return (
          <MemberMultiSelect
            selectedMembers={value || []}
            setSelectedMembers={(members: IMember[]) => handleTemplateDataChange(key, members)}
          />
        );
      default:
        return (
          <TextField
            fullWidth
            value={value || ''}
            onChange={(e) => handleTemplateDataChange(key, e.target.value)}
            required={requirement.required}
          />
        );
    }
  };

  return (
    <>
      <Grid container item direction={"row"} xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-subject">Subject</FormLabel>
            <TextField
              id="newsletter-subject"
              value={newsletter?.subject || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, subject: e.target.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor="newsletter-template">Template</FormLabel>
            <TemplateSelect
              value={newsletter?.template || null}
              onChange={handleTemplateChange}
              label="Select Template"
            />
          </FormControl>
        </Grid>

        {newsletter?.template?.requirements?.required && Object.entries(newsletter.template.requirements.required).map(([key, requirement]) => (
          <Grid item xs={12} key={key}>
            <FormControl fullWidth>
              <FormLabel required htmlFor={`newsletter-${key}`}>{key.charAt(0).toUpperCase() + key.slice(1)}</FormLabel>
              {renderRequirementField(key, requirement)}
            </FormControl>
          </Grid>
        ))}

        {newsletter?.template?.requirements?.optional && Object.entries(newsletter.template.requirements.optional).map(([key, requirement]) => (
          <Grid item xs={12} key={key}>
            <FormControl fullWidth>
              <FormLabel htmlFor={`newsletter-${key}`}>{key.charAt(0).toUpperCase() + key.slice(1)}</FormLabel>
              {renderRequirementField(key, requirement)}
            </FormControl>
          </Grid>
        ))}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel htmlFor="newsletter-message">Message</FormLabel>
            <TextField
              id="newsletter-message"
              multiline
              rows={4}
              value={newsletter?.message || ""}
              onChange={(e) => setNewsletter({ ...newsletter, message: e.target.value })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-status">Status</FormLabel>
            <Select
              id="newsletter-status"
              value={newsletter?.status || "draft"}
              onChange={(e) => setNewsletter({ ...newsletter, status: e.target.value as any })}
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="newsletter-scheduled-date">Scheduled Date</FormLabel>
            <TextField
              id="newsletter-scheduled-date"
              type="datetime-local"
              value={newsletter?.scheduledDate ? new Date(newsletter.scheduledDate).toISOString().slice(0, 16) : ""}
              disabled={newsletter?.status !== 'scheduled'}
              onChange={(e) => setNewsletter({ ...newsletter, scheduledDate: new Date(e.target.value) })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {newsletter?.status === 'draft' ? 'Save Draft' : newsletter?.status === 'scheduled' ? 'Schedule' : 'Send'}
          </Button>
        </Grid>
      </Grid>

      {id && id !== "create" && (
        <Grid item xs={12}>
          <Typography variant="h4" color="error">
            Danger Zone
          </Typography>
          <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
      )}

      <LoadingOverlay loading={loading} />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </>
  );
};

export default NewsLetterForm;