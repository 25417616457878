import React, { useState, useEffect, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import ticketsService from 'services/tickets';
import audioUtils from 'utils/audio';

interface TicketData {
  id: string;
  ticketNumber: string;
  eventId: string;
  name: string;
  validationUrl: string;
}

interface ValidationResponse {
  valid: boolean;
  message: string;
  ticket?: any;
}

const Scanner = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState<TicketData | null | { error: string }>(null);
  const [validationResult, setValidationResult] = useState<ValidationResponse | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(() => {
    // Initialize from localStorage or default to true
    const savedPreference = localStorage.getItem('scanner_sound_enabled');
    return savedPreference !== null ? savedPreference === 'true' : true;
  });
  const [vibrationEnabled, setVibrationEnabled] = useState(() => {
    // Initialize from localStorage or default to true if device supports vibration
    const savedPreference = localStorage.getItem('scanner_vibration_enabled');
    return savedPreference !== null ? savedPreference === 'true' : audioUtils.supportsVibration();
  });
  const [flashStatus, setFlashStatus] = useState<'success' | 'error' | null>(null);
  const [audioPermissionError, setAudioPermissionError] = useState(false);
  const [vibrationSupported] = useState(audioUtils.supportsVibration());
  // Add camera error state
  const [cameraError, setCameraError] = useState<string | null>(null);
  // Add manual entry state
  const [showManualEntry, setShowManualEntry] = useState(false);
  const [manualTicketId, setManualTicketId] = useState('');
  // Check if running on HTTPS
  const [isHttps, setIsHttps] = useState(false);
  // Check if browser supports camera access
  const [isCameraSupported, setIsCameraSupported] = useState(true);
  // Add state to track if camera has been initialized
  const [cameraInitialized, setCameraInitialized] = useState(false);
  // Add ref to store the camera stream
  const cameraStreamRef = useRef<MediaStream | null>(null);

  // Save sound preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('scanner_sound_enabled', soundEnabled.toString());

    // If sound is being enabled, try to initialize audio context
    if (soundEnabled) {
      try {
        audioUtils.initAudio();
        setAudioPermissionError(false);
      } catch (error) {
        console.error('Error initializing audio:', error);
        setAudioPermissionError(true);
      }
    }
  }, [soundEnabled]);

  // Save vibration preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('scanner_vibration_enabled', vibrationEnabled.toString());
  }, [vibrationEnabled]);

  // Initialize audio on component mount
  useEffect(() => {
    // Try to initialize audio on component mount
    const initAudio = () => {
      if (soundEnabled) {
        try {
          audioUtils.initAudio();
          setAudioPermissionError(false);
        } catch (error) {
          console.error('Error initializing audio on mount:', error);
        }
      }
    };

    // Try initial initialization
    initAudio();

    // Function to initialize audio after user interaction
    const initAudioAfterInteraction = () => {
      try {
        audioUtils.initAudio();
        setAudioPermissionError(false);
      } catch (error) {
        console.error('Error initializing audio after interaction:', error);
        setAudioPermissionError(true);
      }
    };

    // Add event listeners for user interaction
    document.addEventListener('click', initAudioAfterInteraction, { once: true });
    document.addEventListener('touchstart', initAudioAfterInteraction, { once: true });

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('click', initAudioAfterInteraction);
      document.removeEventListener('touchstart', initAudioAfterInteraction);
    };
  }, [soundEnabled]);

  // Reset flash effect after a short delay
  useEffect(() => {
    if (flashStatus) {
      const timer = setTimeout(() => {
        setFlashStatus(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [flashStatus]);

  // Check protocol on component mount
  useEffect(() => {
    setIsHttps(window.location.protocol === 'https:');
  }, []);

  // Check browser compatibility on component mount
  useEffect(() => {
    // Check if MediaDevices API is supported
    const isMediaDevicesSupported = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    setIsCameraSupported(isMediaDevicesSupported);

    // Automatically show manual entry if camera isn't supported
    if (!isMediaDevicesSupported) {
      setShowManualEntry(true);
    } else {
      // If camera is supported, check for persistent permissions
      checkCameraPermissions();
    }
  }, []);

  // Function to check if camera permissions are already granted
  const checkCameraPermissions = async () => {
    try {
      // Check if we already have camera permissions
      const permissions = await navigator.permissions.query({ name: 'camera' as PermissionName });

      if (permissions.state === 'granted') {
        // If permissions are already granted, we can pre-initialize the camera
        // This helps avoid the permission prompt when the user clicks "Start Scanning"
        console.log('Camera permissions already granted');
        // Don't auto-initialize to avoid unnecessary camera activation
        // Just mark as initialized so we don't prompt again
        setCameraInitialized(true);
      } else {
        console.log('Camera permissions not yet granted:', permissions.state);
      }
    } catch (error) {
      console.error('Error checking camera permissions:', error);
      // Some browsers don't support permissions API, so we'll just continue normally
    }
  };

  // Add effect to handle camera stream cleanup on component unmount
  useEffect(() => {
    // Cleanup function to stop all tracks when component unmounts
    return () => {
      if (cameraStreamRef.current) {
        cameraStreamRef.current.getTracks().forEach(track => {
          track.stop();
        });
        cameraStreamRef.current = null;
      }
    };
  }, []);

  const toggleSound = () => {
    // If turning sound on, initialize audio first
    if (!soundEnabled) {
      try {
        audioUtils.initAudio();
        setAudioPermissionError(false);
      } catch (error) {
        console.error('Error initializing audio:', error);
        setAudioPermissionError(true);
      }
    }
    setSoundEnabled(!soundEnabled);
  };

  const toggleVibration = () => {
    setVibrationEnabled(!vibrationEnabled);
  };

  const testSound = (type: 'success' | 'error') => {
    try {
      // Initialize audio first to ensure permissions
      audioUtils.initAudio();
      setAudioPermissionError(false);

      // Play the requested sound
      if (type === 'success') {
        if (soundEnabled) {
          audioUtils.playSuccessSound();
        } else if (vibrationEnabled) {
          // If sound is disabled but vibration is enabled, just vibrate
          audioUtils.vibrateSuccess();
        }
      } else {
        if (soundEnabled) {
          audioUtils.playErrorSound();
        } else if (vibrationEnabled) {
          // If sound is disabled but vibration is enabled, just vibrate
          audioUtils.vibrateError();
        }
      }
    } catch (error) {
      console.error(`Error playing ${type} sound:`, error);
      setAudioPermissionError(true);
    }
  };

  const provideFeedback = (isSuccess: boolean) => {
    // Set visual flash
    setFlashStatus(isSuccess ? 'success' : 'error');

    // Provide audio and/or vibration feedback
    if (isSuccess) {
      if (soundEnabled) {
        audioUtils.playSuccessSound(); // This also vibrates if vibration is enabled
      } else if (vibrationEnabled) {
        audioUtils.vibrateSuccess();
      }
    } else {
      if (soundEnabled) {
        audioUtils.playErrorSound(); // This also vibrates if vibration is enabled
      } else if (vibrationEnabled) {
        audioUtils.vibrateError();
      }
    }
  };

  const validateTicket = async (ticketId: string) => {
    setIsValidating(true);
    try {
      const response = await ticketsService.validate(ticketId);
      setValidationResult({
        valid: response.valid,
        message: response.message,
        ticket: response.ticket
      });

      // Provide feedback based on validation result
      provideFeedback(response.valid);
    } catch (error: any) {
      setValidationResult({
        valid: false,
        message: error.response?.data?.message || 'Error validating ticket',
      });
      // Provide error feedback
      provideFeedback(false);
    } finally {
      setIsValidating(false);
    }
  };

  const handleScan = async (result: any, error: any) => {
    // Handle errors from the scanner
    // if (error) {
    //   console.error('QR Scanner error:', error);

    //   // Don't set camera error for normal operation errors
    //   // Only set for critical errors that prevent scanning
    //   if (error.name === 'NotAllowedError' ||
    //     error.name === 'NotFoundError' ||
    //     error.name === 'NotReadableError' ||
    //     error.name === 'OverconstrainedError') {
    //     setCameraError(`Camera error: ${error.message || 'Unknown error'}`);
    //     // For critical errors, we should reset the camera initialization
    //     setCameraInitialized(false);
    //     setIsScanning(false);
    //   }
    //   return;
    // }

    // Handle successful scan
    if (result) {
      try {
        const jsonResult = JSON.parse(result.text);

        setScanResult(jsonResult);
        setIsScanning(false);
        // Automatically validate the ticket after scanning
        await validateTicket(jsonResult.id);
      } catch (error: any) {
        setScanResult({ error: 'Invalid QR code: Not a valid ticket' });
        setIsScanning(false);
        // Provide error feedback for invalid QR codes
        provideFeedback(false);
      }
    }
  };

  const handleManualSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!manualTicketId.trim()) return;

    try {
      await validateTicket(manualTicketId.trim());
      // Create a minimal ticket object for display
      setScanResult({
        id: manualTicketId.trim(),
        ticketNumber: 'Manual Entry',
        eventId: 'N/A',
        name: 'Manual Entry',
        validationUrl: ''
      });
    } catch (error) {
      console.error('Error validating manual ticket:', error);
    }
  };

  const renderValidationResult = () => {
    if (!validationResult) return null;

    return (
      <div className={`mt-4 p-4 rounded-md ${validationResult.valid
        ? 'bg-green-50 border border-green-200'
        : 'bg-red-50 border border-red-200'
        }`}>
        <div className="flex items-center gap-2">
          {validationResult.valid ? (
            <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          ) : (
            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
          <span className={validationResult.valid ? 'text-green-700' : 'text-red-700'}>
            {validationResult.message}
          </span>
        </div>
      </div>
    );
  };

  const renderTicketDetails = (ticket: TicketData | { error: string }) => {
    if ('error' in ticket) {
      return (
        <div className="text-red-600 p-4 bg-red-50 rounded-md">
          {ticket.error}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {isValidating ? (
          <div className="flex items-center justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            <span className="ml-2 text-gray-600">Validating ticket...</span>
          </div>
        ) : (
          <>
            {renderValidationResult()}
            <div>
              <h3 className="text-sm font-medium text-gray-500">Ticket Number</h3>
              <p className="text-lg font-semibold">{ticket.ticketNumber}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Event ID</h3>
              <p className="text-lg font-semibold">{ticket.eventId}</p>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500">Attendee Name</h3>
              <p className="text-lg font-semibold">{ticket.name}</p>
            </div>
            <div className="mt-4 pt-4 border-t border-gray-200">
              <p className="text-xs text-gray-500">Ticket ID: {ticket.id}</p>
            </div>
          </>
        )}
      </div>
    );
  };

  // Function to initialize camera once and store the stream
  const initializeCamera = async () => {
    if (cameraInitialized) {
      // If camera is already initialized, just start scanning
      setIsScanning(true);
      return;
    }

    try {
      // Request camera permissions explicitly once
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' }
      });

      // Store the stream reference
      cameraStreamRef.current = stream;
      setCameraInitialized(true);
      setCameraError(null);

      // Start scanning
      setIsScanning(true);
    } catch (error: any) {
      console.error('Error initializing camera:', error);
      setCameraError(`Camera error: ${error.message || 'Unknown error'}`);
    }
  };

  return (
    <div className={`p-6 max-w-2xl mx-auto transition-colors duration-300 ${flashStatus === 'success' ? 'bg-green-50' :
      flashStatus === 'error' ? 'bg-red-50' :
        'bg-white'
      }`}>
      <h1 className="text-3xl font-bold mb-4">
        Ticket Scanner
      </h1>

      {/* HTTPS Warning */}
      {!isHttps && (
        <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-md text-yellow-800">
          <p className="font-semibold">⚠️ HTTPS Required for Camera Access</p>
          <p className="text-sm mt-1">
            Most mobile browsers require HTTPS for camera access.
            If the scanner doesn't work, try accessing this page via HTTPS or use the manual entry option.
          </p>
        </div>
      )}

      {/* Browser compatibility warning */}
      {!isCameraSupported && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-800">
          <p className="font-semibold">❌ Camera Access Not Supported</p>
          <p className="text-sm mt-1">
            Your browser doesn't appear to support camera access.
            Please try a modern browser like Chrome, Firefox, or Safari, or use the manual entry option below.
          </p>
        </div>
      )}

      <div className="mb-4 flex flex-col gap-3">
        {/* Sound toggle */}
        <div className="flex items-center">
          <label htmlFor="sound-toggle" className="mr-2 text-gray-700 w-32">
            Sound Feedback:
          </label>
          <div
            className={`relative inline-block w-12 h-6 transition-colors duration-200 ease-in-out rounded-full cursor-pointer ${soundEnabled ? 'bg-blue-500' : 'bg-gray-300'}`}
            onClick={toggleSound}
          >
            <span
              className={`absolute left-1 top-1 w-4 h-4 transition-transform duration-200 ease-in-out bg-white rounded-full transform ${soundEnabled ? 'translate-x-6' : 'translate-x-0'}`}
            />
          </div>

          {soundEnabled && (
            <div className="ml-4 flex space-x-2">
              <button
                className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200 transition-colors"
                onClick={() => testSound('success')}
                title="Test success sound"
              >
                Test Success
              </button>
              <button
                className="text-xs px-2 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
                onClick={() => testSound('error')}
                title="Test error sound"
              >
                Test Error
              </button>
            </div>
          )}
        </div>

        {/* Vibration toggle - only show if device supports vibration */}
        {vibrationSupported && (
          <div className="flex items-center">
            <label htmlFor="vibration-toggle" className="mr-2 text-gray-700 w-32">
              Vibration:
            </label>
            <div
              className={`relative inline-block w-12 h-6 transition-colors duration-200 ease-in-out rounded-full cursor-pointer ${vibrationEnabled ? 'bg-blue-500' : 'bg-gray-300'}`}
              onClick={toggleVibration}
            >
              <span
                className={`absolute left-1 top-1 w-4 h-4 transition-transform duration-200 ease-in-out bg-white rounded-full transform ${vibrationEnabled ? 'translate-x-6' : 'translate-x-0'}`}
              />
            </div>

            {vibrationEnabled && (
              <div className="ml-4 flex space-x-2">
                <button
                  className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200 transition-colors"
                  onClick={() => audioUtils.vibrateSuccess()}
                  title="Test success vibration"
                >
                  Test Success
                </button>
                <button
                  className="text-xs px-2 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200 transition-colors"
                  onClick={() => audioUtils.vibrateError()}
                  title="Test error vibration"
                >
                  Test Error
                </button>
              </div>
            )}
          </div>
        )}

        {audioPermissionError && (
          <div className="mt-2 text-sm text-amber-600 bg-amber-50 p-2 rounded-md">
            <p>
              <span className="font-semibold">Note:</span> Your browser may require user interaction before playing sounds.
              Click the test buttons or interact with the page to enable audio.
            </p>
          </div>
        )}

        {!vibrationSupported && soundEnabled && (
          <div className="mt-2 text-sm text-blue-600 bg-blue-50 p-2 rounded-md">
            <p>
              <span className="font-semibold">Note:</span> Your device doesn't support vibration feedback.
              Sound feedback will be used instead.
            </p>
          </div>
        )}
      </div>

      {!isScanning ? (
        <div className="flex flex-col gap-4">
          {/* Only show scan button if camera is supported */}
          {isCameraSupported && (
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition-colors"
              onClick={() => {
                // Reset camera error state when starting to scan
                setCameraError(null);

                // Initialize audio and vibration when starting to scan
                if (soundEnabled) {
                  try {
                    audioUtils.initAudio();
                    // Play a silent beep to ensure audio is working
                    // This helps on iOS where audio needs to be triggered by direct user interaction
                    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
                    if (AudioContext) {
                      const context = new AudioContext();
                      const oscillator = context.createOscillator();
                      const gainNode = context.createGain();
                      gainNode.gain.value = 0.01; // Almost silent
                      oscillator.connect(gainNode);
                      gainNode.connect(context.destination);
                      oscillator.start(0);
                      oscillator.stop(0.01);
                    }
                    setAudioPermissionError(false);
                  } catch (error) {
                    console.error('Error initializing audio when starting scan:', error);
                    setAudioPermissionError(true);
                  }
                }

                // Test vibration with a very short pulse if enabled
                if (vibrationEnabled && audioUtils.supportsVibration()) {
                  try {
                    // Very short vibration to test if it's working
                    audioUtils.vibrate(10);
                  } catch (error) {
                    console.error('Error testing vibration:', error);
                  }
                }

                // Use the new camera initialization function
                initializeCamera();
              }}
            >
              Start Scanning
            </button>
          )}

          {/* Always show manual entry option, but auto-show it if camera isn't supported */}
          <button
            className="bg-gray-100 hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded transition-colors"
            onClick={() => setShowManualEntry(!showManualEntry)}
          >
            {showManualEntry ? 'Hide Manual Entry' : 'Enter Ticket ID Manually'}
          </button>

          {/* Manual entry form */}
          {showManualEntry && (
            <div className="mt-2 p-4 bg-gray-50 rounded-md">
              <h3 className="text-lg font-semibold mb-2">Manual Ticket Entry</h3>
              <form onSubmit={handleManualSubmit} className="flex flex-col gap-2">
                <input
                  type="text"
                  value={manualTicketId}
                  onChange={(e) => setManualTicketId(e.target.value)}
                  placeholder="Enter ticket ID"
                  className="border border-gray-300 rounded px-3 py-2"
                  required
                />
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition-colors"
                  disabled={isValidating}
                >
                  {isValidating ? 'Validating...' : 'Validate Ticket'}
                </button>
              </form>
            </div>
          )}

          {cameraError && (
            <div className="mt-2 text-sm text-red-600 bg-red-50 p-3 rounded-md">
              <p className="font-semibold">Camera Error:</p>
              <p>{cameraError}</p>
              <p className="mt-2">
                Please ensure you've granted camera permissions to this site.
                On mobile devices, you may need to access this site via HTTPS or add it to your home screen.
              </p>
            </div>
          )}

          {scanResult && (
            <div className="bg-white shadow-md rounded-lg p-4">
              <h2 className="text-xl font-semibold mb-4">Ticket Details</h2>
              {renderTicketDetails(scanResult)}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full max-w-md mx-auto">
          {/* Add a loading indicator while camera initializes */}
          {!cameraInitialized && (
            <div className="flex flex-col items-center justify-center p-8 bg-gray-100 rounded-md mb-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2"></div>
              <p className="text-gray-600">Initializing camera...</p>
            </div>
          )}

          <QrReader
            onResult={handleScan}
            constraints={{
              facingMode: 'environment',
            }}
          />

          <div className="mt-2 text-xs text-gray-500 text-center">
            <p>Point your camera at a QR code to scan it.</p>
            <p className="mt-1">If the camera doesn't start, try refreshing the page or check your browser settings.</p>
          </div>

          <div className="flex gap-2 mt-4">
            <button
              className="flex-1 border border-gray-300 hover:border-gray-400 text-gray-700 font-semibold py-2 px-4 rounded transition-colors"
              onClick={() => {
                // Just hide the scanner UI without stopping the camera stream
                setIsScanning(false);
              }}
            >
              Cancel Scanning
            </button>

            {/* Add a button to manually trigger camera permissions if needed */}
            <button
              className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition-colors"
              onClick={() => {
                // Reset camera initialization and try again
                setCameraInitialized(false);
                cameraStreamRef.current?.getTracks().forEach(track => track.stop());
                cameraStreamRef.current = null;

                // Re-initialize camera
                initializeCamera();
              }}
            >
              Reset Camera
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Scanner;
