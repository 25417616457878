import { useLocation, Navigate, Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  IconButton,
  Alert,
  Button,
  useMediaQuery,
  TabScrollButton,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import { StatusEnum } from "types/events.d";
import DetailsTab from "./DetailsTab";
import OccurrenceTab from "./OccurrenceTab";
import MediaTab from "./MediaTab";
import PromotionsTab from "./PromotionsTab";
import TicketsTab from "./TicketsTab";
import RSVPsTab from "./RsvpTab";
import AdminTab from "./AdminTab";
import LoadingOverlay from "components/LoadingOverlay";
import { EventProvider, useEvent } from "./EventContext";
import theme from "assets/theme";
import { useState } from "react";

const tabPaths = {
  details: "Details",
  occurrence: "Occurrence",
  media: "Media",
  promotions: "Promotions",
  tickets: "Tickets",
  rsvps: "RSVPs",
  admin: "Admin",
};

enum TabEnum {
  "details",
  "occurrence",
  "media",
  "promotions",
  "tickets",
  "rsvps",
  "admin",
}
const EventContent: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error, saveEvent, event } = useEvent();
  const currentTab = location?.pathname?.split("/")?.pop() || "details";
  const [carouselPosition, setCarouselPosition] = useState(TabEnum[currentTab] || 1);

  const handleSave = (data?: Record<string, any>) => async () => {
    const result = await saveEvent(data);
    if (result) {
      navigate("/events");
    }
  };

  const visibleTabs: () => number[] = () => {
    if (!isSmallScreen) {
      return Object.keys(tabPaths).map((tab, index) => index);
    }
    if (carouselPosition === 0 || !carouselPosition) {
      return [0, 1, 2];
    }
    if (carouselPosition === Object.keys(tabPaths).length - 1) {
      return [carouselPosition - 2, carouselPosition - 1, carouselPosition];
    }
    return [carouselPosition - 1, carouselPosition, carouselPosition + 1];
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/events")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">{event?.title ? "Event: " + event.title : "Event"}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: 2, ml: 4 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave({ status: StatusEnum.Pending })}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={handleSave({ status: StatusEnum.Published })}
                disabled={loading}
              >
                Publish
              </Button>
            </Box>
          </Box>
        </Grid>

        {error && <Alert severity="error">{error}</Alert>}

        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 3,
            }}
          >
            <Tabs
              value={tabPaths[currentTab] || "details"}
              // onChange={(event, value) => setTab(TabEnum[value])}
              aria-label="scrollable force tabs example"
            >
              {isSmallScreen && (
                <TabScrollButton
                  direction="left"
                  orientation={"horizontal"}
                  onClick={() => setCarouselPosition((prev: number) => prev - 1)}
                  disabled={carouselPosition <= 1}
                />
              )}
              {/* When in mobile, tabs are conditionally rendered. If a selected tab is not rendered,
              then MUI throws an error. The following renders the tab option but hides it to kill the warning. <Tabs> has a 
              UI underline feature for the currently selected tab. By rendering the hidden tab before or after
              the conditional render, the underline now helps users see where they are in the carousel. */}
              {isSmallScreen &&
                !visibleTabs().includes(tabPaths[currentTab]) &&
                TabEnum[currentTab] < carouselPosition && (
                  <Tab
                    label={currentTab}
                    value={currentTab}
                    component={Link}
                    to={tabPaths[currentTab]}
                    sx={{ visibility: "hidden", width: 0, height: 0 }}
                  />
                )}
              {Object.entries(tabPaths).map(([key, value]) =>
                visibleTabs().includes(TabEnum[key as keyof typeof TabEnum]) ? (
                  <Tab key={key} label={value} value={key} component={Link} to={value} />
                ) : null
              )}
              {isSmallScreen &&
                !visibleTabs().includes(tabPaths[currentTab]) &&
                TabEnum[currentTab] > carouselPosition && (
                  <Tab
                    label={currentTab}
                    value={currentTab}
                    component={Link}
                    to={tabPaths[currentTab]}
                    sx={{ visibility: "hidden", width: 0, height: 0 }}
                  />
                )}
              {isSmallScreen && (
                <TabScrollButton
                  direction="right"
                  orientation={"horizontal"}
                  onClick={() => setCarouselPosition((prev) => prev + 1)}
                  disabled={carouselPosition >= Object.keys(tabPaths).length - 2}
                />
              )}
            </Tabs>
          </Box>
          <Routes>
            <Route path="details" element={<DetailsTab />} />
            <Route path="occurrence" element={<OccurrenceTab />} />
            <Route path="media" element={<MediaTab />} />
            <Route path="promotions" element={<PromotionsTab />} />
            <Route path="tickets" element={<TicketsTab />} />
            <Route path="rsvps" element={<RSVPsTab />} />
            <Route path="admin" element={<AdminTab />} />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const Event: React.FC = () => {
  return (
    <EventProvider>
      <EventContent />
    </EventProvider>
  );
};

export default Event;
