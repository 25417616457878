import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useCommunity } from "./CommunityContext";
import communityService from "services/communities";

const CommunityAdmin: React.FC = () => {

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { loading } = useCommunity();

  const handleDelete = async () => {
    try {
      await communityService.remove(id);
      navigate("/communities");
    } catch (error) {
      console.error("Error deleting community:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Admin</Typography>
        {id && id !== "create" && (
          <>
            <Typography variant="h6" color="error" sx={{ mt: 2 }}>
              Danger Zone
            </Typography>
            <Button
              disabled={loading}
              variant="contained"
              color="error"
              onClick={handleDelete}
              sx={{ mt: 1 }}
            >
              Delete Community
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CommunityAdmin; 