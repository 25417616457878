import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";
import { ITicket } from "types/tickets";

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/tickets`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function fetch(id: string) {
  const response = await axios.get(`${API_HOST}/tickets/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function create(data: Partial<ITicket>) {
  const response = await axios.post(`${API_HOST}/tickets`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function update(id: string, data: Partial<ITicket>) {
  const response = await axios.put(`${API_HOST}/tickets/${id}`, data, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function remove(id: string) {
  const response = await axios.delete(`${API_HOST}/tickets/${id}`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

async function validate(id: string) {
  const response = await axios.get(`${API_HOST}/tickets/${id}/validate`, {
    headers: TokenHandler.getHeaders(),
  });
  return response.data;
}

const ticketsService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  validate,
};

export default ticketsService; 