import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNews } from "./NewsContext";
import newsService from "services/news";

const SettingsTab: React.FC = () => {
  const { news, setNews, loading, setLoading, error, setError, user, id } = useNews();
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!news.title || !news?.defaultImage) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        news.createdBy = user;
        news.createdDate = new Date().toISOString();

        const newsWithoutSeedImage = {
          ...news,
          updatedBy: user?.uid,
          updatedAt: new Date().toISOString(),
        };
        delete newsWithoutSeedImage.seedImageURL;
        delete newsWithoutSeedImage.id;
        delete newsWithoutSeedImage._id;
        await newsService.create(newsWithoutSeedImage);
      } else {
        news.updatedDate = new Date().toISOString();
        news.updatedBy = user?.uid;
        const newsWithoutSeedImage = { ...news };
        delete newsWithoutSeedImage.seedImageURL;
        await newsService.update(news?.id, newsWithoutSeedImage);
      }
      navigate("/news");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newsService.remove(id);
      navigate("/news");
    } catch (error) {
      console.error("Error deleting ad:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>

      {news?.id && (
        <Grid item xs={12}>
          <Typography variant="h4" color="error">
            Danger Zone
          </Typography>
          <Button
            disabled={loading}
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SettingsTab;
