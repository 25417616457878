import { FC, useState } from 'react';
import TicketOptionsDialog from './TicketOptionsDialog';
import { TicketType, TicketTypeOption } from 'types/events.d';

interface EventTicketTypesProps {
  ticketTypes: TicketType[];
  eventId: string;
  organizationId: string;
  onTicketTypesChange: (ticketTypes: TicketType[]) => void;
}

const EventTicketTypes: FC<EventTicketTypesProps> = ({
  ticketTypes = [],
  eventId,
  organizationId,
  onTicketTypesChange
}) => {
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const [currentTicketTypeIndex, setCurrentTicketTypeIndex] = useState<number | null>(null);

  const handleAddTicketType = () => {
    const newTicketType: TicketType = {
      name: '',
      description: '',
      price: 0,
      available: 0,
      sortOrder: (ticketTypes?.length || 0) + 1,
      event: eventId,
      organization: organizationId,
      includedGuests: 1,
      ticketTypeOptions: []
    };
    onTicketTypesChange([...(ticketTypes || []), newTicketType]);
  };

  const handleUpdateTicket = (index: number, updatedTicket: Partial<TicketType>) => {
    const updatedTickets = [...ticketTypes];
    updatedTickets[index] = { ...updatedTickets[index], ...updatedTicket };
    onTicketTypesChange(updatedTickets);
  };

  const handleDeleteTicket = (index: number) => {
    const updatedTickets = ticketTypes?.filter((_, i) => i !== index);
    onTicketTypesChange(updatedTickets);
  };

  const openOptionsDialog = (index: number) => {
    setCurrentTicketTypeIndex(index);
    setOptionsDialogOpen(true);
  };

  const closeOptionsDialog = () => {
    setOptionsDialogOpen(false);
    setCurrentTicketTypeIndex(null);
  };

  const handleAddOption = (newOption: TicketTypeOption) => {
    if (currentTicketTypeIndex === null) return;

    const updatedTickets = [...ticketTypes];
    const currentTicket = { ...updatedTickets[currentTicketTypeIndex] };

    currentTicket.ticketTypeOptions = [
      ...(currentTicket.ticketTypeOptions || []),
      newOption
    ];

    updatedTickets[currentTicketTypeIndex] = currentTicket;
    onTicketTypesChange(updatedTickets);
  };

  const handleUpdateOption = (optionIndex: number, updatedOption: TicketTypeOption) => {
    if (currentTicketTypeIndex === null) return;

    const updatedTickets = [...ticketTypes];
    const currentTicket = { ...updatedTickets[currentTicketTypeIndex] };

    if (currentTicket.ticketTypeOptions) {
      const updatedOptions = [...currentTicket.ticketTypeOptions];
      updatedOptions[optionIndex] = updatedOption;
      currentTicket.ticketTypeOptions = updatedOptions;

      updatedTickets[currentTicketTypeIndex] = currentTicket;
      onTicketTypesChange(updatedTickets);
    }
  };

  const handleDeleteOption = (optionIndex: number) => {
    if (currentTicketTypeIndex === null) return;

    const updatedTickets = [...ticketTypes];
    const currentTicket = { ...updatedTickets[currentTicketTypeIndex] };

    currentTicket.ticketTypeOptions = currentTicket.ticketTypeOptions?.filter((_, i) => i !== optionIndex);

    updatedTickets[currentTicketTypeIndex] = currentTicket;
    onTicketTypesChange(updatedTickets);
  };

  return (
    <div className="mt-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Ticket Types</h2>
        <button
          onClick={handleAddTicketType}
          className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-1.5 px-3 rounded text-sm transition duration-150 ease-in-out flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          Add Ticket Type
        </button>
      </div>

      {ticketTypes?.length === 0 ? (
        <div className="p-6 text-center border border-dashed border-gray-300 rounded-lg">
          <p className="text-gray-500">No ticket types added yet. Add a ticket type to get started.</p>
        </div>
      ) : (
        <div className="space-y-3">
          {ticketTypes?.map((ticket, index) => (
            <div key={ticket._id || index} className="p-3 border border-gray-300 rounded-lg bg-white">
              <div className="flex flex-wrap items-end space-x-3">
                {/* Sort Order field */}
                <div className="w-20">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Order</label>
                  <input
                    type="number"
                    value={ticket.sortOrder || index + 1}
                    onChange={(e) => handleUpdateTicket(index, { sortOrder: Number(e.target.value) })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Name field */}
                <div className="flex-grow min-w-[150px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Ticket Name</label>
                  <input
                    type="text"
                    value={ticket.name}
                    onChange={(e) => handleUpdateTicket(index, { name: e.target.value })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Description field */}
                <div className="flex-grow min-w-[200px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <input
                    type="text"
                    value={ticket.description || ''}
                    onChange={(e) => handleUpdateTicket(index, { description: e.target.value })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Price field */}
                <div className="w-24">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
                  <input
                    type="number"
                    value={ticket.price}
                    onChange={(e) => handleUpdateTicket(index, { price: Number(e.target.value) })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Available Quantity field */}
                <div className="w-36">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Available</label>
                  <input
                    type="number"
                    value={ticket.available}
                    onChange={(e) => handleUpdateTicket(index, { available: Number(e.target.value) })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Included Guests field */}
                <div className="w-36">
                  <label className="block text-sm font-medium text-gray-700 mb-1">Included Guests</label>
                  <input
                    type="number"
                    value={ticket.includedGuests}
                    onChange={(e) => handleUpdateTicket(index, { includedGuests: Number(e.target.value) })}
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                </div>

                {/* Options button */}
                <button
                  onClick={() => openOptionsDialog(index)}
                  className="p-1.5 text-blue-600 hover:text-blue-800 focus:outline-none"
                  aria-label="Configure ticket options"
                >
                  <span className="flex items-center text-sm font-medium">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    Options ({ticket.ticketTypeOptions?.length || 0})
                  </span>
                </button>

                {/* Delete button */}
                <button
                  onClick={() => handleDeleteTicket(index)}
                  className="p-1.5 text-red-600 hover:text-red-800 focus:outline-none ml-0.5"
                  aria-label="Remove ticket type"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Ticket Options Dialog */}
      <TicketOptionsDialog
        open={optionsDialogOpen}
        onClose={closeOptionsDialog}
        ticketTypes={ticketTypes}
        currentTicketTypeIndex={currentTicketTypeIndex}
        onAddOption={handleAddOption}
        onUpdateOption={handleUpdateOption}
        onDeleteOption={handleDeleteOption}
      />
    </div>
  );
};

export default EventTicketTypes; 