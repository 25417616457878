import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTemplateContext } from "./TemplateContext";
import { ITemplate, ITemplateModelRequirement } from "types/templates";
import { TrashIcon } from "@heroicons/react/24/outline";

const DetailsTab = () => {
  const { template, setTemplate } = useTemplateContext();
  const [modelTypes, setModelTypes] = useState<string[]>([
    "event", "organization", "news", "member", "user", "location"
  ]);
  const [showAddModel, setShowAddModel] = useState(false);
  const [newModelType, setNewModelType] = useState("");
  const [newModelRequired, setNewModelRequired] = useState(true);
  const [newModelDescription, setNewModelDescription] = useState("");
  const [newModelIsMultiple, setNewModelIsMultiple] = useState(false);
  const [newModelIsRecipient, setNewModelIsRecipient] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ITemplate>({
    defaultValues: template || {},
  });

  useEffect(() => {
    if (template) {
      reset(template);
    }
  }, [template, reset]);

  const onSubmit = (data: ITemplate) => {
    setTemplate(prev => ({ ...prev, ...data } as ITemplate));
  };

  const addModelRequirement = () => {
    if (!newModelType) return;

    const newRequirement: ITemplateModelRequirement = {
      type: newModelType,
      description: newModelDescription || `${newModelType} data for template`,
      required: newModelRequired,
      isMultiple: newModelIsMultiple,
      isRecipient: newModelIsRecipient
    };

    setTemplate(prev => {
      if (!prev) return prev;

      const currentRequirements = prev.requirements || { models: {} };

      return {
        ...prev,
        requirements: {
          ...currentRequirements,
          models: {
            ...(currentRequirements.models || {}),
            [newModelType]: newRequirement
          }
        }
      };
    });

    // Reset add model form
    setNewModelType("");
    setNewModelDescription("");
    setNewModelRequired(true);
    setNewModelIsMultiple(false);
    setNewModelIsRecipient(false);
    setShowAddModel(false);
  };

  const removeModelRequirement = (modelType: string) => {
    setTemplate(prev => {
      if (!prev || !prev.requirements || !prev.requirements.models) return prev;

      const updatedModels = { ...prev.requirements.models };
      delete updatedModels[modelType];

      return {
        ...prev,
        requirements: {
          ...prev.requirements,
          models: updatedModels
        }
      };
    });
  };

  return (
    <form onChange={handleSubmit(onSubmit)} className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Name
          </label>
          <input
            id="name"
            {...register("name", { required: "Name is required" })}
            type="text"
            className={`w-full p-2 border rounded-md bg-white ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
            Type
          </label>
          <select
            id="type"
            {...register("type", { required: "Type is required" })}
            className={`w-full p-2 border rounded-md bg-white ${errors.type ? 'border-red-500' : 'border-gray-300'}`}
          >
            <option value="email">Email</option>
            <option value="sms">SMS</option>
            <option value="push">Push</option>
          </select>
          {errors.type && (
            <p className="mt-1 text-sm text-red-600">{errors.type.message}</p>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          id="description"
          {...register("description")}
          rows={3}
          className="w-full p-2 border border-gray-300 rounded-md bg-white"
        />
      </div>

      {/* Template Requirements Section */}
      <div className="border border-gray-200 rounded-md p-4 bg-gray-50">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-md font-medium text-gray-700">Data Requirements</h3>
          <button
            type="button"
            onClick={() => setShowAddModel(!showAddModel)}
            className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition"
          >
            {showAddModel ? 'Cancel' : 'Add Data Requirement'}
          </button>
        </div>

        {showAddModel && (
          <div className="bg-white p-4 rounded-md border border-gray-300 mb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
              <div>
                <label htmlFor="modelType" className="block text-sm font-medium text-gray-700 mb-1">
                  Model Type
                </label>
                <select
                  id="modelType"
                  value={newModelType}
                  onChange={(e) => setNewModelType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select a model type</option>
                  {modelTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="modelDescription" className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <input
                  id="modelDescription"
                  type="text"
                  value={newModelDescription}
                  onChange={(e) => setNewModelDescription(e.target.value)}
                  placeholder="What is this data used for?"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="flex flex-wrap gap-4 mb-3">
              <div className="flex items-center">
                <input
                  id="modelRequired"
                  type="checkbox"
                  checked={newModelRequired}
                  onChange={(e) => setNewModelRequired(e.target.checked)}
                  className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                />
                <label htmlFor="modelRequired" className="ml-2 text-sm text-gray-700">
                  Required
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="modelIsMultiple"
                  type="checkbox"
                  checked={newModelIsMultiple}
                  onChange={(e) => setNewModelIsMultiple(e.target.checked)}
                  className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                />
                <label htmlFor="modelIsMultiple" className="ml-2 text-sm text-gray-700">
                  Multiple Selections
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="modelIsRecipient"
                  type="checkbox"
                  checked={newModelIsRecipient}
                  onChange={(e) => setNewModelIsRecipient(e.target.checked)}
                  className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                />
                <label htmlFor="modelIsRecipient" className="ml-2 text-sm text-gray-700">
                  Is Recipient (varies per send)
                </label>
              </div>
            </div>

            <button
              type="button"
              onClick={addModelRequirement}
              disabled={!newModelType}
              className="px-3 py-1 bg-green-500 text-white rounded-md text-sm hover:bg-green-600 transition disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Add Requirement
            </button>
          </div>
        )}

        {/* List of current model requirements */}
        <div>
          {template?.requirements?.models && Object.keys(template.requirements.models).length > 0 ? (
            <div className="space-y-3">
              {Object.entries(template.requirements.models).map(([key, model]) => (
                <div key={key} className="flex justify-between items-center bg-white p-3 rounded-md border border-gray-200">
                  <div>
                    <div className="flex items-center space-x-2">
                      <span className="font-medium">{key}</span>
                      {model.required && <span className="text-xs bg-red-100 text-red-800 px-2 py-0.5 rounded">Required</span>}
                      {model.isMultiple && <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Multiple</span>}
                      {model.isRecipient && <span className="text-xs bg-purple-100 text-purple-800 px-2 py-0.5 rounded">Recipient</span>}
                    </div>
                    <p className="text-sm text-gray-600">{model.description}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeModelRequirement(key)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-sm italic">No data requirements defined. Add requirements to specify what data this template needs.</p>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="html" className="block text-sm font-medium text-gray-700 mb-1">
          Template Content
        </label>
        <textarea
          id="html"
          {...register("html", { required: "Template content is required" })}
          rows={16}
          className={`font-mono text-xs w-full p-2 border rounded-md bg-white ${errors.html ? 'border-red-500' : 'border-gray-300'}`}
        />
        {errors.html && (
          <p className="mt-1 text-sm text-red-600">{errors.html.message}</p>
        )}
      </div>

      {template?.type === "email" && (
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
            Plain Text Version
          </label>
          <textarea
            id="text"
            {...register("text")}
            rows={12}
            className="font-mono text-xs w-full p-2 border border-gray-300 rounded-md bg-white"
          />
        </div>
      )}
    </form>
  );
};

export default DetailsTab; 