import React from 'react';
import { IFile } from "types/files.d";
import { Checkbox, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface FileThumbnailProps {
  file: IFile;
  onImageClick: (file: IFile) => void;
  onCheckboxChange?: (file: IFile) => void;
  onDelete?: (file: IFile) => void;
  isSelected?: boolean;
  isDefaultImage?: boolean;
  isDefaultVideo?: boolean;
}

const FileThumbnail: React.FC<FileThumbnailProps> = ({
  file,
  onImageClick,
  onCheckboxChange,
  onDelete,
  isSelected,
  isDefaultImage,
  isDefaultVideo,
}) => {

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="relative aspect-square">
        {file.type?.startsWith('video/') ? (
          <video
            src={file.url}
            onClick={() => onImageClick(file)}
            className="absolute inset-0 w-full h-full object-cover cursor-pointer"
            preload="metadata"
            muted
            playsInline
            onLoadedMetadata={(e) => {
              // Seek to first frame to show thumbnail
              const video = e.target as HTMLVideoElement;
              video.currentTime = 0.1;
            }}
            onMouseEnter={(e) => {
              const video = e.target as HTMLVideoElement;
              video.play();
            }}
            onMouseLeave={(e) => {
              const video = e.target as HTMLVideoElement;
              video.pause();
            }}
            onTouchStart={(e) => {
              const video = e.target as HTMLVideoElement;
              video.play();
            }}
            onTouchEnd={(e) => {
              const video = e.target as HTMLVideoElement;
              video.pause();
            }}
          />
        ) : (
          <img
            src={file.url}
            alt={`${file.id}`}
            onClick={() => onImageClick(file)}
            className="absolute inset-0 w-full h-full object-cover cursor-pointer"
          />
        )}
      </div>
      <div className="flex justify-between items-center px-2 py-1">
        {onCheckboxChange && (
          <Checkbox
            checked={isSelected}
            onChange={() => onCheckboxChange(file)}
          />
        )}
        {(isDefaultImage || isDefaultVideo) && (
          <Typography variant="caption" color="text.secondary">
            {isDefaultImage ? 'Default Image' : 'Default Video'}
          </Typography>
        )}
        {onDelete && (
          <IconButton onClick={() => onDelete(file)} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default FileThumbnail; 