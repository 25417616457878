import React from "react";
import { Box, Button, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { Routes, Route, Link, useLocation, Navigate, useParams, useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import DetailsTab from "./DetailsTab";
import PreviewTab from "./PreviewTab";
import { TemplateProvider, useTemplateContext } from "./TemplateContext";
import LoadingOverlay from "components/LoadingOverlay";

const TemplateContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { template, loading, saveTemplate } = useTemplateContext();
  const currentTab = location.pathname.split('/').pop() || 'details';
  const isNew = !id || id === 'new';

  const tabPaths = {
    details: 'details',
    preview: 'preview'
  };

  const handleSave = async () => {
    await saveTemplate();
    navigate("/templates");
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/templates")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">{isNew ? "Create Template" : "Edit Template"}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={loading}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[currentTab] || 'details'}>
              <Tab
                label="Details"
                value="details"
                component={Link}
                to={tabPaths.details}
              />
              <Tab
                label="Preview"
                value="preview"
                component={Link}
                to={tabPaths.preview}
              />
            </Tabs>
          </Box>

          <Routes>
            <Route path="details" element={<DetailsTab />} />
            <Route path="preview" element={<PreviewTab />} />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const Template: React.FC = () => {
  return (
    <TemplateProvider>
      <TemplateContent />
    </TemplateProvider>
  );
};

export default Template; 