import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "context/Auth";
import dataSourcesService from "services/dataSources";
import { useForm, FormProvider, UseFormReturn } from "react-hook-form";
import { IDataSource } from "types/dataSource.d";

type PartialDataSource = Partial<IDataSource> & {
  type: 'website' | 'instagram' | 'facebook' | 'twitter' | 'rss' | 'json';
  dataType: 'events' | 'news' | 'both';
  url: string;
  prompt?: string;
  scrapingInterval: 'hourly' | 'every-240-minutes' | 'twice-daily' | 'daily' | 'weekly' | 'monthly';
  active: boolean;
  parentType?: 'location' | 'community' | 'artist' | 'author' | 'none' | 'group' | undefined | null;
  parentId?: string;
  author?: string;
};

interface DataSourceContextType {
  dataSource: any;
  setDataSource: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  id: string | undefined;
  deleteDataSource: () => Promise<boolean>;
  formMethods: UseFormReturn<PartialDataSource>;
  handleSubmit: (formData: PartialDataSource, e: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
}

const DataSourceContext = createContext<DataSourceContextType | undefined>(undefined);

interface DataSourceProviderProps {
  id?: string;
  children: React.ReactNode;
  onComplete?: () => void;
  defaultValues?: PartialDataSource;
}

export const DataSourceProvider: React.FC<DataSourceProviderProps> = (props) => {
  const { children, id, onComplete, defaultValues = {} } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataSource, setDataSource] = useState<any>({});
  const { user } = useAuth();
  const formMethods = useForm<PartialDataSource>({ defaultValues });

  useEffect(() => {
    const fetchDataSource = async () => {
      try {
        setLoading(true);
        if (id && id !== "create") {
          const data = await dataSourcesService.fetch(id);
          setDataSource(data);
          formMethods.reset(data);
        }
      } catch (e) {
        console.error("Error fetching data source:", e);
        setError("Error fetching data source");
      } finally {
        setLoading(false);
      }
    };

    if (id && id !== "create") {
      fetchDataSource();
    }
  }, [id, formMethods]);

  const deleteDataSource = async () => {
    try {
      setLoading(true);
      await dataSourcesService.remove(id);
      return true;
    } catch (error: any) {
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData: PartialDataSource, e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    console.log("handleSubmit", formData);
    try {
      setLoading(true);
      setError("");
      const { parentType, parentId, author } = formData;

      const updatedDataSource = {
        type: formData.type,
        dataType: formData.dataType,
        url: formData.url,
        prompt: formData.prompt,
        scrapingInterval: formData.scrapingInterval,
        ...(parentType && { parentType }),
        ...(parentId && { parentId }),
        ...(author && { author }),
      }

      if (!id || id === "create") {
        await dataSourcesService.create(updatedDataSource);
      } else {
        await dataSourcesService.update(id, updatedDataSource);
      }
      formMethods.reset({ ...defaultValues });
      onComplete?.();
      return true;
    } catch (error) {
      console.error("Error saving data source:", error);
      setError('Failed to save data source');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error: any) => {
    console.error("Error saving data source:", error);
    setError('Failed to save data source');
  };

  return (
    <DataSourceContext.Provider
      value={{
        dataSource,
        setDataSource,
        loading,
        setLoading,
        error,
        setError,
        user,
        id,
        deleteDataSource,
        formMethods,
        handleSubmit,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit, handleError)}>
          {children}
        </form>
      </FormProvider>
    </DataSourceContext.Provider>
  );
};

export const useDataSource = () => {
  const context = useContext(DataSourceContext);
  if (context === undefined) {
    throw new Error("useDataSource must be used within a DataSourceProvider");
  }
  return context;
}; 