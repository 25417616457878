import React, { useState } from "react";
import { Grid, Paper, Typography, Button, Box } from "@mui/material";
import { useDataSource } from "../../context/DataSource";
import LoadingOverlay from "components/LoadingOverlay";
import dataSourcesService from "services/dataSources";
import DataSourceResults from "components/DataSourceResults/DataSourceResults";

const DataSourceRun: React.FC = () => {
  const { id, dataSource } = useDataSource();
  const [running, setRunning] = useState(false);
  const [results, setResults] = useState<any>(null);

  const handleRun = async () => {
    if (id) {
      try {
        setRunning(true);
        const { message, results } = await dataSourcesService.run(id);
        alert(message);
        console.log(results);
        setResults(results);
      } catch (error) {
        console.error("Error running data source:", error);
      } finally {
        setRunning(false);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Run Data Source</Typography>
            <Button
              onClick={handleRun}
              variant="contained"
              color="primary"
              disabled={running || !id || id === "create"}
            >
              {running ? 'Running...' : 'Run Now'}
            </Button>
          </Box>

          {results ? (
            <DataSourceResults
              results={results}
              dataType={dataSource?.dataType}
            />
          ) : (
            <Typography variant="body1" color="textSecondary" align="center">
              Run the data source to see results
            </Typography>
          )}
        </Paper>
      </Grid>
      {running && <LoadingOverlay loading={running} />}
    </Grid>
  );
};

export default DataSourceRun; 