import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkerActivityForm from "components/WorkerActivityForm/WorkerActivityForm";
import BasicLayout from "components/Layouts/BasicLayout";
import WorkerActivityJSON from "components/WorkerActivityJSON";
import WorkerActivityDetails from "components/WorkerActivityDetails/WorkerActivityDetails";
import * as workerActivityService from "services/workerActivity";
import { WorkerActivity as WorkerActivityType } from "types/workerActivity.d";

const WorkerActivity: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [workerActivity, setWorkerActivity] = useState<WorkerActivityType | null>(null);

  useEffect(() => {
    const fetchWorkerActivity = async () => {
      try {
        if (id) {
          const data = await workerActivityService.fetch(id);
          setWorkerActivity(data);
        }
      } catch (e) {
        console.error("Error fetching worker activity:", e);
      }
    };

    fetchWorkerActivity();
  }, [id]);

  return (
    <BasicLayout>
      {workerActivity && <WorkerActivityDetails data={workerActivity} />}
      <WorkerActivityJSON />
    </BasicLayout>
  );
};

export default WorkerActivity; 