import React from "react";
import WorkerActivityList from "components/WorkerActivityList/WorkerActivityList";
import BasicLayout from "components/Layouts/BasicLayout";
import { Grid, Typography } from "@mui/material";

const WorkerActivities: React.FC = () => {
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" color="error">Failed Activities</Typography>
          <WorkerActivityList
            defaultQuery={[{ field: 'status', operator: 'eq', value: 'failed' }]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" color="error">Processing Activities</Typography>
          <WorkerActivityList
            defaultQuery={[{ field: 'status', operator: 'eq', value: 'processing' }]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Other Activities</Typography>
          <WorkerActivityList
            defaultQuery={[{ field: 'status', operator: 'nin', value: ['failed', 'processing'] }]}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default WorkerActivities; 