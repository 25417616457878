export const PERMISSIONS = {
  EVENTS: {
    CREATE: 'events.create',
    READ: 'events.read',
    UPDATE: 'events.update',
    DELETE: 'events.delete',
    UPLOAD: 'events.upload',
    GENERATE: 'events.generate'
  },

  ACCOUNTS: {
    CREATE: 'accounts.create',
    READ: 'accounts.read',
    UPDATE: 'accounts.update',
    DELETE: 'accounts.delete'
  },

  ADS: {
    CREATE: 'ads.create',
    READ: 'ads.read',
    UPDATE: 'ads.update',
    DELETE: 'ads.delete',
    UPLOAD: 'ads.upload'
  },

  API_KEYS: {
    CREATE: 'apiKeys.create',
    READ: 'apiKeys.read',
    UPDATE: 'apiKeys.update',
    DELETE: 'apiKeys.delete',
    GENERATE: 'apiKeys.generate'
  },

  AUDIENCE: {
    CREATE: 'audience.create',
    READ: 'audience.read',
    UPDATE: 'audience.update',
    DELETE: 'audience.delete',
    UPLOAD: 'audience.upload'
  },

  AUTHORS: {
    CREATE: 'authors.create',
    READ: 'authors.read',
    UPDATE: 'authors.update',
    DELETE: 'authors.delete',
    UPLOAD: 'authors.upload'
  },

  CATEGORIES: {
    CREATE: 'categories.create',
    READ: 'categories.read',
    UPDATE: 'categories.update',
    DELETE: 'categories.delete',
    UPLOAD: 'categories.upload'
  },

  CITIES: {
    CREATE: 'cities.create',
    READ: 'cities.read',
    UPDATE: 'cities.update',
    DELETE: 'cities.delete',
    UPLOAD: 'cities.upload'
  },

  COMMUNITIES: {
    CREATE: 'communities.create',
    READ: 'communities.read',
    UPDATE: 'communities.update',
    DELETE: 'communities.delete'
  },

  DATA_SOURCES: {
    CREATE: 'dataSources.create',
    READ: 'dataSources.read',
    UPDATE: 'dataSources.update',
    DELETE: 'dataSources.delete',
    RUN: 'dataSources.run'
  },

  FILES: {
    UPLOAD: 'files.upload',
    CREATE: 'files.create',
    READ: 'files.read',
    UPDATE: 'files.update',
    DELETE: 'files.delete'
  },

  FOLDERS: {
    CREATE: 'folders.create',
    READ: 'folders.read',
    UPDATE: 'folders.update',
    DELETE: 'folders.delete'
  },

  FORMS: {
    CREATE: 'forms.create',
    READ: 'forms.read',
    UPDATE: 'forms.update',
    DELETE: 'forms.delete'
  },

  GROUPS: {
    CREATE: 'groups.create',
    READ: 'groups.read',
    UPDATE: 'groups.update',
    DELETE: 'groups.delete'
  },

  INVITATIONS: {
    CREATE: 'invitations.create',
    READ: 'invitations.read',
    UPDATE: 'invitations.update',
    DELETE: 'invitations.delete'
  },

  ITINERARIES: {
    CREATE: 'itineraries.create',
    READ: 'itineraries.read',
    UPDATE: 'itineraries.update',
    DELETE: 'itineraries.delete'
  },

  LOCATIONS: {
    CREATE: 'locations.create',
    READ: 'locations.read',
    UPDATE: 'locations.update',
    DELETE: 'locations.delete'
  },

  MEMBERS: {
    CREATE: 'members.create',
    READ: 'members.read',
    UPDATE: 'members.update',
    DELETE: 'members.delete'
  },

  NEWS: {
    CREATE: 'news.create',
    READ: 'news.read',
    UPDATE: 'news.update',
    DELETE: 'news.delete',
    UPLOAD: 'news.upload',
    GENERATE: 'news.generate',
  },

  NEWSLETTERS: {
    CREATE: 'newsletters.create',
    READ: 'newsletters.read',
    UPDATE: 'newsletters.update',
    DELETE: 'newsletters.delete'
  },

  NOTIFICATIONS: {
    CREATE: 'notifications.create',
    READ: 'notifications.read',
    UPDATE: 'notifications.update',
    DELETE: 'notifications.delete'
  },

  OPENAI: {
    READ: 'openai.read',
    COMPLETION: 'openai.completion',
    IMAGE: 'openai.image',
    ANALYZE: 'openai.analyze',
    TRANSCRIBE: 'openai.transcribe',
    GENERATE: 'openai.generate'
  },

  ORGANIZATIONS: {
    CREATE: 'organizations.create',
    READ: 'organizations.read',
    UPDATE: 'organizations.update',
    DELETE: 'organizations.delete'
  },

  PHONE_NUMBERS: {
    CREATE: 'phoneNumbers.create',
    READ: 'phoneNumbers.read',
    UPDATE: 'phoneNumbers.update',
    DELETE: 'phoneNumbers.delete'
  },

  PROMOTIONS: {
    CREATE: 'promotions.create',
    READ: 'promotions.read',
    UPDATE: 'promotions.update',
    DELETE: 'promotions.delete'
  },

  QRCODES: {
    CREATE: 'qrcodes.create',
    READ: 'qrcodes.read',
    UPDATE: 'qrcodes.update',
    DELETE: 'qrcodes.delete'
  },

  QUEUE: {
    READ: 'queue.read',
    RETRY: 'queue.retry'
  },

  ROLES: {
    CREATE: 'roles.create',
    READ: 'roles.read',
    UPDATE: 'roles.update',
    DELETE: 'roles.delete'
  },

  RSVP: {
    CREATE: 'rsvp.create',
    READ: 'rsvp.read',
    UPDATE: 'rsvp.update',
    DELETE: 'rsvp.delete'
  },

  SENDGRID: {
    READ: 'sendgrid.read',
    SEND: 'sendgrid.send',
    SUBUSERS: {
      DOMAINS: 'sendgrid.subusers.domains',
      CREATE: 'sendgrid.subusers.create'
    },
    DOMAINS: {
      AUTHENTICATE: 'sendgrid.domains.authenticate',
      DELETE: 'sendgrid.domains.delete',
      VALIDATE: 'sendgrid.domains.validate'
    }
  },

  STATS: {
    READ: 'stats.read'
  },

  STRIPE: {
    ACCOUNTS: {
      CREATE: 'stripe.accounts.create',
      READ: 'stripe.accounts.read',
      UPDATE: 'stripe.accounts.update',
      DELETE: 'stripe.accounts.delete'
    },
    BANK_ACCOUNTS: {
      CREATE: 'stripe.bankAccounts.create',
      READ: 'stripe.bankAccounts.read',
      UPDATE: 'stripe.bankAccounts.update',
      DELETE: 'stripe.bankAccounts.delete'
    },
    DISPUTES: {
      CREATE: 'stripe.disputes.create',
      READ: 'stripe.disputes.read',
      UPDATE: 'stripe.disputes.update',
      DELETE: 'stripe.disputes.delete'
    },
    READ: 'stripe.read',
    CREATE: 'stripe.create',
    UPDATE: 'stripe.update',
    DELETE: 'stripe.delete',
    PAYMENTS: {
      CREATE: 'stripe.payments.create',
      READ: 'stripe.payments.read',
      UPDATE: 'stripe.payments.update',
      DELETE: 'stripe.payments.delete'
    },
    PAYOUTS: {
      CREATE: 'stripe.payouts.create',
      READ: 'stripe.payouts.read',
      UPDATE: 'stripe.payouts.update',
      DELETE: 'stripe.payouts.delete'
    },
    PLANS: {
      READ: 'stripe.plans.read'
    },
    SUBSCRIPTIONS: {
      CREATE: 'stripe.subscriptions.create',
      CANCEL: 'stripe.subscriptions.cancel',
      READ: 'stripe.subscriptions.read',
      UPDATE: 'stripe.subscriptions.update'
    },
    INVOICES: {
      READ: 'stripe.invoices.read'
    }
  },

  TAGS: {
    CREATE: 'tags.create',
    READ: 'tags.read',
    UPDATE: 'tags.update',
    DELETE: 'tags.delete',
    UPLOAD: 'tags.upload'
  },

  TICKETS: {
    CREATE: 'tickets.create',
    READ: 'tickets.read',
    UPDATE: 'tickets.update',
    DELETE: 'tickets.delete',
    VALIDATE: 'tickets.validate',
    REGENERATE: 'tickets.regenerate'
  },

  USERS: {
    CREATE: 'users.create',
    READ: 'users.read',
    UPDATE: 'users.update',
    DELETE: 'users.delete'
  },

  UTILS: {
    TRANSCRIBE_VIDEO: 'utils.transcribe-video',
    EVENT_OBJECTS_FROM_JSON: 'utils.event-objects-from-json',
    EVENT_OBJECTS_FROM_TEXT: 'utils.event-objects-from-text',
    EVENT_OBJECTS_FROM_IMAGE: 'utils.event-objects-from-image'
  },

  WORKER_ACTIVITY: {
    READ: 'workerActivity.read',
    READ_STATS: 'workerActivity.read-stats'
  },

};