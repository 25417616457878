import React from 'react';
import { DateTime } from 'luxon';
import { WorkerActivity } from 'types/workerActivity.d';

interface WorkerActivityDetailsProps {
  data: WorkerActivity;
}

const WorkerActivityDetails: React.FC<WorkerActivityDetailsProps> = ({ data }) => {
  const formatDate = (dateString: string) => {
    return DateTime.fromISO(dateString).toFormat('MMM d, yyyy HH:mm:ss');
  };

  const formatDuration = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
  };

  const formatBytes = (bytes: number) => {
    const mb = (bytes / (1024 * 1024)).toFixed(2);
    return `${mb} MB`;
  };

  return (
    <div className="space-y-8 p-6 bg-white rounded-lg shadow">
      {/* Header Information */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-gray-500">Status</h3>
          <p className={`mt-1 text-lg font-semibold ${data.status === 'completed' ? 'text-green-600' :
            data.status === 'failed' ? 'text-red-600' : 'text-blue-600'
            }`}>
            {data.status}
          </p>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-gray-500">Type</h3>
          <p className="mt-1 text-lg font-semibold">{data.type}</p>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-gray-500">Duration</h3>
          <p className="mt-1 text-lg font-semibold">{formatDuration(data.duration || 0)}</p>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-gray-500">Stage</h3>
          <p className="mt-1 text-lg font-semibold">{data.stage}</p>
        </div>
      </div>

      {/* Data Source Information */}
      {data.dataSource && (
        <div className="bg-gray-50 p-6 rounded-lg">
          <h2 className="text-lg font-semibold mb-4">Data Source</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Basic Information</h3>
              <div className="space-y-2">
                <div>
                  <span className="text-sm font-medium text-gray-500">Active:</span>
                  <span className="ml-2 text-sm text-gray-900">{data.dataSource.active ? 'Yes' : 'No'}</span>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-500">Type:</span>
                  <span className="ml-2 text-sm text-gray-900">{data.dataSource.type}</span>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-500">URL:</span>
                  <a
                    href={data.dataSource.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 text-sm text-blue-600 hover:text-blue-800"
                  >
                    {data.dataSource.url}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Configuration</h3>
              <div className="space-y-2">
                <div>
                  <span className="text-sm font-medium text-gray-500">Data Type:</span>
                  <span className="ml-2 text-sm text-gray-900">{data.dataSource.dataType}</span>
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-500">Scraping Interval:</span>
                  <span className="ml-2 text-sm text-gray-900">
                    {data.dataSource.scrapingInterval} minutes
                  </span>
                </div>
                {data.dataSource.organization && (
                  <div>
                    <span className="text-sm font-medium text-gray-500">Last Completed At:</span>
                    <span className="ml-2 text-sm text-gray-900">{data.dataSource.lastCompletedAt ? DateTime.fromISO(data.dataSource.lastCompletedAt).toFormat('MMM d, yyyy hh:mm:ss a') : 'N/A'}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Items Processing Stats */}
      <div className="bg-gray-50 p-6 rounded-lg">
        <h2 className="text-lg font-semibold mb-4">Processing Statistics</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Items Found</h3>
            <p className="mt-1 text-lg font-semibold">{data.itemsFound}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Processed</h3>
            <p className="mt-1 text-lg font-semibold">{data.itemsProcessed}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Saved</h3>
            <p className="mt-1 text-lg font-semibold">{data.itemsSaved}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Failed</h3>
            <p className="mt-1 text-lg font-semibold text-red-600">{data.itemsFailed}</p>
          </div>
        </div>
      </div>

      {/* Processing Time */}
      {data.metadata?.processingTime && (
        <div className="bg-gray-50 p-6 rounded-lg">
          <h2 className="text-lg font-semibold mb-4">Processing Time Breakdown</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stage</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time (ms)</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(data.metadata.processingTime).map(([stage, time]) => (
                  <tr key={stage}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stage}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{time.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Memory Usage */}
      {data.metadata?.memory && (
        <div className="bg-gray-50 p-6 rounded-lg">
          <h2 className="text-lg font-semibold mb-4">Memory Usage</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <h3 className="text-sm font-medium text-gray-500">Heap Used</h3>
              <p className="mt-1 text-lg font-semibold">{formatBytes(data.metadata.memory.heapUsed)}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Heap Total</h3>
              <p className="mt-1 text-lg font-semibold">{formatBytes(data.metadata.memory.heapTotal)}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">External</h3>
              <p className="mt-1 text-lg font-semibold">{formatBytes(data.metadata.memory.external)}</p>
            </div>
          </div>
        </div>
      )}

      {/* Info Messages */}
      {data.info && data.info.length > 0 && (
        <div className="bg-gray-50 p-6 rounded-lg">
          <h2 className="text-lg font-semibold mb-4">Process Log</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metadata</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.info.map((info) => (
                  <tr key={info._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(info.timestamp)}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">{info.message}</td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {info.metadata && (
                        <pre className="text-xs">{JSON.stringify(info.metadata, null, 2)}</pre>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Timestamps */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-sm font-medium text-gray-500">Started At</h3>
          <p className="mt-1 text-sm">{data.startedAt && formatDate(data.startedAt)}</p>
        </div>
        {data.completedAt && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-500">Completed At</h3>
            <p className="mt-1 text-sm">{formatDate(data.completedAt)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerActivityDetails; 