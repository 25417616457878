import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
} from '@mui/material';
import locationsService from '../services/locations';
import communitiesService from '../services/communities';
import tagsService from '../services/tags';
import authorsService from '../services/authors';
import { IPaginationModal, IQueryObject } from '../types/App';

interface Suggestion {
  id: string;
  type: 'location' | 'community' | 'tag' | 'author';
  label: string;
}

interface MetadataValue {
  type: string;
  id: string;
  label: string;
}

interface EventMetadataFieldProps {
  value: string;
  onChange: (text: string, metadata: Record<string, MetadataValue[]>) => void;
  placeholder?: string;
}

const TRIGGERS = {
  '@location': 'location',
  '@community': 'community',
  '@tag': 'tag',
  '@author': 'author',
};

const SERVICES = {
  location: locationsService,
  community: communitiesService,
  tag: tagsService,
  author: authorsService,
};

export default function EventMetadataField({
  value,
  onChange,
  placeholder,
}: EventMetadataFieldProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [triggerPos, setTriggerPos] = useState<number>(-1);
  const [currentType, setCurrentType] = useState<string>('');
  const [metadata, setMetadata] = useState<Record<string, MetadataValue[]>>({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const searchTimeout = useRef<NodeJS.Timeout>();

  const exitSearchMode = () => {
    setIsSearchMode(false);
    setSelectedTrigger('');
    setAnchorEl(null);
    setSuggestions([]);
    setSearchTerm('');
  };

  const fetchSuggestions = async (type: string, searchQuery: string) => {
    if (!SERVICES[type as keyof typeof SERVICES]) return;

    setLoading(true);
    try {
      const service = SERVICES[type as keyof typeof SERVICES];

      const paginationModel: IPaginationModal = {
        query: searchQuery ? [{
          field: 'name',
          operator: 'search',
          value: searchQuery
        }] : undefined
      };

      const response = await service.fetchAll(paginationModel);
      const { data } = response.items || response;

      console.log('data', data);
      const suggestions: Suggestion[] = data?.map((item: any) => ({
        id: item._id,
        type: type as Suggestion['type'],
        label: item.name || item.title || item.label,
      }));

      setSuggestions(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const cursorPosition = event.target.selectionStart || 0;

    // If we're in search mode, check if @ was removed
    if (isSearchMode && selectedTrigger) {
      // Check if the @ at triggerPos was removed
      if (newValue.length <= triggerPos || newValue[triggerPos] !== '@') {
        exitSearchMode();
        onChange(newValue, metadata);
        return;
      }

      const textAfterAt = newValue.slice(triggerPos + 1).trim(); // +1 to skip the @
      setSearchTerm(textAfterAt);

      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
      searchTimeout.current = setTimeout(() => {
        fetchSuggestions(TRIGGERS[selectedTrigger as keyof typeof TRIGGERS], textAfterAt);
      }, 300);

      onChange(newValue, metadata);
      return;
    }

    // If not in search mode, look for trigger
    const lastAtPos = newValue.lastIndexOf('@', cursorPosition);
    if (lastAtPos !== -1) {
      const textAfterAt = newValue.slice(lastAtPos, cursorPosition);
      const matchingTrigger = Object.keys(TRIGGERS).find(trigger =>
        trigger.startsWith(textAfterAt)
      );

      if (matchingTrigger) {
        // Enter search mode if we have an exact trigger match
        if (matchingTrigger === textAfterAt) {
          const afterTrigger = newValue.slice(lastAtPos + matchingTrigger.length).trim();
          setIsSearchMode(true);
          setSelectedTrigger(matchingTrigger);
          setTriggerPos(lastAtPos);
          setAnchorEl(event.target);
          const type = TRIGGERS[matchingTrigger as keyof typeof TRIGGERS];
          setCurrentType(type);

          // Replace the trigger with just @ and keep any text after
          const beforeTrigger = newValue.slice(0, lastAtPos);
          const newValueWithAt = `${beforeTrigger}@${afterTrigger}`;
          onChange(newValueWithAt, metadata);

          // Move cursor after the @
          setTimeout(() => {
            if (inputRef.current) {
              const newCursorPosition = lastAtPos + 1;
              inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
          }, 0);

          // Initial search with any text that might be after the trigger
          fetchSuggestions(type, afterTrigger);
        } else {
          // Just show trigger suggestions
          setTriggerPos(lastAtPos);
          setAnchorEl(event.target);
          setSuggestions(
            Object.keys(TRIGGERS)
              .filter(t => t.startsWith(textAfterAt))
              .map(t => ({
                id: t,
                type: TRIGGERS[t as keyof typeof TRIGGERS] as Suggestion['type'],
                label: t
              }))
          );
        }
      } else if (isSearchMode) {
        // If no trigger match and we were in search mode, exit it
        exitSearchMode();
      }
    } else {
      exitSearchMode();
    }

    onChange(newValue, metadata);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' && suggestions.length > 0 && Boolean(anchorEl)) {
      event.preventDefault();
      handleSuggestionClick(suggestions[0]);
    } else if (event.key === 'Escape' && isSearchMode) {
      event.preventDefault();
      event.stopPropagation();  // Stop the event from bubbling up to the modal
      exitSearchMode();
    }
  };

  const handleSuggestionClick = (suggestion: Suggestion) => {
    if (!inputRef.current) return;

    // If clicking a trigger suggestion, enter search mode
    if (Object.keys(TRIGGERS).includes(suggestion.label)) {
      const beforeTrigger = value.slice(0, triggerPos);
      const currentTriggerText = value.slice(triggerPos, inputRef.current.selectionStart || triggerPos);
      const afterPartialTrigger = value.slice(triggerPos + currentTriggerText.length);
      // Keep only the @ symbol
      const newValue = `${beforeTrigger}@${afterPartialTrigger}`;

      setIsSearchMode(true);
      setSelectedTrigger(suggestion.label);
      const type = TRIGGERS[suggestion.label as keyof typeof TRIGGERS];
      setCurrentType(type);

      // Update the input value to show just @
      onChange(newValue, metadata);

      // Move cursor after the @
      setTimeout(() => {
        if (inputRef.current) {
          const newCursorPosition = triggerPos + 1; // +1 for the @
          inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      }, 0);

      // Search with any text that was after the partial trigger
      const searchText = afterPartialTrigger.trim();
      if (searchText) {
        fetchSuggestions(type, searchText);
      } else {
        fetchSuggestions(type, '');
      }
      return;
    }

    const beforeTrigger = value.slice(0, triggerPos);
    const afterTrigger = value.slice(inputRef.current.selectionStart || 0);
    const newText = `${beforeTrigger}[${suggestion.label}]${afterTrigger}`;

    // Update metadata
    const newMetadata = { ...metadata };
    if (!newMetadata[suggestion.type]) {
      newMetadata[suggestion.type] = [];
    }
    newMetadata[suggestion.type].push({
      type: suggestion.type,
      id: suggestion.id,
      label: suggestion.label,
    });

    setMetadata(newMetadata);
    onChange(newText, newMetadata);
    exitSearchMode();

    // Focus back on the input and move cursor to the end of the inserted text
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const newCursorPosition = beforeTrigger.length + suggestion.label.length + 2; // +2 for the brackets
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  return (
    <Box position="relative">
      <TextField
        inputRef={inputRef}
        multiline
        rows={4}
        fullWidth
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        variant="outlined"
      />
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: '200px', zIndex: 1300 }}
      >
        <Paper elevation={3}>
          <List>
            {loading ? (
              <ListItem>
                <Box display="flex" justifyContent="center" width="100%" py={1}>
                  <CircularProgress size={24} />
                </Box>
              </ListItem>
            ) : suggestions.length > 0 ? (
              suggestions.map((suggestion) => (
                <ListItem
                  key={suggestion.id}
                  button
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <ListItemText primary={suggestion.label} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No results found" />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
} 