import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  DialogTitle,
  debounce,
  Typography,
} from "@mui/material";

import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";

import Image from "components/Images/Image";
import TipTap from "components/TipTap";
import { INews } from "types/news.d";
import { StatusEnum } from "types/events.d";
import PreviewArticleModal from "pages/News/PreviewArticleModal";

interface NewsEditDialogProps {
  open?: boolean;
  news?: INews;
  onClose?: () => void;
  onSave?: (news: Partial<INews>) => void;
  headerText?: string;
}

const BLANK_NEWS: Partial<INews> = {
  title: undefined,
  status: undefined,
  tagIds: undefined,
  tags: undefined,
  updatedDate: undefined,
  updatedBy: undefined,
  featured: undefined,
  trending: undefined,
  defaultImage: undefined,
  defaultVideo: undefined,
};

const NewsEditDialog = (props: NewsEditDialogProps) => {
  const { news: defaultNews, open, onClose, onSave, headerText } = props;
  const [news, setNews] = useState(defaultNews || BLANK_NEWS);

  useEffect(() => {
    setNews(defaultNews || BLANK_NEWS);
  }, [defaultNews]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{headerText || "Edit Event"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Image
                src={news?.defaultImage?.url}
                onAccept={(image) => {
                  setNews((prevNews: any) => ({
                    ...prevNews,
                    defaultImage: {
                      url: image.url,
                      createdDate: new Date().toISOString()
                    }
                  }));
                }}
                folderPath="News"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Video</FormLabel>
              <VideoSelect
                value={news?.defaultVideo || null}
                onChange={(video) => setNews((pe: any) => ({ ...pe, defaultVideo: video }))}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Tags</FormLabel>
              <TagMultiSelect
                type={["news"]}
                selectedTags={news?.tags}
                setSelectedTags={(tags) => setNews((pe: any) => ({ ...pe, tags }))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <FormLabel>Title</FormLabel>
              <TextField
                value={news?.title}
                id="title"
                variant="outlined"
                onChange={(e) => setNews((pe: any) => ({ ...pe, title: e.target.value }))}
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <FormLabel>Subtitle</FormLabel>
              <TextField
                value={news?.subtitle}
                id="subtitle"
                variant="outlined"
                onChange={(e) => setNews((pe: any) => ({ ...pe, subtitle: e.target.value }))}
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <FormLabel>Summary</FormLabel>
              <TextField
                id="summary"
                multiline
                rows={7}
                value={news?.summary || ""}
                onChange={(e) => setNews((prev) => ({ ...prev, summary: e.target.value }))}
              />
            </FormControl>
            <FormGroup sx={{ marginBottom: 2 }}>
              <FormLabel id="status-group-label">Publishing Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-group-label"
                name="row-radio-buttons-group"
                value={news.status}
                onChange={(e) => setNews({ ...news, status: e.target.value as StatusEnum })}
              >
                <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                <FormControlLabel
                  value={StatusEnum.Published}
                  control={<Radio />}
                  label="Published"
                />
              </RadioGroup>
            </FormGroup>
            <FormGroup>
              <FormLabel id="status-group-label">Monitization Options</FormLabel>
              <FormControlLabel
                htmlFor="featured"
                label={"Featured"}
                control={
                  <Checkbox
                    checked={news?.featured || false}
                    onChange={(e) => setNews({ ...news, featured: e.target.checked })}
                  />
                }
              />
              <FormControlLabel
                htmlFor="trending"
                label={"Trending"}
                control={
                  <Checkbox
                    checked={news?.trending || false}
                    onChange={(e) => setNews({ ...news, trending: e.target.checked })}
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} className="NewsArticle__content">
              <FormControl fullWidth>
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  marginBottom={2}
                  alignItems={"flex-end"}
                >
                  <Typography variant="h6">Content (HTML)</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TipTap
                    content={news.content}
                    onChange={debounce((value: JSON) => setNews((prev) => ({ ...prev, content: value })), 300)}
                    bucketPath="cityview-cms/news"
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 2 }}>
                  {news.content && <PreviewArticleModal news={news} />}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" size="large" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={() => onSave(news)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsEditDialog;
