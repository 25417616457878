import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Icon, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import eventService from "../../services/events";
import ApproveSwipper from "../ApproveSwipper";
import { useAuth } from "context/Auth";
import { DateTime } from "luxon";
import { calculateStringWidth } from "utils/commonFunctions";

const valid = (event: any) => {
  return !!event?.title && !!event?.location && !!event?.tags?.length;
};

const columns = (
  handleApproveEvent: (id: string) => Promise<void>,
  handleDeletePendingEvent: (id: string) => Promise<void>
) => [
    // Add a status row if all the event fields are filled out show a green checkmark else show a warning icon
    {
      field: "status",
      headerName: "",
      width: 30,
      renderCell: (params) =>
        valid(params.row) ? (
          <Icon color="success">check_circle</Icon>
        ) : (
          <Icon color="warning">warning</Icon>
        ),
    },
    {
      field: "defaultImage",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <img src={params.value?.url} style={{ width: 50 }} alt={params?.value} />
      ),
    },
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "startDate",
      headerName: "Start",
      flex: 1,
      renderCell: (params) => DateTime.fromISO(params.value, { zone: "utc" }).toFormat("MM/dd/yyyy"),
    },
    { field: "startTime", headerName: "Start Time", flex: 1, renderCell: (params) => DateTime.fromFormat(params.value, "HH:mm").toFormat("h:mma").toLowerCase() },
    { field: "eventType", headerName: "Event Type", flex: 1, renderCell: (params) => params?.value || "No Event Type" },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      renderCell: (params) => params?.value?.name || "No Location",
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 1,
      renderCell: (params) => params?.value?.map((t: any) => t.title).join(", ") || "No Tags",
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            disabled={!valid(params.row)}
            onClick={() => handleApproveEvent(params.id as string)}
          >
            <Icon style={{ color: !valid(params.row) ? "#ddd" : "green" }}>checkmark</Icon>
          </IconButton>
          <IconButton color="secondary" component={Link} to={`/events/${params.id}`}>
            <Icon style={{ color: "navy" }}>edit</Icon>
          </IconButton>
          <IconButton color="secondary" onClick={() => handleDeletePendingEvent(params.id as string)}>
            <Icon color="error">delete</Icon>
          </IconButton>
        </Box>
      ),
    },
  ];

function DraftEventsWidget() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  const { user } = useAuth();

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const events = await eventService.fetchAll({
        query: [{ field: "status", operator: "eq", value: "pending" }],
        pageSize: 1000,
      });
      setEvents(events.data);
    } catch (error) {
      console.error("Error fetching events", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const handleClose = () => {
    fetchEvents();
  };

  const handleApproveEvent = useCallback(
    async (id: string) => {
      try {
        await eventService.update(id, {
          updatedBy: user,
          updatedDate: new Date(),
          status: "published",
        });
        fetchEvents();
      } catch (error) {
        console.error("Error approving event", error);
        setError(error);
      }
    },
    [fetchEvents, user]
  );

  const handleDeletePendingEvent = useCallback(
    async (id: string) => {
      try {
        await eventService.remove(id);
        fetchEvents();
      } catch (error) {
        console.error("Error deleting event", error);
        setError(error);
      }
    },
    [fetchEvents]
  );

  const _columns = useMemo(() => {
    let tempColumns = [...columns(handleApproveEvent, handleDeletePendingEvent)];
    tempColumns = tempColumns.map((col) => ({
      ...col,
      minWidth: col.width ? col.width : calculateStringWidth(col.headerName || ""),
    }));

    return tempColumns;
  }, [handleApproveEvent, handleDeletePendingEvent]);

  return (
    <Paper style={{ padding: 20 }}>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h5">Pending Events</Typography>
        {!!events?.length && <ApproveSwipper onOpen={fetchEvents} onClose={handleClose} />}
      </Box>
      <DataGrid
        slots={{
          noRowsOverlay: () => (
            <Box py={2}>
              <Typography textAlign={"center"} variant="body1">
                No Pending Events
              </Typography>
            </Box>
          ),
        }}
        checkboxSelection={false}
        style={{ height: 400 }}
        rows={events || []}
        getRowId={(row) => row._id}
        columns={_columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Paper>
  );
}

export default DraftEventsWidget;
