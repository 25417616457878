import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  RadioGroup,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import TagMultiSelect from "components/TagMultiSelect";
import { useNewsGenerate } from "./NewsGenerateContext";
import Image from "components/Images/Image";
import { Edit } from "@mui/icons-material";
import NewsEditDialog from "components/NewsEditDialog";
import { StatusEnum } from "types/events.d";

const ReviewStep: React.FC = () => {
  const { news, setNews } = useNewsGenerate();
  const [field, setField] = useState("");
  const [value, setValue] = useState<any>(null);
  const [editing, setEditing] = useState<any>(null);

  const handleApplyAll = () => {
    if (field && value) {
      setNews((prevNews) => prevNews.map((n) => ({ ...n, [field]: value })));
      setField("");
      setValue(null);
    }
  };

  const handleSaveEdit = (editedEvent: any) => {
    if (editedEvent) {
      setNews((prevNews) =>
        prevNews.map((pe) => {
          if (pe.id === editedEvent.id) {
            return editedEvent;
          }
          return pe;
        })
      );
    }
    setEditing(null);
  };

  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <img src={params?.value?.url} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "status", headerName: "Status", width: 100, renderCell: (params: any) => <span>{params?.value?.charAt(0).toUpperCase() + params?.value.slice(1)}</span> },
    { field: "title", headerName: "Title", width: 400 },
    { field: "featured", headerName: "Featured", width: 100 },
    { field: "trending", headerName: "Trending", width: 100 },
    {
      field: "tags",
      headerName: "Tags",
      width: 400,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <TagMultiSelect
            type={["news"]}
            selectedTags={params?.value}
            setSelectedTags={(selectedTags: any) => {
              setNews((prevNews) =>
                prevNews.map((n) => {
                  if (n.id === params.row.id) {
                    return { ...n, tags: selectedTags };
                  }
                  return n;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <IconButton onClick={() => setEditing(params.row)}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className="mb-4">Apply To All</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <select
                value={field}
                onChange={(e) => {
                  setValue(null);
                  setField(e.target.value);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="">Select Field</option>
                <option value="defaultImage">Image</option>
                <option value="title">Title</option>
                <option value="subtitle">Subtitle</option>
                <option value="summary">Summary</option>
                <option value="status">Status</option>
                <option value="featured">Featured</option>
                <option value="trending">Trending</option>
                <option value="tags">Tags</option>
              </select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {field === "defaultImage" && (
              <Image
                onAccept={(image) =>
                  setNews((prevNews) => prevNews.map((n) => ({ ...n, defaultImage: image })))
                }
                folderPath="News"
                bucketPath="cityview-cms/news"
                src={news?.[0].defaultImage?.url}
                alt={news?.[0].title}
              />
            )}
            {field === "title" && (
              <FormControl fullWidth>
                <FormLabel>Title</FormLabel>
                <TextField
                  id="title"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "subtitle" && (
              <FormControl fullWidth>
                <FormLabel>Subtitle</FormLabel>
                <TextField
                  id="subtitle"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "summary" && (
              <FormControl fullWidth>
                <FormLabel>Summary</FormLabel>
                <TextField
                  id="summary"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={news?.[0].summary || value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "tags" && (
              <FormControl fullWidth>
                <FormLabel>Tags</FormLabel>
                <TagMultiSelect
                  selectedTags={value}
                  type={["news"]}
                  setSelectedTags={(selectedTags: any) => setValue(selectedTags)}
                />
              </FormControl>
            )}
            {field === "status" && (
              <FormControl fullWidth>
                <FormLabel>Status</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="status-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                  <FormControlLabel
                    value={StatusEnum.Published}
                    control={<Radio />}
                    label="Published"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {field === "featured" && (
              <FormControl fullWidth>
                <FormLabel>Featured</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="status-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {field === "trending" && (
              <FormControl fullWidth>
                <FormLabel>Trending</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="status-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyAll}
              className="mt-4"
              disabled={!field || !value}
            >
              Apply to All
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className="mb-4">Generated News</Typography>
        <Box sx={{ height: 600 }}>
          <DataGrid
            rows={news}
            columns={columns}
            getRowId={(row) => row.id}
          />
        </Box>
      </Grid>

      <NewsEditDialog
        open={Boolean(editing)}
        news={editing}
        onClose={() => setEditing(null)}
        onSave={handleSaveEdit}
      />
    </Grid>
  );
};

export default ReviewStep; 