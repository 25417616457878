import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import { IOrganization } from 'types/organizations';
import { useAuth } from 'context/Auth';

interface OrganizationSelectProps {
  value: IOrganization | null;
  onChange: (organization: IOrganization | null) => void;
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ value, onChange }) => {
  const { user } = useAuth();
  const organizations = user?.organizations.filter((org) => org?.subscription?.status === 'active') || [];

  return (
    <>
      {organizations.length >= 2 ? (
        <FormControl sx={{ minWidth: 400 }}>
          <Select
            id="organization-select"
            value={value?.id || ''}
            onChange={(event) => {
              const selectedOrg = organizations.find(org => org.id === event.target.value) || null;
              onChange(selectedOrg);
            }}
          >
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>{organizations[0]?.name}</Typography>
      )}
    </>
  );
};

export default OrganizationSelect;
