import React, { useState } from 'react';
import { IFile } from "types/files.d";
import {
  Grid,
  Card,
  CardMedia,
  CardActions,
  Checkbox,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Typography,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MediaGalleryProvider } from "components/MediaGallery/MediaGalleryContext";
import Pagination from "components/MediaGallery/Pagination";
import GalleryHeader from "components/MediaGallery/GalleryHeader";
import FolderList from "components/MediaGallery/FolderList";
import Gallery from "components/MediaGallery/Gallery";
import FileThumbnail from './FileThumbnail';
import { IEvent } from 'types/events.d';
import { ILocationData } from 'types/locations.d';
import { INews } from 'types/news.d';
import { ICommunity } from 'types/communities.d';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface ImageGalleryProps {
  files: IFile[];
  onDelete?: (file: IFile) => void;
  onBulkDelete?: (files: IFile[]) => void;
  updateFiles?: (files: IFile[]) => void;
  data: IEvent | ILocationData | INews | ICommunity;
  onSetDefaultImage?: (file: IFile) => void;
  onSetDefaultVideo?: (file: IFile) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ files, onDelete, onBulkDelete, updateFiles, data, onSetDefaultImage, onSetDefaultVideo }) => {

  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [viewingImage, setViewingImage] = useState<IFile | null>(null);
  const [openMediaGallery, setOpenMediaGallery] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleImageClick = (image: IFile) => {
    setViewingImage(image);
  };

  const handleCheckboxChange = (image: IFile) => {
    const isSelected = selectedFiles.some(img => img.id === image.id);
    if (isSelected) {
      setSelectedFiles(prev => prev.filter(img => img.id !== image.id));
    } else {
      setSelectedFiles(prev => [...prev, image]);
    }
  };

  const handleDelete = (image: IFile) => {
    onDelete && onDelete(image);
    setSelectedFiles(prev => prev.filter(img => img.id !== image.id));
  };

  const handleBulkDelete = () => {
    onBulkDelete && onBulkDelete(selectedFiles);
    setSelectedFiles([]);
  };

  const handleFileSelected = (files: IFile[]) => {
    setSelectedFiles(files);
  };

  const handleAddSelected = () => {
    updateFiles && updateFiles([...files, ...selectedFiles]);
    setSelectedFiles([]);
    setOpenMediaGallery(false);
  };

  const handleCopy = () => {
    if (viewingImage?.url) {
      navigator.clipboard.writeText(viewingImage.url);
    }
  };

  const handleSetDefault = (file: IFile) => {
    if (file.type.startsWith('image')) {
      if (data?.defaultImage?._id === file._id) {
        setError('Default image is required, please select another image to change default image');
      } else {
        onSetDefaultImage(file);
      }
    } else if (file.type.startsWith('video')) {
      if (data?.defaultVideo?._id === file._id) {
        setError('Default video is required, please select another video to change default video');
      } else {
        onSetDefaultVideo(file);
      }
    }
  };

  const handleClose = () => {
    setViewingImage(null);
    setError(null);
  };

  const filteredFiles = files
    .sort((a, b) => {
      const isADefault = a._id === data?.defaultImage?._id || a._id === data?.defaultVideo?._id;
      const isBDefault = b._id === data?.defaultImage?._id || b._id === data?.defaultVideo?._id;
      return Number(isBDefault) - Number(isADefault);
    });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        {onBulkDelete && files && files.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={selectedFiles.length === 0}
            startIcon={<DeleteIcon />}
          >
            Delete Selected ({selectedFiles.length})
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={() => setOpenMediaGallery(true)}>Add Media</Button>
      </Box>
      <Grid container spacing={2}>
        {filteredFiles.map(image => (
          <Grid item xs={12} sm={3} md={4} lg={2} key={image.id}>
            <FileThumbnail
              file={image}
              isDefaultImage={data?.defaultImage?._id === image._id}
              isDefaultVideo={data?.defaultVideo?._id === image._id}
              onImageClick={handleImageClick}
              onCheckboxChange={onBulkDelete ? handleCheckboxChange : undefined}
              onDelete={onDelete}
              isSelected={selectedFiles.some(img => img.id === image.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={viewingImage !== null}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: { height: "100vh", display: "flex", flexDirection: "row", overflow: "hidden" },
        }}
      >
        <DialogContent style={{ display: "flex", width: "100%", padding: 0, overflow: "hidden" }}>
          {/* Left Side - Full Image View */}
          <Box
            sx={{
              flex: 1,
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {viewingImage && (
              viewingImage.type.startsWith('image') ? (
                <img
                  src={viewingImage.url}
                  alt={`Viewing ${viewingImage.id}`}
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <video
                  src={viewingImage.url}
                  controls
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                  }}
                />
              )
            )}
          </Box>

          {/* Right Side - URL and Delete Button */}
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              padding: 3,
              backgroundColor: "#fff",
            }}
          >
            {viewingImage && (
              <Box>
                <Typography variant="h5">File Name</Typography>
                <Typography variant="body2" style={{ maxWidth: "100%", wordWrap: "break-word" }}>{viewingImage.name}</Typography>
              </Box>
            )}

            {viewingImage && <Box sx={{ mt: 4 }}>
              <Typography variant="h5">File URL</Typography>
              <Box
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  paddingBottom: 1,
                  borderBottom: "1px solid #ddd",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <Typography variant="body2" sx={{ display: "inline-block" }}>
                  {viewingImage.url}
                </Typography>
              </Box>
            </Box>}

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={handleCopy}
                sx={{ width: "50%" }}
              >
                Copy URL
              </Button>
            </Box>

            {viewingImage && (
              <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Checkbox
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 28,
                      marginLeft: -1,
                    },
                  }}
                  checked={data?.defaultImage?._id === viewingImage._id || data?.defaultVideo?._id === viewingImage._id}
                  onClick={(e) => {
                    handleSetDefault(viewingImage);
                    e.stopPropagation();
                  }}
                />
                <Typography variant="h5">Set as default {viewingImage.type.startsWith('image') ? 'image' : 'video'}</Typography>
              </Box>
            )}
            {error && <Typography variant="body2" style={{ color: 'red', marginTop: '8px' }}>{error}</Typography>}

            {/* Push Delete Button to Bottom */}
            <Box sx={{ flexGrow: 1 }} />

            {/* Delete Button at Bottom Right */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginRight: 2 }}
                onClick={() => handleClose()}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(viewingImage)}
              >
                DELETE
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {openMediaGallery && (
        <div className="fixed inset-0 z-[1300] flex items-center justify-center">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/50"
            onClick={() => setOpenMediaGallery(false)}
          />

          {/* Dialog */}
          <div className="relative w-full max-w-[76vw] bg-white rounded-lg shadow-xl p-4">
            {/* Content */}
            <div className="bg-gray-100 p-0 overflow-hidden rounded-lg p-4">
              {/* <div className="h-[80vh] overflow-auto">
                <MediaGallery selectionMethod="multiple" onChangeFileSelection={handleFileSelected} />
              </div> */}

              <div className="h-[80vh] overflow-auto">
                <MediaGalleryProvider onChangeFileSelection={handleFileSelected} selectionMethod="multiple">
                  <div className="flex flex-col md:flex-row h-full gap-4">
                    <div className="w-full md:w-1/4 h-full">
                      <FolderList />
                    </div>
                    <div className="w-full md:w-3/4 flex flex-col gap-2">
                      <div className="w-full">
                        <GalleryHeader />
                      </div>
                      <div className="w-full flex-1 overflow-auto rounded-md shadow-md">
                        <Gallery />
                      </div>
                      <div className="w-full">
                        <Pagination />
                      </div>
                    </div>
                  </div>
                </MediaGalleryProvider>
              </div>
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-2 p-4 bg-white border-t">
              <Button
                onClick={() => setOpenMediaGallery(false)}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddSelected}
                variant="contained"
                color="primary"
              >
                Add Selected
              </Button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ImageGallery;
