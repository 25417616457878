import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";

import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import membersService from "services/members";
import tagsService from "services/tags";

const Members: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "membershipType", headerName: "Member Type", flex: 1 },
    { field: "isActive", headerName: "Is Active?", flex: 1, renderCell: (params) => params.value ? "Yes" : "No" },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Members</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/members/create">
            <Icon>add</Icon>
            Create Member
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="members"
              defaultSortField="name"
              defaultSortDirection="desc"
              defaultQuery={[]}
              columns={columns}
              service={membersService}
              filterOptions={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
                {
                  label: "Email",
                  name: "email",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
                {
                  label: "Is Active",
                  name: "isActive",
                  type: "checkbox",
                  operator: "eq",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
                {
                  label: "Member Type",
                  name: "membershipType",
                  type: "select",
                  options: ['basic', 'premium', 'vip'].map((value) => ({
                    label: value.charAt(0).toUpperCase() + value.slice(1),
                    value,
                  })),
                  operator: "eq",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
                {
                  name: "tags",
                  label: "Tags",
                  type: "autocomplete",
                  tagType: "member",
                  service: tagsService,
                  fieldName: "title",
                  columnWidthMap: { xs: 12, sm: 6, md: 4, lg: 4 },
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Members;
