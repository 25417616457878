import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import LocationSelect from "components/LocationSelect";
import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";
import { useEventGenerate } from "./EventGenerateContext";
import Image from "components/Images/Image";
import { Edit } from "@mui/icons-material";
import EventEditDialog from "components/EventEditDialog";
import { DateTime } from "luxon";

const ReviewStep: React.FC = () => {
  const { events, setEvents } = useEventGenerate();
  const [field, setField] = useState("");
  const [value, setValue] = useState<any>(null);
  const [editing, setEditing] = useState<any>(null);

  const handleApplyAll = () => {
    if (field && value) {
      setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, [field]: value })));
      setField("");
      setValue(null);
    }
  };

  const handleSaveEdit = (editedEvent: any) => {
    if (editedEvent) {
      setEvents((prevEvents) =>
        prevEvents.map((pe) => {
          if (pe.id === editedEvent.id) {
            return editedEvent;
          }
          return pe;
        })
      );
    }
    setEditing(null);
  };

  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <img src={params?.value?.url} alt="Event" style={{ width: "50px" }} />
      ),
    },
    { field: "status", headerName: "Status", width: 100, renderCell: (params: any) => <span>{params?.value?.charAt(0).toUpperCase() + params?.value.slice(1)}</span> },
    { field: "title", headerName: "Title", width: 400 },
    {
      field: "startDate",
      headerName: "Start",
      width: 200,
      renderCell: (params: any) => {
        return <span>{DateTime.fromISO(params.value, { zone: 'utc' }).toFormat("MM/dd/yyyy HH:mm")}</span>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 300,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "link",
      headerName: "Link",
      width: 400,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 400,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <TagMultiSelect
            type={["event"]}
            selectedTags={params?.value}
            setSelectedTags={(selectedTags: any) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, tags: selectedTags };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "video",
      headerName: "Video",
      width: 300,
      renderCell: (params: any) => (
        <FormControl fullWidth>
          <VideoSelect
            value={params?.value}
            onChange={(video) => {
              setEvents((prevEvents) =>
                prevEvents.map((e) => {
                  if (e.id === params.row.id) {
                    return { ...e, video };
                  }
                  return e;
                })
              );
            }}
          />
        </FormControl>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 100,
      renderCell: (params: any) => (
        <IconButton onClick={() => setEditing(params.row)}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className="mb-4">Apply To All</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <select
                value={field}
                onChange={(e) => {
                  setValue(null);
                  setField(e.target.value);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="">Select Field</option>
                <option value="defaultImage">Image</option>
                <option value="title">Title</option>
                <option value="description">Description</option>
                <option value="start">Start</option>
                <option value="location">Location</option>
                <option value="price">Price</option>
                <option value="link">Link</option>
                <option value="video">Video</option>
                <option value="tags">Tags</option>
              </select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {field === "defaultImage" && (
              <Image
                onAccept={(image) =>
                  setEvents((prevEvents) => prevEvents.map((e) => ({ ...e, defaultImage: image })))
                }
                folderPath="Events"
                bucketPath="cityview-cms/events"
                src={events?.[0].defaultImage?.url}
                alt={events?.[0].title}
              />
            )}
            {field === "title" && (
              <FormControl fullWidth>
                <FormLabel>Title</FormLabel>
                <TextField
                  id="title"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "description" && (
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <TextField
                  id="description"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "start" && (
              <FormControl fullWidth>
                <FormLabel>Start</FormLabel>
                <TextField
                  id="start"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "location" && (
              <FormControl fullWidth>
                <FormLabel>Locations</FormLabel>
                <LocationSelect onChange={(location) => setValue(location)} />
              </FormControl>
            )}
            {field === "price" && (
              <FormControl fullWidth>
                <FormLabel>Price</FormLabel>
                <TextField
                  id="price"
                  label="Price"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "link" && (
              <FormControl fullWidth>
                <FormLabel>Link</FormLabel>
                <TextField
                  id="link"
                  label="Link"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormControl>
            )}
            {field === "video" && (
              <FormControl fullWidth>
                <FormLabel>Video</FormLabel>
                <VideoSelect onChange={(location) => setValue(location)} />
              </FormControl>
            )}
            {field === "tags" && (
              <FormControl fullWidth>
                <FormLabel>Tags</FormLabel>
                <TagMultiSelect
                  selectedTags={value}
                  setSelectedTags={(selectedTags: any) => setValue(selectedTags)}
                  type={["event"]}
                />
              </FormControl>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyAll}
              className="mt-4"
              disabled={!field || !value}
            >
              Apply to All
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className="mb-4">Generated Events</Typography>
        <Box sx={{ height: 600 }}>
          <DataGrid
            rows={events}
            columns={columns}
            getRowId={(row) => row.id}
          />
        </Box>
      </Grid>

      <EventEditDialog
        open={Boolean(editing)}
        event={editing}
        onClose={() => setEditing(null)}
        onSave={handleSaveEdit}
      />
    </Grid>
  );
};

export default ReviewStep; 