import { Grid, Box, IconButton, Typography, Button, Alert, Tabs, Tab } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useLocation, Navigate, Routes, Route, Link, useNavigate } from "react-router-dom";
import BasicLayout from "components/Layouts/BasicLayout";
import { StatusEnum } from "types/communities.d";
import LoadingOverlay from "components/LoadingOverlay";
import { useCommunity, CommunityProvider } from "./CommunityContext";
import CommunityDetails from "./CommunityDetails"
import CommunityMedia from "./CommunityMedia"
import CommunityEvents from "./CommunityEvents"
import CommunityAdmin from "./CommunityAdmin"
import CommunityDataSources from "./CommunityDataSources"
const CommunityContent: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { loading, error, saveCommunity, community } = useCommunity();
  const currentTab = location.pathname.split('/').pop() || 'details';

  const tabPaths = {
    details: 'details',
    media: 'media',
    events: 'events',
    admin: 'admin',
    'data-sources': 'data-sources'
  };

  const validCurrentTab = tabPaths[currentTab] ? currentTab : 'details';

  const handleSave = (data?: Record<string, any>) => async () => {
    const result = await saveCommunity(data);
    if (result) {
      navigate("/communities");
    }
  };

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/communities")}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">{community?.name ? "Community: " + community.name : "Community"}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave({ status: StatusEnum.Pending })}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={handleSave({ status: StatusEnum.Published })}
                disabled={loading}
              >Publish
              </Button>
            </Box>
          </Box>
        </Grid>

        {error && <Alert severity="error">{error}</Alert>}

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={tabPaths[validCurrentTab]}>
              <Tab label="Details" value="details" component={Link} to={tabPaths.details} />
              <Tab label="Events" value="events" component={Link} to={tabPaths.events} />
              <Tab label="Data Sources" value="data-sources" component={Link} to={tabPaths['data-sources']} />
              <Tab label="Media" value="media" component={Link} to={tabPaths.media} />
              <Tab label="Admin" value="admin" component={Link} to={tabPaths.admin} />
            </Tabs>
          </Box>
          <Routes>
            <Route path="details" element={<CommunityDetails />} />
            <Route path="media" element={<CommunityMedia />} />
            <Route path="events" element={<CommunityEvents />} />
            <Route path="admin" element={<CommunityAdmin />} />
            <Route path="data-sources" element={<CommunityDataSources />} />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </Grid>
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

const Community: React.FC = () => {
  return (
    <CommunityProvider>
      <CommunityContent />
    </CommunityProvider>
  );
};

export default Community;
