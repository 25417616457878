import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import contentTypeService from "services/contentTypes";
import BasicLayout from "components/Layouts/BasicLayout";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { Grid, Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/material";

const ContentTypes: React.FC = () => {

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, minWidth: 200 },
    { field: 'type', headerName: 'Type', flex: 1, minWidth: 200 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Content Types</Typography>
        </Grid>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/content-types/create">
            <Icon>add</Icon>
            Add Content Type
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="contentTypes"
              defaultSortField="title"
              defaultSortDirection="asc"
              columns={columns}
              service={contentTypeService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  )
}

export default ContentTypes;
