import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { INews } from "types/news";
import { StatusEnum } from "types/events.d";
import newsService from "services/news";
import { useAuth } from "context/Auth";

const DEFAULT_CREATE_NEWS: INews = {
  _id: "",
  slug: "",
  title: "",
  paragraphs: [],
  subtitle: "",
  tagIds: [],
  tags: [],
  prompt: "",
  createdDate: "",
  author: undefined,
  __v: 0,
  id: "",
  content: undefined,
  article: "",
  updatedDate: "",
  updatedBy: "",
  summary: "",
  featured: false,
  trending: false,
  status: StatusEnum.Published,
  defaultImage: undefined,
  defaultVideo: undefined,
  files: [],
};

interface NewsContextType {
  news: Partial<INews & { seedImageURL?: string }>;
  setNews: React.Dispatch<React.SetStateAction<Partial<INews & { seedImageURL?: string }>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  id: string | undefined;
  saveNews: (additionalProps?: Record<string, any>) => Promise<boolean>;
  deleteNews: () => Promise<boolean>;
}

const NewsContext = createContext<NewsContextType | undefined>(undefined);

export const NewsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [news, setNews] = useState<Partial<INews & { seedImageURL?: string }>>({
    ...DEFAULT_CREATE_NEWS,
  });
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const _news: INews = await newsService.fetch(id);
        if (!_news.status) {
          _news.status = StatusEnum.Published;
        }
        setNews(_news);
      } catch (e) {
        console.error("Error fetching news:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchNews();
    }
  }, [id]);

  const saveNews = async (additionalProps = {}) => {
    try {
      setLoading(true);
      if (!news.title || !news?.defaultImage) {
        throw new Error("Please fill in all fields");
      }

      const updatedNews = {
        ...news,
        ...additionalProps,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
      };

      if (id === "create") {
        updatedNews.createdBy = user;
        updatedNews.createdDate = new Date().toISOString();

        const newsWithoutSeedImage = { ...updatedNews };
        delete newsWithoutSeedImage.seedImageURL;
        delete newsWithoutSeedImage.id;
        delete newsWithoutSeedImage._id;
        await newsService.create(newsWithoutSeedImage);
      } else {
        const newsWithoutSeedImage = { ...updatedNews };
        delete newsWithoutSeedImage.seedImageURL;
        await newsService.update(news?._id, newsWithoutSeedImage);
      }
      return true;
    } catch (error: any) {
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deleteNews = async () => {
    try {
      await newsService.remove(id);
      return true;
    } catch (error: any) {
      setError(error.message);
      return false;
    }
  };

  return (
    <NewsContext.Provider
      value={{
        news,
        setNews,
        loading,
        setLoading,
        error,
        setError,
        user,
        id,
        saveNews,
        deleteNews
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNews = () => {
  const context = useContext(NewsContext);
  if (context === undefined) {
    throw new Error("useNews must be used within a NewsProvider");
  }
  return context;
}; 