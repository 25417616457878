import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import eventsService from "services/events";
import { IFile } from "types/files.d";

interface EventGenerateContextType {
  file: IFile | null;
  setFile: React.Dispatch<React.SetStateAction<IFile | null>>;
  shouldGenerateImage: boolean;
  setShouldGenerateImage: React.Dispatch<React.SetStateAction<boolean>>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  details: string;
  setDetails: React.Dispatch<React.SetStateAction<string>>;
  metadata: Record<string, any>;
  setMetadata: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
  setError: React.Dispatch<React.SetStateAction<any>>;
  events: any[];
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;
  generateEvents: () => Promise<boolean>;
  saveEvents: () => Promise<boolean>;
}

const EventGenerateContext = createContext<EventGenerateContextType | undefined>(undefined);

export const EventGenerateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState<IFile | null>(null);
  const [shouldGenerateImage, setShouldGenerateImage] = useState(true);
  const [prompt, setPrompt] = useState("");
  const [details, setDetails] = useState("");
  const [metadata, setMetadata] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [events, setEvents] = useState<any[]>([]);

  const generateEvents = async () => {
    try {
      setLoading(true);
      const response = await eventsService.generate({
        prompt,
        details,
        metadata,
        location: metadata.location?.[0]?.id,
        community: metadata.community?.[0]?.id,
        tags: metadata.tag?.map((t: any) => t.id) || [],
        author: metadata.author?.[0]?.id,
        file: file,
        generateImage: shouldGenerateImage,
      });
      setEvents(response.map((e: any) => ({ ...e, id: uuidv4(), status: "pending" })));
      return true;
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const saveEvents = async () => {
    try {
      if (events.length === 0) return false;
      setLoading(true);

      for (let e of events) {
        try {
          if (e._id) {
            await eventsService.update(e._id, {
              ...e,
              location: e.location?._id,
              status: 'published'
            });
          } else {
            await eventsService.create({
              ...e,
              location: e.location?._id,
              status: 'published'
            });
          }
          setEvents((prevEvents) =>
            prevEvents.map((pe) => {
              if (pe.id === e.id) {
                return { ...e, status: "success" };
              }
              return pe;
            })
          );
        } catch (error) {
          setEvents((prevEvents) =>
            prevEvents.map((pe) => {
              if (pe.id === e.id) {
                return { ...e, status: "failure" };
              }
              return pe;
            })
          );
        }
      }
      navigate("/events");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <EventGenerateContext.Provider
      value={{
        file,
        setFile,
        shouldGenerateImage,
        setShouldGenerateImage,
        prompt,
        setPrompt,
        details,
        setDetails,
        metadata,
        setMetadata,
        loading,
        setLoading,
        error,
        setError,
        events,
        setEvents,
        generateEvents,
        saveEvents,
      }}
    >
      {children}
    </EventGenerateContext.Provider>
  );
};

export const useEventGenerate = () => {
  const context = useContext(EventGenerateContext);
  if (context === undefined) {
    throw new Error("useEventGenerate must be used within a EventGenerateProvider");
  }
  return context;
}; 