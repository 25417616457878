import React from "react";
import QueueList from "components/QueueList/QueueList";
import BasicLayout from "components/Layouts/BasicLayout";

const Queues: React.FC = () => {
  return (
    <BasicLayout>
      <QueueList />
    </BasicLayout>
  );
};

export default Queues; 