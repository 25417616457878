import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Alert,
  TextField,
} from "@mui/material";
import EventMetadataField from "components/EventMetadataField";
import Image from "components/Images/Image";
import { useEventGenerate } from "./EventGenerateContext";
import { IFile } from "types/files.d";

const ConfigureStep: React.FC = () => {
  const {
    file,
    setFile,
    shouldGenerateImage,
    setShouldGenerateImage,
    prompt,
    setPrompt,
    details,
    setDetails,
    metadata,
    setMetadata,
  } = useEventGenerate();

  const handleImageAccept = (image: IFile) => {
    setFile(null); // Clear any existing file
    setShouldGenerateImage(false); // Disable generate image option
    setFile(image as any); // Set the new image
  };

  const handleMetadataChange = (newDetails: string, newMetadata: Record<string, any>) => {
    setDetails(newDetails);
    setMetadata(newMetadata);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" className="mb-4">Image (Optional)</Typography>
        <Box sx={{ height: '300px' }}>
          <Image
            src={file?.url}
            onAccept={handleImageAccept}
            folderPath="Events"
            context={prompt} // Pass prompt as context for AI image generation
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldGenerateImage}
              onChange={(e) => {
                setShouldGenerateImage(e.target.checked);
                // Clear the uploaded file if generate image is checked
                if (e.target.checked) {
                  setFile(null);
                }
              }}
            />
          }
          label="Generate image from prompt"
          className="mt-4"
        />
        {file && shouldGenerateImage && (
          <Alert severity="warning" className="mt-2">
            You cannot upload an image and generate one at the same time
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Typography variant="h6" className="mb-4">Prompt Context</Typography>
          <TextField
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
        </Box>
        <Box>
          <Typography variant="h6" className="mb-4">Event Details</Typography>
          <EventMetadataField
            value={details}
            onChange={handleMetadataChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfigureStep; 