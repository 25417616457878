import React from "react";
import QueueForm from "components/QueueForm/QueueForm";
import BasicLayout from "components/Layouts/BasicLayout";

const Queue: React.FC = () => {
  return (
    <BasicLayout>
      <QueueForm />
    </BasicLayout>
  );
};

export default Queue; 