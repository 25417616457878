import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ILocationData } from "types/locations.d";
import locationsService from "services/locations";
import { useAuth } from "context/Auth";

interface LocationContextType {
  location: ILocationData | undefined;
  setLocation: (location: ILocationData | undefined) => void;
  loading: boolean;
  error: string;
  refetchLocation: () => Promise<void>;
  handleSave: () => Promise<void>;
  user: any;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [location, setLocation] = useState<ILocationData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const fetchLocation = async () => {
    if (id && id !== "create") {
      try {
        setLoading(true);
        const data = await locationsService.fetch(id);
        setLocation(data);
      } catch (e) {
        console.error("Error fetching location:", e);
        setError("Error fetching location");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchLocation();
  }, [id]);

  const handleSave = async () => {
    try {
      const { events, ...restLocation } = location;
      const newLocation = {
        ...restLocation,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        status: "published",
        source: "admin",
        formattedAddress: `${location.address1}, ${location.city}, ${location.state} ${location.zip}`,
      };

      if (!newLocation.name || !newLocation.defaultImage || !newLocation.placeId) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newLocation.createdBy = user;
        newLocation.createdAt = new Date().toISOString();
        await locationsService.create(newLocation);
      } else {
        newLocation.updatedBy = user;
        newLocation.updatedAt = new Date().toISOString();
        await locationsService.update(newLocation.id, newLocation);
      }
      navigate("/locations");
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };


  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
        loading,
        error,
        refetchLocation: fetchLocation,
        handleSave,
        user,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
}; 