import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import communitiesService from "../../services/communities";
import { ICommunity } from "types/communities.d";

interface CommunitySelectProps {
  value?: ICommunity | string | number;
  onChange: (community: ICommunity) => void;
}

const CommunitySelect: React.FC<CommunitySelectProps> = ({ onChange, value }) => {
  const [communities, setCommunities] = useState<ICommunity[]>([]);

  useEffect(() => {
    const fetchCommunities = async () => {
      const communities = await communitiesService.fetchAll({ pageSize: 1000 });
      setCommunities(communities.data);
    };
    fetchCommunities();
  }, []);

  const handleSelectChange = (value: any) => {
    const selectedCommunity = communities.find((community) => community._id === value);
    onChange(selectedCommunity);
  };

  const getCurrentValue = () => {
    if (!value) return null;
    if (typeof value === 'object') return value._id;
    return value;
  };

  return (
    <Autocomplete
      value={getCurrentValue()}
      onChange={(e, value) => handleSelectChange(value)}
      options={communities?.map((community: ICommunity) => community._id)}
      getOptionLabel={(option: any) => {
        const community = communities.find((community: ICommunity) => community._id === option);
        return community ? community.name : option;
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default CommunitySelect; 