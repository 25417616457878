import React, { useEffect, lazy, Suspense } from "react";
import "./index.css";

import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Import all pages
import * as Pages from "pages";
import { Typography } from "@mui/material";

// Unprotected routes and authentication
import { useAuth } from "context/Auth";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import ProtectedRoute from "./components/ProtectedRoute";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { GlobalContextProvider } from "context/GlobalContext";
import { GlobalFilterContextProvider } from "components/PaginatedDataGrid/GlobalFilterContext";

interface RouteItem {
  type?: string;
  name?: string;
  key: string;
  route?: string;
  path?: string;
  component?: string;
  icon?: string;
  collapse?: RouteItem[];
}

interface FlattenedRoute {
  path: string;
  component: string;
  key: string;
}

interface RouteObject {
  type?: string;
  name?: string;
  key: string;
  route?: string;
  component?: string;
  icon?: React.ReactNode;
  title?: string;
  children?: {
    index?: boolean;
    element?: React.ReactNode;
    path?: string;
  }[];
  collapse?: RouteObject[];
}

const unprotectedRoutes: RouteObject[] = [
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: "Login",
  },
  {
    name: "SignUp",
    key: "sign-up",
    route: "/sign-up",
    component: "SignUp",
  },
  {
    name: "Plans",
    key: "plans",
    route: "/plans",
    component: "Plans",
  },
  {
    name: "ForgotPassword",
    key: "forgot-password",
    route: "/forgot-password",
    component: "ForgotPassword",
  },
  {
    name: "AcceptInvitation",
    key: "accept-invitation",
    route: "/accept-invitation/:invitationCode",
    component: "AcceptInvitation",
  },
];

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { user, loading, error } = useAuth();
  const pathname = useLocation().pathname;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Render unprotected routes (login, signup, etc.)
  const renderUnprotectedRoutes = () => {
    return unprotectedRoutes.map((route: any) => {
      if (route.route) {
        return <Route path={route.route} element={React.createElement(Pages[route.component as keyof typeof Pages])} key={route.key} />;
      }
      return null;
    });
  };

  // Render protected routes from user data
  const renderProtectedRoutes = () => {
    if (!user || !user.routes) return null;

    // Flatten routes including nested collapse arrays
    const flattenRoutes = (routes: RouteItem[]): FlattenedRoute[] => {
      let result: FlattenedRoute[] = [];

      routes.forEach(route => {
        // Add route if it has a route/path and component
        if (route.route || route.path) {
          result.push({
            path: route.route || route.path,
            component: route.component,
            key: route.key
          });
        }

        // Recursively flatten nested collapse routes
        if (route.collapse && Array.isArray(route.collapse)) {
          result = result.concat(flattenRoutes(route.collapse));
        }
      });

      return result;
    };

    const flatRoutes = flattenRoutes(user.routes);

    return flatRoutes.map(route => (
      <Route
        path={route.path}
        key={route.key}
        element={<ProtectedRoute>{React.createElement(Pages[route.component as keyof typeof Pages])}</ProtectedRoute>}
      />
    ));
  };

  // Handle loading state
  if (loading) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          position="fixed"
          width="100%"
          zIndex={9999}
          bgcolor={(theme) => theme.palette.background.default}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalContextProvider>
          <GlobalFilterContextProvider>
            <CssBaseline />
            <div className="antialiased">
              <Routes>
                {/* Always render unprotected routes */}
                {renderUnprotectedRoutes()}

                {/* Show protected routes only when there's a user */}
                {user && renderProtectedRoutes()}

                {/* Redirect to login if no user, otherwise to home */}
                <Route
                  path="*"
                  element={<Navigate to={user ? "/" : "/login"} />}
                />
              </Routes>
            </div>
          </GlobalFilterContextProvider>
        </GlobalContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
