import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import contentTypesService from "services/contentTypes";
import { useAuth } from "context/Auth";
import { IContentType, IMetadata } from "types/contentTypes";

interface ContentTypeContextType {
  contentType: Partial<IContentType>;
  setContentType: React.Dispatch<React.SetStateAction<Partial<IContentType>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  id: string | undefined;
  metadata: IMetadata[];
  setMetadata: React.Dispatch<React.SetStateAction<IMetadata[]>>;
  saveContentType: (additionalProps?: Record<string, any>) => Promise<boolean>;
  reFetchContentType: () => Promise<void>;
}

const ContentTypeContext = createContext<ContentTypeContextType | undefined>(undefined);

export const ContentTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [contentType, setContentType] = useState<Partial<IContentType>>({});
  const [metadata, setMetadata] = useState<IMetadata[]>([]);
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const saveContentType = async () => {
    try {
      setLoading(true);
      setError(null);

      const updatedContentType = {
        ...contentType,
        metadata: metadata,
      };

      if (!updatedContentType?.title) {
        throw new Error("Please provide a content type name");
      }

      if (metadata.length === 0) {
        throw new Error("Please add at least one field");
      }

      if (id === "create" || !id) {
        updatedContentType.createdBy = user;
        updatedContentType.createdDate = new Date().toISOString();
        console.log("updatedContentType", updatedContentType);
        await contentTypesService.create(updatedContentType);
      } else {
        updatedContentType.updatedBy = user;
        updatedContentType.updatedDate = new Date().toISOString();
        await contentTypesService.update(id, updatedContentType);
      }

      return true;
    } catch (error) {
      console.error("Error saving content type:", error);
      setError(error.message);
      setLoading(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchContentType = async () => {
    if (id && id !== 'create') {
      try {
        setLoading(true);
        const contentType = await contentTypesService.fetch(id);
        setContentType(contentType);
        setMetadata(contentType.metadata || []);
      } catch (err) {
        console.error("Error fetching content type:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchContentType();
  }, [id]);

  return (
    <ContentTypeContext.Provider
      value={{
        contentType,
        setContentType,
        loading,
        setLoading,
        error,
        setError,
        user,
        id,
        metadata,
        setMetadata,
        saveContentType,
        reFetchContentType: fetchContentType,
      }}
    >
      {children}
    </ContentTypeContext.Provider>
  );
};

export const useContentType = () => {
  const context = useContext(ContentTypeContext);
  if (context === undefined) {
    throw new Error("useContentType must be used within a ContentTypeProvider");
  }
  return context;
};