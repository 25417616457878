import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import BasicLayout from "components/Layouts/BasicLayout";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import templatesService from "services/templates";

const Templates = () => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      width: 120,
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 120,
      valueGetter: (params) => (params.row.isActive ? "Yes" : "No"),
    },
    {
      field: "updatedDate",
      headerName: "Last Updated",
      width: 200,
      valueGetter: (params) => new Date(params.row.updatedDate).toLocaleString(),
    },
  ];

  const filterOptions = [
    {
      field: "type",
      label: "Type",
      type: "select",
      options: [
        { label: "Email", value: "email" },
        { label: "SMS", value: "sms" },
        { label: "Push", value: "push" },
      ],
    },
    {
      field: "isActive",
      label: "Status",
      type: "select",
      options: [
        { label: "Active", value: "true" },
        { label: "Inactive", value: "false" },
      ],
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Templates</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"} gap={2}>
          <Button variant="contained" color="primary" component={Link} to="/templates/new/details">
            <Icon>add</Icon>
            Create Template
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="templates"
            columns={columns}
            service={templatesService}
            filterOptions={[]}
            defaultSortField="updatedDate"
            defaultSortDirection="desc"
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Templates; 