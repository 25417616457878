import {
  Grid,
  FormLabel,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { INewsletter } from "types/newsletters";
import { useState } from "react";

interface PreviewModalProps {
  open: boolean;
  onClose: () => void;
  html: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ open, onClose, html }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Preview
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <iframe
          srcDoc={html}
          style={{
            width: '100%',
            height: '600px',
            border: 'none',
            backgroundColor: 'white'
          }}
          title="Newsletter Preview"
          sandbox="allow-same-origin"
        />
      </DialogContent>
    </Dialog>
  );
};

interface NewsLetterContentProps {
  newsletter: Partial<INewsletter>;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  isSmallScreen: boolean;
}

const NewsLetterContent: React.FC<NewsLetterContentProps> = ({ newsletter, setNewsletter, isSmallScreen }) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel sx={{ marginRight: 2 }}>Type:</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('email')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'email']
                    : (newsletter.type || []).filter(t => t !== 'email');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('sms')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'sms']
                    : (newsletter.type || []).filter(t => t !== 'sms');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="SMS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletter.type?.includes('push')}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...(newsletter.type || []), 'push']
                    : (newsletter.type || []).filter(t => t !== 'push');
                  setNewsletter({ ...newsletter, type: newTypes as ('email' | 'sms' | 'push')[] });
                }}
              />
            }
            label="Push Notification"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={isSmallScreen ? 12 : 6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="newsletter-email-content">Email HTML Content</FormLabel>
          <TextField
            id="newsletter-email-content"
            multiline
            rows={20}
            value={newsletter?.emailContent || ''}
            onChange={(e) => {
              setNewsletter({ ...newsletter, emailContent: e.target.value });
            }}
            sx={{
              '& .MuiInputBase-root': {
                fontFamily: 'monospace',
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => setPreviewOpen(true)}
            sx={{ mt: 1 }}
          >
            Preview HTML
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={isSmallScreen ? 12 : 6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="newsletter-sms-content">SMS Content</FormLabel>
              <TextField
                id="newsletter-sms-content"
                value={newsletter?.smsContent}
                multiline
                rows={10}
                onChange={(e) => setNewsletter({ ...newsletter, smsContent: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel htmlFor="newsletter-push-content">Push Notification Content</FormLabel>
              <TextField
                id="newsletter-push-content"
                value={newsletter?.pushContent}
                multiline
                rows={10}
                onChange={(e) => setNewsletter({ ...newsletter, pushContent: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <PreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        html={typeof newsletter?.emailContent === 'string' ? newsletter.emailContent : JSON.stringify(newsletter?.emailContent)}
      />
    </Grid>
  )
}

export default NewsLetterContent;